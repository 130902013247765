<!-- 客户分析 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="客户名称">
            <a-input
              placeholder="请输入"
              v-model="queryParam.customer_name"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="客户编号">
            <a-input
              placeholder="请输入"
              v-model="queryParam.customer_num"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div> -->

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <!-- <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        > -->
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row)">删除</a>
      </template>

      <template v-slot:intable="{ row }"><projectDetail ></projectDetail></template>
    </vxe-grid>

    <vxe-modal  style="z-index: 10"  
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="客户名称"
          field="customer_name"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="联系人"
          field="contacts"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="电话"
          field="contacts"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="地址"
          field="address"
          span="24"
          :item-render="{ name: 'textarea', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="登录账号"
          field="account"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="登录密码"
          field="password"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="客户类别"
          field="customer_type"
          span="24"
          :item-render="{
            name: '$select',
            options: typeOptions,
            attrs: { placeholder: '请选择' },
          }"
        ></vxe-form-item>

        <vxe-form-item
          title="业务负责人"
          field="salesman"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item title="附件" field="salesman" span="24">
          <template v-slot>
            <a-upload
              name="file"
              :file-list="fileListMain"
              :remove="handleRemove1"
              :before-upload="beforeUpload1"
            >
              <a-button> <a-icon type="upload" /> 上传</a-button>
            </a-upload>
          </template>
        </vxe-form-item>

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </a-card>
</template>

<script>
import projectDetail from './modal/project_detail'
export default {
  data() {
    return {
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
        importUrl: "",
      },
      fileListMain: [],
      typeOptions: [
        { label: "一级", value: "一级" },
        { label: "二级", value: "二级" },
        { label: "三级", value: "三级" },
      ],
      columns: [
        {
          width: 50,
          title: "",
          type: "expand",
          slots: {
            content: "intable",
          },
        },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        // {
        //   title: "操作",
        //   field: "action",
        //   slots: {
        //     default: "action",
        //   },
        // },
        {
          title: "客户名称",
          field: "customer_name",
        },
        {
          title: "客户编号",
          field: "customer_num",
        },
        {
          title: "联系人",
          field: "contacts",
        },
        {
          title: "电话",
          field: "phone",
        },
        {
          title: "地址",
          field: "address",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "result.records",
          total: "result.total",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            // console.log(this.queryParam);
            // return _this.$Request(this.$Interface.warn_info);
            let data = {
              result: {
                current: 1,
                records: [
                  {
                    customer_name: "广州市疾病预防控制中心",
                    address: "广东省广州市白云区启德路1号",
                    contacts: "陈先生",
                    phone: 13392211555,
                    customer_num: "a123",
                    account: "b123",
                    password: "123465",
                    customer_type: "",
                    remark: "",
                    salesman: "admin",
                    createTime: "2022-3-9",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        customer_type: "",
      },
      formRules: {
        customer_name: [{ required: true, message: "请输入" }],
        contacts: [{ required: true, message: "请输入" }],
        phone: [{ required: true, message: "请输入" }],
        address: [{ required: true, message: "请输入" }],
        account: [{ required: true, message: "请输入" }],
        password: [{ required: true, message: "请输入" }],
        // customer_type: [{ required: true, message: "请输入" }],
      },
    };
  },

  components: {projectDetail},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {},
    submitEvent() {},
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
    },
    handleRemove1(file) {
      const index = this.fileListMain.indexOf(file);
      const newfileListMain = this.fileListMain.slice();
      newfileListMain.splice(index, 1);
      this.fileListMain = newfileListMain;
    },
    beforeUpload1(file) {
      let that = this;
      let param = new FormData();
      param.append("images", file);
      param.append("name", "images");
      param.append("filename", file.name);
      let config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      that.fileListMain = [file];
      // axios.post(this.$Interface.prod_upload, param, config).then((res) => {
      //   if (res.data.code == 200) {
      //     let d = res.data.data;
      //     let img_id = d.id;
      //     let img = d.url;
      //     let url_thumb = d.url_thumb;
      //     file.img_id = img_id;
      //     file.url = img;
      //     file.url_thumb = url_thumb;
      //     that.fileListMain = [file];
      //     console.log(that.fileListMain);
      //   }
      // });
      return false;
    },
  },
};
</script>

<style scoped>
</style>
