<!-- 编号设置 -->
<template>
  <a-card>
    <a-form-model
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      style="width: 600px"
      ref="ruleForm"
      :model="formData"
      :rules="formRules"
    >
      <a-form-model-item label="项目编号:" prop="project_num_type">
        <a-select
          :options="opt1"
          v-model="formData.project_num_type"
          placeholder="请输入"
          style="width: 250px"
          @change="pChange"
        />
        如：{{ p_num }}
      </a-form-model-item>
      <a-form-model-item label="项目序列号:" prop="project_num">
        <a-input
          style="width: 250px"
          v-model="formData.project_num"
          placeholder="请输入"
          @change="pChange"
          type="number"
        />
      </a-form-model-item>
      <a-form-model-item label="样品编号:" prop="sample_num_type">
        <a-select
          :options="opt2"
          v-model="formData.sample_num_type"
          placeholder="请输入"
          style="width: 250px"
          @change="sChange"
        />
        如：{{ s_num }}
      </a-form-model-item>
      <a-form-model-item label="样品序列号:" prop="sample_num">
        <a-input
          style="width: 250px"
          v-model="formData.sample_num"
          placeholder="请输入"
          @change="sChange"
          type="number"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 18, offset: 6 }">
        <a-button type="primary" @click="submitEvent"> 保存 </a-button>
        <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
          清空
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      opt1: [
        {
          label: "项目类型编号+序列号",
          value: "project_code,project_num",
        },
        {
          label: "项目类型编号+时间",
          value: "project_code,time",
        },
        {
          label: "项目类型编号+时间+序列号",
          value: "project_code,time,project_num",
        },
      ],
      opt2: [
        {
          label: "项目类型编号+序列号",
          value: "sample_code,sample_num",
        },
        {
          label: "项目类型编号+时间",
          value: "sample_code,time",
        },
        {
          label: "项目类型编号+时间+序列号",
          value: "sample_code,time,sample_num",
        },
      ],
      formData: {},
      formRules: {
        sample_num_type: [{ required: true, message: "请选择" }],
        project_num_type: [{ required: true, message: "请选择" }],
      },
      p_num: "",
      s_num: "",
      project_code: "rt",
      sample_code: "sm",
      time: "20220325172225",
    };
  },

  components: {},

  computed: {},

  created() {
    this.init();
  },

  mounted() {},

  methods: {
    init() {
      this.formData = {
        project_num: "001",
        project_num_type: "project_code,project_num",
        sample_num: "001",
        sample_num_type: "sample_code,sample_num",
      };
      this.pChange();
      this.sChange();
    },
    submitEvent() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(this.formData);
        } else {
          return false;
        }
      });
    },
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.formData = {
        project_num: "",
        project_num_type: "",
        sample_num: "",
        sample_num_type: "",
      }
      this.p_num = ''
      this.s_num =''
    },
    pChange() {
      let that = this;
      let project_num_type = this.formData.project_num_type.split(",");
      let arr = project_num_type.map((item) => {
        return that.formData[item] || that[item];
      });
      that.p_num = arr.join("");
    },
    sChange() {
      let that = this;
      let sample_num_type = this.formData.sample_num_type.split(",");
      let arr = sample_num_type.map((item) => {
        return that.formData[item] || that[item];
      });
      that.s_num = arr.join("");
    },
  },
};
</script>

<style scoped>
. {
}
</style>
