<!-- 样品列表 -->
<template>
  <vxe-modal  style="z-index: 10"  
    v-model="showModal"
    title="样品列表"
    width="800"
    height="600"
    resize
    destroy-on-close
    show-zoom
  >
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      @radio-change="radioChangeEvent"
    >
    </vxe-grid>
    <a-button @click="handleOk" type="primary" style="float:right">确定</a-button>
  </vxe-modal>
</template>

<script>
export default {
  data() {
    let that = this;
    return {
      showModal: false,
      selectRow: {},
      template: {},
      queryParam: {},
      columns: [
        { type: "radio", width: 45 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "样品编号",
          field: "product_no",
        },
        {
          title: "样品名称",
          field: "name",
        },
        {
          title: "样品类别",
          field: "type_name",
        },
        {
          title: "样品备注",
          field: "extInfo.sampleRemark",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
           let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize;
            return that.$getAction(that.$Interface.sample_list + param);

          },
        },
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    show() {
      this.showModal = true;
    },
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
    handleOk() {
      this.$emit("ok", this.selectRow);
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
</style>
