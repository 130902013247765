<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
	export default {
		name: 'app',
	}
</script>

<style>
#app{
	height: 100%;
	width: 100%;
}
</style>
