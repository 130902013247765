<!--  -->
<template>
  <div id="edit">
    <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="formRules"
      class="ant-advanced-search-form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-row :gutter="24">
        <a-col span="24">
          <div class="title" style="width: 100%">
            项目基本信息
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="seeHistory"
              >参考历史单</a-button
            >
          </div>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目类型" prop="project_type_id">
            <a-select
              :options="typeOptions"
              v-model="formData.project_type_id"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="测试部位" prop="tag_id">
            <a-select
              placeholder="请选择"
              v-model="formData.tag_id"
              :options="tagList"
              mode="multiple"
            ></a-select>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目名称" prop="project_name">
            <a-input
              placeholder="请输入"
              v-model="formData.project_name"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="测试指标">
            <a-select
              placeholder="请选择"
              v-model="formData.inspect_tag_id"
              :options="test_tag"
              mode="multiple"
            ></a-select>
          </a-form-model-item>
        </a-col>

        <!-- <a-col span="8">
          <a-form-model-item label="项目编号" prop="code">
            <a-input placeholder="请输入" v-model="formData.code"></a-input>
          </a-form-model-item>
        </a-col> -->

        <a-col span="8">
          <a-form-model-item label="项目组">
            <a-select
              placeholder="请选择"
              v-model="formData.team_id"
              :options="teamList"
            ></a-select>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="联系方式" prop="phone">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.phone"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            label="是否允许做其他项目"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-select
              :options="is_opt"
              v-model="formData.exclude_other"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            label="是否允许做同类项目"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-select
              :options="is_opt"
              v-model="formData.exclude_similar"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="参与者性别">
            <a-select
              :options="sex_opt"
              v-model="formData.sex"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="参与者年龄">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.age1"
              style="width: 82px"
              min="0"
            ></a-input>
            -
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.age2"
              style="width: 82px"
              min="0"
            ></a-input>
            岁
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="样品名称" prop="sample_name">
            <a-input
              placeholder="请输入"
              v-model="formData.sample_name"
              style="width: 150px"
            ></a-input>
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="seeSample"
              >选择样品</a-button
            >
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            label="封面"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-upload
              list-type="picture-card"
              :file-list="fileListMain"
              @preview="handlePreview"
              :remove="handleRemove1"
              :before-upload="beforeUpload1"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
        </a-col>

        <!-- <a-col span="8">
          <a-form-model-item label="报名上限">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.extInfo.regLimit"
            ></a-input>
          </a-form-model-item>
        </a-col> -->

        <a-col span="8">
          <a-form-model-item label="名额上限">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.extInfo.quotaLimit"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="补助金额">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.point_final"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="24">
          <a-form-model-item
            label=""
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            <!-- <a-textarea
              placeholder="请输入"
              v-model="formData.info"
            ></a-textarea> -->

            <!-- <div id="editDiv"></div> -->
            <a-collapse v-model="collapse0">
              <a-collapse-panel header="简介">
                <div
                  style="
                    margin: -16px;
                    height: auto;
                    z-index: 100;
                    background: white;
                  "
                >
                  <Toolbar
                    style="border-bottom: 1px solid #ccc"
                    :editor="editor"
                    :defaultConfig="toolbarConfig"
                    mode="default"
                  />
                  <Editor
                    v-model="formData.feature"
                    :defaultConfig="editorConfig"
                    mode="default"
                    @onCreated="initEditor"
                    class="rightV"
                  />
                </div>

                <!-- <div id="toolbar"></div>
                <div id="editor"></div> -->
              </a-collapse-panel>
            </a-collapse>
          </a-form-model-item>
        </a-col>

        <a-divider />

        <a-col span="24">
          <div class="title">
            调查问卷
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="seeQuestion"
              >添加调查问卷</a-button
            >
          </div>
        </a-col>

        <a-col span="24">
          <vxe-grid
            style="margin: 10px 0"
            size="small"
            resizable
            row-id="id"
            show-overflow
            show-header-overflow
            highlight-hover-row
            border
            :data="question_data"
            :columns="columns"
          >
            <template v-slot:action="{ row }">
              <a @click="preview(row)">预览</a>
              <!-- <a style="margin-left: 8px" @click="removeEvent(row)">删除</a> -->
               <a-popconfirm
                title="确认删除?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="removeEvent(row)"
              >
                <a href="#" style="margin-left: 8px">删除</a>
              </a-popconfirm>
            </template>
            <template v-slot:start_time="{ row }">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD HH:mm"
                valueFormat="YYYY-MM-DD HH:mm"
                :locale="locale"
                v-model="row.begin_time"
                :show-time="{ format: 'HH:mm' }"
              ></a-date-picker>
            </template>
            <template v-slot:end_time="{ row }">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD HH:mm"
                valueFormat="YYYY-MM-DD HH:mm"
                :locale="locale"
                v-model="row.end_time"
                :show-time="{ format: 'HH:mm' }"
              ></a-date-picker>
            </template>
          </vxe-grid>
        </a-col>

        <a-divider />

        <a-collapse v-model="collapse1" style="margin-bottom: 20px">
          <a-collapse-panel header="打卡设置">
            <selDayTime
              ref="clockTime"
              personSet="0"
              @del="colDel"
            ></selDayTime>
          </a-collapse-panel>
        </a-collapse>

        <a-collapse v-model="collapse2" style="margin-bottom: 20px">
          <a-collapse-panel header="预约设置">
            <selDayTime ref="yuyueTime" size="big" @del="yuyueDel"></selDayTime>
          </a-collapse-panel>
        </a-collapse>

        <a-col span="24">
          <div class="title">
            消息设置-提醒管理员
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="selAll1"
              >全选</a-button
            >
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="selAllNo1"
              >全不选</a-button
            >
          </div>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="项目开始报名提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.a_apply_start"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="项目报名结束提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.a_apply_end"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="问卷调查结束提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.a_ques_end"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="每日打卡结束提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.a_checkin_end"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="预约回访提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.a_reserve_end"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-divider />

        <a-col span="24">
          <div class="title">
            消息设置-提醒志愿者
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="selAll2"
              >全选</a-button
            >
            <a-button
              type="primary"
              style="margin-left: 10px"
              size="small"
              @click="selAllNo2"
              >全不选</a-button
            >
          </div>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="问卷调查提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.v_checkin_end"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="每日打卡提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.v_ques_end"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-col span="4">
          <a-form-model-item label="回访预约开始提醒" :label-col="{ span: 18 }">
            <vxe-checkbox
              v-model="formData.notify.v_reserve_start"
              checked-value="Y"
              unchecked-value="N"
            ></vxe-checkbox>
          </a-form-model-item>
        </a-col>

        <a-divider />

        <a-col span="24">
          <div class="title">积分规则</div>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            按时打卡1次得
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.point_once_reward"
              style="width: 85px"
              min="0"
            ></a-input
            >积分
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            超时打卡1次得
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.point_per_miss"
              style="width: 85px"
              min="0"
            ></a-input
            >积分
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            1天内未打卡扣除
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.point_day_miss"
              style="width: 85px"
              min="0"
            ></a-input
            >积分
          </a-form-model-item>
        </a-col>

        <!-- <a-col span="8">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input
                type="number"
                placeholder="请输入"
                v-model="formData.exchange_points"
                style="width: 85px"
                min="0"
              ></a-input
              >积分=
              <a-input
                type="number"
                placeholder="请输入"
                v-model="formData.exchange_money"
                style="width: 85px"
                min="0"
              ></a-input
              >元
            </a-form-model-item>
          </a-col> -->

        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="submitEvent(0)"> 保存 </a-button>
          <a-button
            type="primary"
            @click="submitEvent(2)"
            style="margin-left: 8px"
            v-if="formData.status < 2"
          >
            提交
          </a-button>
          <a-button style="margin-left: 8px" @click="handleReset">
            清空
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <vxe-modal
      style="z-index: 10"
      v-model="timeModal"
      title="设置时间间隔"
      show-zoom
      resize
      destroy-on-close
      width="300"
      height="auto"
    >
      <div style="margin-bottom: 10px">
        {{ timeSplit.start_time }} ~ {{ timeSplit.end_time }}，人数上限：{{
          timeSplit.max_num
        }}
      </div>

      <div>
        间隔：<a-select
          :options="optTime"
          v-model="splitV"
          style="width: 100px"
        ></a-select>
      </div>
      <div style="text-align: center; margin: 15px">
        <a-button type="primary" @click="setSplit" style="margin-right: 15px"
          >确定</a-button
        >
        <a-button @click="cancelSplit">取消</a-button>
      </div>
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>

    <preview ref="preview" :template="template"></preview>

    <historyModal ref="historyModal" @ok="selHistory" />

    <sampleList ref="sampleList" @ok="selSample" />

    <questionList ref="questionList" @ok="selQuestion" />
  </div>
</template>

<script>
import preview from "@/components/preview";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import historyModal from "./history_modal.vue";
import sampleList from "./sample_list.vue";
import questionList from "./question_list.vue";
import calendar from "@/components/calendar/calendar";
import selDayTime from "./selDayTime.vue";
// import E from "wangeditor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IEditorConfig, createEditor, createToolbar } from "@wangeditor/editor";

export default {
  data() {
    let _this = this;
    return {
      colDelArr: [],
      yuyueDelArr: [],
      collapse0: [0, 1],
      collapse1: [0, 1],
      collapse2: [0, 1],
      doType: "",
      timeModal: false,
      dayRow: {},
      timeSplit: {},
      itemIndex: "",
      optTime: [],
      splitV: 10,
      festival: [],
      restDate: [],
      festivalObj: {},
      dateArr: "",
      locale,
      template: {},
      fileListMain: [],
      questionList: [],
      previewVisible: false,
      previewImage: "",
      teamList: [],
      testItemList: [
        // { label: "水分", value: "水分" },
      ],
      typeOptions: [
        // { label: "人体功效评价", value: "人体功效评价" },
      ],
      test_tag: [],
      status_opt: [
        { label: "未审核", value: "0" },
        { label: "已通过", value: "1" },
        { label: "已拒绝", value: "2" },
        { label: "未开始", value: "3" },
        { label: "进行中", value: "4" },
        { label: "已完成", value: "5" },
      ],
      tagList: [],
      is_opt: [
        {
          label: "是",
          value: "Y",
        },
        {
          label: "否",
          value: "N",
        },
      ],
      sex_opt: [
        {
          label: "全部",
          value: "I",
        },
        {
          label: "男",
          value: "M",
        },
        {
          label: "女",
          value: "F",
        },
      ],
      formData: {
        info: "",
        notify: {
          a_apply_end: "Y",
          a_apply_start: "Y",
          a_checkin_end: "Y",
          a_ques_end: "Y",
          a_reserve_end: "Y",
          v_checkin_end: "Y",
          v_ques_end: "Y",
          v_reserve_start: "Y",
        },
        extInfo: {},
        clock_rule: "按日打卡",
        point_once_reward: 2,
        point_day_miss: 1,
        point_per_miss: 1,
        exchange_points: 10,
        exchange_money: 1,
        sample_name: "",
        sample_num: "",
        status: 0,
      },
      formRules: {
        project_type_id: [{ required: true, message: "请选择" }],
        tag_id: [{ required: true, message: "请选择" }],
        project_name: [{ required: true, message: "请输入" }],
        test_tag: [{ required: true, message: "请输入" }],
        phone: [{ required: true, message: "请输入" }],
        sex: [{ required: true, message: "请选择" }],
        sample_name: [{ required: true, message: "请输入" }],
      },
      question_data: [],
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "调查问卷名称",
          field: "question_name",
        },
        {
          title: "类型",
          field: "type_text",
        },
        {
          title: "开始时间",
          slots: {
            default: "start_time",
          },
        },
        {
          title: "结束时间",
          slots: {
            default: "end_time",
          },
        },
      ],
      selectionRows: "",
      selectedRowKeys: "",
      c_day: "",
      c_month: new Date().getMonth() + 1,
      yuyue_arr: [],
      columnsYu: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
          width: 100,
        },
        {
          title: "日期",
          field: "day",
          width: 120,
        },
        {
          title: "时间段",
          slots: {
            default: "timeArr",
          },
        },
      ],
      deleteArr: [],
      editor: null,
      editorConfig: {},
      toolbarConfig: {
        excludeKeys: [
          "insertLink",
          "emotion",
          "editImage",
          "insertImage",
          "uploadImage",
          "insertVideo",
          "uploadVideo",
        ],
      },
    };
  },

  components: {
    preview,
    historyModal,
    sampleList,
    questionList,
    calendar,
    selDayTime,
    Editor,
    Toolbar,
  },

  computed: {},

  created() {
    this.init();
  },

  mounted() {
    // const editor = createEditor({
    //   selector: "#editor",
    //   config: this.editorConfig,
    // });
    // const toolbar = createToolbar({
    //   editor,
    //   selector: "#toolbar",
    //   config: this.toolbarConfig,
    //   mode: "default",
    // });
  },

  beforeDestroy() {
    const editor = this.editor;
    if (editor) {
      editor.destroy();
    }
  },

  methods: {
    init() {
      let that = this;
      that.tagList = [];
      that.typeOptions = [];
      that.test_tag = [];
      that.teamList = [];
      let customer_id = "";
      customer_id = JSON.parse(sessionStorage.userInfo).user_id;
      if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
        customer_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
      }
      if (sessionStorage.sf == "管理员") {
        customer_id = "";
      }
      let param =
        "?pageNum=1" + "&pageSize=100" + "&customer_id=" + customer_id;
      that.$getAction(that.$Interface.project_tag_list + param).then((res) => {
        if (res.code == 200) {
          if (res.data.list && res.data.list.length) {
            that.tagList = res.data.list.map((it) => {
              let id = it.id;
              let a = {
                value: id,
                label: it.name,
              };
              return a;
            });
          }
        }
      });

      that.$getAction(that.$Interface.project_type_list + param).then((res) => {
        if (res.code == 200) {
          if (res.data.list && res.data.list.length) {
            that.typeOptions = res.data.list.map((it) => {
              let id = it.id;
              let a = {
                value: id,
                label: it.name,
              };
              return a;
            });
          }
        }
      });
      that
        .$getAction(that.$Interface.tag_list + param + "&tag_type=i")
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list && res.data.list.length) {
              that.test_tag = res.data.list.map((it) => {
                let id = it.id;
                let a = {
                  value: id,
                  label: it.name,
                };
                return a;
              });
            }
          }
        });

      that.$getAction(that.$Interface.group_list + param).then((res) => {
        if (res.code == 200) {
           if (res.data.List && res.data.List.length) {
            that.teamList = res.data.List.map((it) => {
              let id = it.id;
              let a = {
                value: id,
                label: it.team_name,
              };
              return a;
            });
          }
        }
      });
    },
    initEditor(editor) {
      let that = this;
      this.editor = Object.seal(editor);

      // if (!that.editor) {
      // that.editor = new E("#editDiv");
      // console.log(that.editor)
      // that.editor.config.excludeMenus = ["image", "video","code","emoticon"];
      // that.editor.create();
      // }
    },
    selAll1() {
      this.formData.notify.a_apply_end = "Y";
      this.formData.notify.a_apply_start = "Y";
      this.formData.notify.a_checkin_end = "Y";
      this.formData.notify.a_ques_end = "Y";
      this.formData.notify.a_reserve_end = "Y";
    },
    selAllNo1() {
      this.formData.notify.a_apply_end = "N";
      this.formData.notify.a_apply_start = "N";
      this.formData.notify.a_checkin_end = "N";
      this.formData.notify.a_ques_end = "N";
      this.formData.notify.a_reserve_end = "N";
    },
    selAll2() {
      this.formData.notify.v_checkin_end = "Y";
      this.formData.notify.v_ques_end = "Y";
      this.formData.notify.v_reserve_start = "Y";
    },
    selAllNo2() {
      this.formData.notify.v_checkin_end = "N";
      this.formData.notify.v_ques_end = "N";
      this.formData.notify.v_reserve_start = "N";
    },
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    checkFirstTime2(rule, value, callback) {
      let that = this;
      if (!value) {
        return callback(new Error("请选择"));
      } else {
        let firstFime1 = that.formData.clock_firstTime1;
        let hour1 = parseInt(firstFime1.split(":")[0]);
        let hour2 = parseInt(value.split(":")[0]);
        if (hour2 <= hour1 || hour2 - hour1 > 5) {
          return callback(new Error("日期范围错误"));
        }
        callback();
      }
    },

    checkSecondTime1(rule, value, callback) {
      let that = this;
      if (!value) {
        return callback(new Error("请选择"));
      } else {
        let firstFime2 = that.formData.clock_firstTime2;
        let f_hour = parseInt(firstFime2.split(":")[0]);
        let hour1 = parseInt(value.split(":")[0]);
        if (hour1 <= f_hour || hour1 - f_hour < 6) {
          return callback(new Error("必须与第1次间隔6小时"));
        }
        callback();
      }
    },

    checkSecondTime2(rule, value, callback) {
      let that = this;
      if (!value) {
        return callback(new Error("请选择"));
      } else {
        let secondTime1 = that.formData.clock_secondTime1;
        let hour1 = parseInt(secondTime1.split(":")[0]);
        let hour2 = parseInt(value.split(":")[0]);
        if (hour2 <= hour1 || hour2 - hour1 > 5) {
          return callback(new Error("日期范围错误"));
        }
        callback();
      }
    },
    seeHistory() {
      this.$refs.historyModal.show();
    },
    selHistory(row) {
      let that = this;
      delete row.id;
      delete row.code;
      delete row.customerID;
      delete row.begin_time
      delete row.end_time
      this.formData = { ...row };
      this.formData.status = 0
      if (!that.formData.notify) {
        that.formData.notify = {
          a_apply_end: "Y",
          a_apply_start: "Y",
          a_checkin_end: "Y",
          a_ques_end: "Y",
          a_reserve_end: "Y",
          v_checkin_end: "Y",
          v_ques_end: "Y",
          v_reserve_start: "Y",
        };
      }
      this.fileListMain = [];
      let img = {
        uid: -1,
        name: row.extInfo.img + ".jpg",
        status: "done",
        url: row.extInfo.img,
      };
      this.fileListMain.push(img);
      // console.log(this.formData);
    },
    seeSample() {
      this.$refs.sampleList.show();
    },
    selSample(row) {
      this.formData.sample_name = row.name;
      this.formData.sample_id = row.id;
      //  this.$set(this.formData, 'sample_name', row.name)
      // console.log(this.formData);
    },
    seeQuestion() {
      this.$refs.questionList.show();
    },
    selQuestion(row) {
      let that = this;
      let data = JSON.parse(JSON.stringify(row));
      for (let i in data) {
        let a = data[i];
        let question_id = a.id;
        let index = _.findIndex(that.question_data, {
          question_id: question_id,
        });
        if (index == -1) {
          let o = {
            begin_time: "",
            end_time: "",
            question_id: a.id,
            question_name: a.name,
            question_number: a.number,
            type: a.type,
            type_text: a.type_text,
          };
          that.question_data.push(o);
        }
      }
    },
    removeEvent(row) {
      let that = this;
      let index = _.indexOf(that.question_data, row);
      that.question_data.splice(index, 1);

      let idStr = row.id.toString();
      if (idStr.indexOf("row") == -1 && that.deleteArr.indexOf(row.id) == -1) {
        that.deleteArr.push(row.id);
      }
    },

    async deleteQuestion(id) {
      let that = this;
      this.$postAction(this.$Interface.project_question_del, { id: id }).then(
        (res) => {
          if (res.code == 200) {
            console.log("删除问卷成功");
          } else {
            console.log("删除问卷失败");
          }
          that.deleteArr = [];
        }
      );
    },

    updateQuestion(obj) {
      this.$postAction(this.$Interface.project_question_update, obj).then(
        (res) => {
          if (res.code == 200) {
            console.log("更新问卷成功");
          } else {
            console.log("更新问卷失败");
          }
        }
      );
    },

    removeDay(row) {
      let that = this;
      let index = _.indexOf(that.yuyue_arr, row);
      that.yuyue_arr.splice(index, 1);
      let dayIndex = _.indexOf(that.restDate, row.day);
      that.restDate.splice(dayIndex, 1);
    },
    copyDay(row) {
      let that = this;
      that.yuyue_arr.push(row);
      that.restDate.push(row.day);
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleRemove1(file) {
      const index = this.fileListMain.indexOf(file);
      const newfileListMain = this.fileListMain.slice();
      newfileListMain.splice(index, 1);
      this.fileListMain = newfileListMain;
      this.formData.extInfo.img = "";
    },
    beforeUpload1(file) {
      let that = this;
      this.$uploadAction(file).then((res) => {
        if (res.code == 200) {
          let img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.data.url,
          };
          that.fileListMain = [img];
          that.formData.extInfo.img = res.data.url;
        }
      });
      return false;
    },
    addEvent() {
      this.doType = "add";
      this.deleteArr = [];
    },
    editEvent(item) {
      let that = this;
      that.colDelArr = [];
      that.yuyueDelArr = [];
      that
        .$getAction(that.$Interface.project_edit + "?id=" + item.id)
        .then((res) => {
          if (res.code == 200) {
            let row = res.data;
            console.log(row);
            // console.log(row.extInfo.html)
            that.doType = "edit";
            that.deleteArr = [];
            that.formData = JSON.parse(JSON.stringify(row));
            if (!that.formData.notify) {
              that.formData.notify = {
                a_apply_end: "Y",
                a_apply_start: "Y",
                a_checkin_end: "Y",
                a_ques_end: "Y",
                a_reserve_end: "Y",
                v_checkin_end: "Y",
                v_ques_end: "Y",
                v_reserve_start: "Y",
              };
            }
            that.fileListMain = [];
            let img = {
              uid: -1,
              name: row.extInfo.img + ".jpg",
              status: "done",
              url: row.extInfo.img,
            };
            that.fileListMain.push(img);

            // that.$refs.clockTime.init(row.extInfo.clock_time);
            // this.$refs.yuyueTime.init(row.extInfo.yuyue_time);
            that.getQuestionList(row.id);
            that.getYuyueData(row.id);
            that.getClockData(row.id);
          }
        });
    },

    getQuestionList(id) {
      let param = "?pageNum=" + 1 + "&pageSize=" + 100 + "&project_id=" + id;
      this.$getAction(this.$Interface.project_question_list + param).then(
        (res) => {
          if (res.code == 200) {
            let data = res.data.list;
            if (!data) {
              data = [];
            }
            this.question_data = data;
          }
        }
      );
    },

    getYuyueData(project_id) {
      let param = "?project_id=" + project_id;
      // + '&base_day_begin=2022-07-01&base_day_end=2022-7-31';
      this.$getAction(this.$Interface.manage_yuyue_list + param).then((res) => {
        if ((res.code = 200)) {
          let list = res.data.list;
          this.$refs.yuyueTime.init(list);
        }
      });
    },

    getClockData(project_id) {
      let param = "?project_id=" + project_id;
      // + '&base_day_begin=2022-07-01&base_day_end=2022-7-31';
      this.$getAction(this.$Interface.pro_clock_list + param).then((res) => {
        if ((res.code = 200)) {
          let list = res.data.list;
          this.$refs.clockTime.init(list);
        }
      });
    },

    dateChange(arr) {
      let that = this;
      let newArr = [];
      for (let i in arr) {
        let a = arr[i];
        let dayHas = _.findIndex(that.yuyue_arr, ["day", a]);
        if (dayHas == -1) {
          let t = {
            day: a,
            timeArr: [{ end_time: "", start_time: "", max_num: "" }],
            max_num: "",
          };
          newArr.push(t);
        } else {
          let t = that.yuyue_arr[dayHas];
          newArr.push(t);
        }
      }
      that.yuyue_arr = newArr;
    },

    addTime(row) {
      let t = {
        day: row.day,
        timeArr: [{ end_time: "", start_time: null, max_num: null }],
      };
      row.timeArr.push(t);
    },

    delTime(index, arr) {
      arr.splice(index, 1);
    },

    colDel(row) {
      let that = this;
      let hasIndex = that._.findIndex(that.colDelArr, {
        base_day: row.base_day,
      });
      if (hasIndex == -1) {
        that.colDelArr.push(row);
      }
    },

    yuyueDel(row) {
      let that = this;
      let hasIndex = that._.findIndex(that.yuyueDelArr, {
        base_day: row.base_day,
      });
      if (hasIndex == -1) {
        that.yuyueDelArr.push(row);
      }
    },

    submitEvent(status) {
      let that = this;
      // console.log(that.formData.info)
      // console.log(that.editor.getHtml())
      // that.formData.extInfo.html = that.editor.getHtml()
      that.formData.feature = that.editor.getHtml();
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.formData));
          if (!param.customerID) {
            let customer_id = JSON.parse(sessionStorage.userInfo).main
              .customer_id;
            param.customerID = customer_id;
            if (!customer_id) {
              param.customerID = JSON.parse(sessionStorage.userInfo).user_id;
            }
          }

          // param.extInfo.apply_time = JSON.stringify(that.yuyue_arr);
          param.extInfo.clock_time =
            // JSON.stringify(
            that.$refs.clockTime.yuyue_arr;
          // );
          param.extInfo.yuyue_time =
            // JSON.stringify(
            that.$refs.yuyueTime.yuyue_arr;
          // );

          if (that.$refs.clockTime.yuyue_arr.length) {
            let arr = that.$refs.clockTime.yuyue_arr;
            param.extInfo.clock_startTime = arr[0].base_day;
            param.extInfo.clock_endTime = arr[arr.length - 1].base_day;
            param.begin_time = arr[0].base_day;
            param.end_time = arr[arr.length - 1].base_day;
          }

          if (that.question_data.length) {
            let kong1 = _.find(that.question_data, ["begin_time", ""]);
            let kong2 = _.find(that.question_data, ["end_time", ""]);
            if (kong1 && kong1.name) {
              that.$message.error("调查问卷：" + kong1.name + "，设置时间有误");
              return;
            }
            if (kong2 && kong2.name) {
              that.$message.error("调查问卷：" + kong2.name + "，设置时间有误");
              return;
            }
          }

          param.question = that.question_data;
          if (that.deleteArr.length) {
            that.deleteQuestion(that.deleteArr.join(","));
          }

          for (let i in param.question) {
            let a = param.question[i];
            let id = a.id;
            let idStr = id.toString();
            if (idStr.indexOf("row") > -1) {
              (a.project_id = that.formData.id), delete a.id;
            }
            that.updateQuestion(a);
          }
          let type_index = _.findIndex(that.typeOptions, {
            value: param.project_type_id,
          });
          if (type_index > -1) {
            param.project_type = that.typeOptions[type_index].label;
          }

          let notify = {};
          for (let i in that.formData.notify) {
            let a = that.formData.notify[i];
            if (a) {
              that.formData.notify[i] = 1;
            } else {
              that.formData.notify[i] = 0;
            }
            notify[i] = a;
          }
          that.formData.notify = notify;
          // console.log(that.formData.notify);
          param.status = status;
          that.$postAction(that.$Interface.project_edit, param).then((res) => {
            if (res.code == 200) {
              that.$message.success("操作成功");
              that.$emit("loadData");

              let y_items = that.$refs.yuyueTime.yuyue_arr;
              let y_arr = [];
              for (let i in y_items) {
                let a = y_items[i];
                let o = {
                  items: a.items,
                  base_day: a.base_day,
                };
                y_arr.push(o);
              }

              for (let j in that.yuyueDelArr) {
                let row = that.yuyueDelArr[j];
                let hasIndex = that._.findIndex(y_arr, {
                  base_day: row.base_day,
                });
                if (hasIndex == -1) {
                  y_arr.push(row);
                }
              }

              let arr = {
                days: y_arr,
                project_id: res.data.id,
              };
              that.$postAction(that.$Interface.edit_yuye, arr).then((res) => {
                if (res.code == 200) {
                  // console.log(res);
                } else {
                  that.$message.error(res.message);
                }
              });

              let c_items = that.$refs.clockTime.yuyue_arr;
              let c_arr = [];
              for (let i in c_items) {
                let a = c_items[i];
                let o = {
                  items: a.items,
                  base_day: a.base_day,
                };
                c_arr.push(o);
              }

              for (let k in that.colDelArr) {
                let row = that.colDelArr[k];
                let hasIndex = that._.findIndex(c_arr, {
                  base_day: row.base_day,
                });
                if (hasIndex == -1) {
                  c_arr.push(row);
                }
              }

              let arr2 = {
                days: c_arr,
                project_id: res.data.id,
              };
              that.$postAction(that.$Interface.clock_set, arr2).then((res) => {
                if (res.code == 200) {
                  // console.log(res);
                } else {
                  that.$message.error(res.message);
                }
              });
            } else {
              that.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleReset() {
      this.formData = {
        extInfo: {},
        sample_name: "",
        sample_num: "",
      };
      this.fileListMain = [];
    },

    preview(row) {
      let that = this;
      let param = "?id=" + row.question_id;
      that.$getAction(that.$Interface.question_edit + param).then((res) => {
        if (res.code == 200) {
          that.template = res.data;
          this.$refs.preview.showModal();
        }
      });
    },

    split(item, row) {
      if (!item.start_time || !item.end_time) {
        this.$message.error("时间范围不能为空，请选择");
        return;
      }
      this.timeModal = true;
      this.timeSplit = item;
      this.dayRow = row;
      this.itemIndex = _.findIndex(this.dayRow.timeArr, item);
    },
    setSplit() {
      let that = this;
      this.dayRow.timeArr.splice(this.itemIndex, 1);
      let start = this.timeSplit.start_time;
      let end = this.timeSplit.end_time;
      let s_arr = start.split(":");
      let s_hour = s_arr[0];
      let s_min = s_arr[1];
      let e_arr = end.split(":");
      let e_hour = e_arr[0];
      let e_min = e_arr[1];
      let split = this.splitV;
      let h_cha = parseInt(e_hour) - parseInt(s_hour);
      let m_cha = parseInt(e_min) - parseInt(s_min);
      let min_cha = h_cha * 60 + m_cha;
      for (let i = 0; i < min_cha; i += split) {
        let hour = parseInt(s_hour) + Math.floor(i / 60);
        let min = i + parseInt(s_min) - Math.floor(i / 60) * 60;
        let s_time = "";

        if (i < min_cha) {
          s_time = changeT(hour) + ":" + changeT(min);
          // if (s_hour == e_hour && min >= parseInt(e_min)) {
          //   break;
          // }
        }

        if (
          changeT(hour) == changeT(e_hour) &&
          changeT(min) >= parseInt(e_min)
        ) {
          break;
        }
        if (
          changeT(hour) == changeT(e_hour) &&
          changeT(min + split) > parseInt(e_min)
        ) {
          break;
        }

        let e_time = changeT(hour) + ":" + changeT(min + split);
        if (min + split == 60) {
          e_time = changeT(hour + 1) + ":" + "00";
        }
        if (min + split > 60) {
          let e_m = min + split - 60;
          if (e_m > parseInt(e_min)) {
            break;
          }
          e_time = changeT(hour + 1) + ":" + changeT(e_m);
        }
        let a = {
          start_time: s_time,
          end_time: e_time,
          max_num: that.timeSplit.max_num,
        };
        this.dayRow.timeArr.push(a);
        // console.log(s_time, e_time);
      }

      this.timeModal = false;

      function changeT(t) {
        let a = parseInt(t);
        if (a < 10) {
          a = "0" + a;
        }
        return a;
      }
    },
    cancelSplit() {
      this.timeModal = false;
      this.splitV = 10;
    },
  },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.closeBadeg {
  color: red;
  cursor: pointer;
  z-index: 1;
}

.scissor {
  color: #1890ff;
  cursor: pointer;
  z-index: 1;
}

.rightV {
  width: 375px;
  height: 550px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 10px;
  margin: 10px auto;
  overflow: auto;
}
</style>

