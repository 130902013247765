<!-- 查看 -->
<template>
  <div>
    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      title="查看"
      width="1200"
      height="90%"
      resize
      destroy-on-close
      show-zoom
    >
      <a-form-model
        ref="ruleForm"
        :model="formData"
        class="ant-advanced-search-form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-row :gutter="24">
          <a-col span="24">
            <div class="title">项目基本信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="项目类型" prop="project_type">
              {{ formData.project_type }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="测试部位">
              {{ formData.tag_text }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="项目名称" prop="project_name">
              {{ formData.project_name }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="项目编号" prop="code">
              {{ formData.code }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="测试指标" prop="code">
              {{ formData.inspect_tag_text }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="项目组">
              {{ formData.team_name }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="联系方式">
              {{ formData.phone }}
            </a-form-model-item>
          </a-col>

          <!-- <a-col span="8">
            <a-form-model-item label="调查问卷">
              {{ questionName }}
              <a-button
                type="primary"
                style="padding: 0 8px; margin-left: 5px"
                @click="preview"
                >预览</a-button
              >
            </a-form-model-item>
          </a-col> -->

          <a-col span="8">
            <a-form-model-item
              label="是否允许做其他项目"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 14 }"
            >
              {{ formData.exclude_other == "Y" ? "是" : "否" }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item
              label="是否允许做同类项目"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 14 }"
            >
              {{ formData.exclude_similar == "Y" ? "是" : "否" }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="参与者性别">
              {{ changeSex(formData.sex) }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="参与者年龄">
              {{ formData.age1 }}-{{ formData.age2 }}岁
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="样品名称">
              {{ formData.sample_name }}
            </a-form-model-item>
          </a-col>

          <!-- <a-col span="16">
            <a-form-model-item
              label="简介"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              {{ formData.info }}
            </a-form-model-item>
          </a-col> -->

          <a-col span="8">
            <a-form-model-item
              label="封面"
              :label-col="{ span: 6 }"
              :wrapper-col="{ span: 18 }"
            >
              <img
                :src="formData.extInfo.img"
                class="img1"
                @click="seeImg(formData.extInfo.img)"
              />
            </a-form-model-item>
          </a-col>

          <!-- <a-col span="8">
            <a-form-model-item label="报名上限">
              {{ formData.extInfo.regLimit }}
            </a-form-model-item>
          </a-col> -->

          <a-col span="8">
            <a-form-model-item label="名额上限">
              {{ formData.extInfo.quotaLimit }}
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="补助金额">
              {{ formData.point_final }}
            </a-form-model-item>
          </a-col>

          <a-col span="24">
            <a-form-model-item
              label=""
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              <!-- <a-textarea
              placeholder="请输入"
              v-model="formData.info"
            ></a-textarea> -->

              <!-- <div id="editDiv"></div> -->
              <a-collapse v-model="collapse0">
                <a-collapse-panel header="简介">
                  <div
                    style="
                      margin: -16px;
                      height: auto;
                      z-index: 100;
                      background: white;
                    "
                  >
                    <Editor
                      v-model="formData.feature"
                      :defaultConfig="editorConfig"
                      mode="default"
                      @onCreated="initEditor"
                      class="rightV"
                    />
                  </div>

                  <!-- <div id="toolbar"></div>
                <div id="editor"></div> -->
                </a-collapse-panel>
              </a-collapse>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title">调查问卷</div>
          </a-col>

          <a-col span="24">
            <vxe-grid
              style="margin: 10px 0"
              size="small"
              resizable
              row-id="id"
              show-overflow
              show-header-overflow
              highlight-hover-row
              border
              :data="question_data"
              :columns="columns"
            >
              <template v-slot:action="{ row }">
                <a @click="preview(row)">预览</a>
              </template>
            </vxe-grid>
          </a-col>

          <a-divider />

          <a-collapse v-model="collapse1" style="margin-bottom: 20px">
            <a-collapse-panel header="打卡设置">
              <div style="display: flex" id="yuyue">
                <vxe-grid
                  style="margin-top: 5px; margin-left: 10px; width: 800px"
                  size="small"
                  resizable
                  row-id="id"
                  show-header-overflow
                  highlight-hover-row
                  border
                  :data="clock_time"
                  :columns="columnsYu"
                >
                  <template v-slot:action="{ row }">
                    <a style="margin-left: 8px" @click="removeDay(row)">删除</a>
                  </template>
                  <template v-slot:timeArr="{ row }">
                    <div style="height: auto">
                      <div
                        v-for="(item, index) in row.items"
                        :key="index"
                        style="margin-top: 5px"
                      >
                        {{ item.begin_time }}~{{ item.end_time }}
                      </div>
                    </div>
                  </template>
                </vxe-grid>
              </div>
            </a-collapse-panel>
          </a-collapse>

          <a-collapse v-model="collapse2" style="margin-bottom: 20px">
            <a-collapse-panel header="预约设置">
              <div style="display: flex" id="yuyue">
                <vxe-grid
                  style="margin-top: 5px; margin-left: 10px; width: 800px"
                  size="small"
                  resizable
                  row-id="id"
                  show-header-overflow
                  highlight-hover-row
                  border
                  :data="yuyue_time"
                  :columns="columnsYu"
                >
                  <template v-slot:action="{ row }">
                    <a style="margin-left: 8px" @click="removeDay(row)">删除</a>
                  </template>
                  <template v-slot:timeArr="{ row }">
                    <div style="height: auto">
                      <div
                        v-for="(item, index) in row.items"
                        :key="index"
                        style="margin-top: 5px"
                      >
                        {{ item.begin_time }}~{{ item.end_time }}, 人数上限：{{
                          item.max_num
                        }}人
                      </div>
                    </div>
                  </template>
                </vxe-grid>
              </div>
            </a-collapse-panel>
          </a-collapse>

          <a-col span="24">
            <div class="title">消息设置-提醒管理员</div>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              项目开始报名提醒：{{
                formData.notify.a_apply_start == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              项目报名结束提醒：{{
                formData.notify.a_apply_end == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              问卷调查结束提醒：{{
                formData.notify.a_ques_end == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              每日打卡结束提醒：{{
                formData.notify.a_checkin_end == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              预约回访提醒：{{
                formData.notify.a_reserve_end == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title">消息设置-提醒志愿者</div>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              问卷调查提醒：{{
                formData.notify.v_checkin_end == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              每日打卡提醒：{{
                formData.notify.v_ques_end == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              回访预约开始提醒：{{
                formData.notify.v_reserve_start == "Y" ? "是" : "否"
              }}
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title">积分规则</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              按时打卡1次得{{ formData.point_once_reward }}积分
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              超时打卡1次得{{ formData.point_per_miss }}积分
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item
              :label-col="{ span: 0 }"
              :wrapper-col="{ span: 24 }"
            >
              1天内未打卡扣除{{ formData.point_day_miss }}积分
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>

    <preview ref="preview" :template="template"></preview>
  </div>
</template>

<script>
import preview from "@/components/preview";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

export default {
  data() {
    return {
      editor: null,
      editorConfig: {},
      collapse0: [0, 1],
      collapse1: [0, 1],
      collapse2: [0, 1],
      previewVisible: false,
      previewImage: "",
      subModal: false,
      template: {},
      formData: {
        extInfo: {},
        notify: {},
      },
      questionList: [],
      question_data: [],
      questionName: "",
      columns: [
        // { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "调查问卷名称",
          field: "question_name",
        },
         {
          title: "类型",
          field: "type_text",
        },
        {
          title: "开始时间",
          field: "begin_time",
        },
        {
          title: "结束时间",
          field: "end_time",
        },
      ],
      yuyue_time: [],
      clock_time: [],
      restDate: [],
      columnsYu: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "日期",
          field: "base_day",
          width: 140,
        },
        {
          title: "时间段",
          slots: {
            default: "timeArr",
          },
        },
      ],
    };
  },

  components: {
    preview,
    Editor,
  },

  computed: {},

  created() {},

  mounted() {},

  beforeDestroy() {
    const editor = this.editor;
    if (editor) {
      editor.destroy();
    }
  },

  methods: {
    initEditor(editor) {
      let that = this;
      this.editor = Object.seal(editor);
    },
    show(item) {
      this.subModal = true;

      let that = this;
      that
        .$getAction(that.$Interface.project_edit + "?id=" + item.id)
        .then((res) => {
          if (res.code == 200) {
            let row = res.data;
            that.formData = row;
            that.clock_time = [];
            // that.clock_time = row.extInfo.clock_time;
            that.getQuestionList(row.id);
            that.getYuyueData(row.id);
            that.getClockData(row.id);
            that.formData.tag_text = row.tag_text.join("，");
            that.formData.inspect_tag_text = row.inspect_tag_text.join("，");
          }
        });
    },

    getQuestionList(id) {
      let param = "?pageNum=" + 1 + "&pageSize=" + 100 + "&project_id=" + id;
      this.$getAction(this.$Interface.project_question_list + param).then(
        (res) => {
          if (res.code == 200) {
            let data = res.data.list;
            if (!data) {
              data = [];
            }
            this.question_data = data;
          }
        }
      );
    },
    getYuyueData(project_id) {
      let that = this;
      let param = "?project_id=" + project_id;
      this.$getAction(this.$Interface.manage_yuyue_list + param).then((res) => {
        if ((res.code = 200)) {
          let list = res.data.list;
          // console.log(list)
          that.yuyue_time = list;
        }
      });
    },
    getClockData(project_id) {
      let that = this;
      let param = "?project_id=" + project_id;
      this.$getAction(this.$Interface.pro_clock_list + param).then((res) => {
        if ((res.code = 200)) {
          let list = res.data.list;
          // console.log(list)
          that.clock_time = list;
        }
      });
    },
    preview(row) {
      let that = this;
      let param = "?id=" + row.question_id;
      that.$getAction(that.$Interface.question_edit + param).then((res) => {
        if (res.code == 200) {
          that.template = res.data;
          this.$refs.preview.showModal();
        }
      });
    },
    seeImg(img) {
      this.previewImage = img;
      this.previewVisible = true;
    },
    changeSex(sex) {
      if (sex == "I") {
        return "全部";
      }
      if (sex == "M") {
        return "男";
      }
      if (sex == "F") {
        return "女";
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.img1 {
  width: 150px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.rightV {
  width: 375px;
  height: 550px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 10px;
  margin: 10px auto;
  overflow: auto;
}
</style>
