export default {
	data() {
		return {
			menu: [{
					title: "首页",
					icon: "home",
					path: "analysis",
				},
				{
					title: "项目管理",
					icon: "unordered-list",
					children: [{
							title: "新增项目",
							path: "add_project",
						},
						{
							title: "项目列表",
							path: "project_list",
						},
						{
							title: "草稿列表",
							path: "draft",
						},
						{
							title: "调查问卷查询",
							path: "questionnaire_list",
						},
						{
							title: "打卡查询",
							path: "clock_list",
						},
						{
							title: "预约查询",
							path: "yuyue_list",
						},
						// {
						// 	title: "排行榜",
						// 	path: "rank_list",
						// },
						// {
						// 	title: "日记查询",
						// 	path: "diary_list",
						// },
					],
				},
				{
					title: "样品列表",
					icon: "unordered-list",
					children: [{
						title: "样品列表",
						path: "sample_list",
					}, ]
				},
				{
					title: "账号管理",
					icon: "team",
					children: [{
							title: "客户列表",
							path: "customer_list",
						},
						{
							title: "账号列表",
							path: "customer_zi_list",
						},
						// {
						// 	title: "客户分析",
						// 	path: "customer_analysis",
						// },
					],
				},
				{
					title: "志愿者管理",
					icon: "unordered-list",
					children: [{
						title: "志愿者列表",
						path: "volunteer_list",
					}, ],
				},
				{
					title: "商品管理",
					icon: "unordered-list",
					children: [{
						title: "商品列表",
						path: "goods_list",
					}, ],
				},
				{
					title: "模板管理",
					icon: "unordered-list",
					children: [{
						title: "调查问卷模板",
						path: "questionnaire_template",
					}],
				},
				{
					title: "系统设置",
					icon: "setting",
					children: [
						// {
						// 	title: "用户管理",
						// 	path: "person_list",
						// },
						// {
						// 	title: "角色管理",
						// 	path: "role_list",
						// },
						{
							title: "项目组管理",
							path: "depart_list",
						},
						// 	{
						// 	title: "检测项目管理",
						// 	path: "test_item",
						// },
						{
							title: "项目类别",
							path: "project_type_list",
						},
						{
							title: "测试部位",
							path: "project_tag_list",
						},
						{
							title: "测试标签",
							path: "test_tag_list",
						},
						{
							title: "志愿者标签",
							path: "zyz_tag_list",
						},
						// {
						// 	title: "编号规则",
						// 	path: "code_set",
						// },
						{
							title: "系统公告",
							path: "notice_list",
						},
					],
				},
				// {
				// 	title: "日志统计",
				// 	icon: "unordered-list",
				// 	children: [{
				// 			title: "日志列表",
				// 			path: "log_list",
				// 		},
				// 		// {
				// 		//   title: "报表查看",
				// 		//   path: "com_list",
				// 		// },
				// 	],
				// },

			],
		}
	},
	methods: {

	}
}