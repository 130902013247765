<!-- 调查问卷列表 -->
<template>
  <vxe-modal  style="z-index: 10"  
    v-model="showModal"
    title="调查问卷列表"
    width="800"
    height="600"
    resize
    destroy-on-close
    show-zoom
  >
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
    >
    </vxe-grid>
    <a-button @click="handleOk" type="primary" style="float: right"
      >确定</a-button
    >
  </vxe-modal>
</template>

<script>
export default {
  data() {
    let that = this;
    return {
      showModal: false,
      selectRow: {},
      template: {},
      queryParam: {},
      columns: [
        { type: "checkbox", width: 45, fixed: "left" },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "调查问卷名称",
          field: "name",
        },
        {
          title: "编号",
          field: "number",
        },
         {
          title: "类型",
          field: "type_text",
        },
        {
          title: "描述",
          field: "brief",
        },
        {
          title: "备注",
          field: "remark",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize;
            return that.$getAction(that.$Interface.question_list + param);
          },
        },
      },
      selectionRows:[],
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
    },
    show() {
      this.showModal = true;
    },
    handleOk() {
      if (!this.selectionRows.length) {
        this.$message.error("请选择调查问卷");
        return;
      }
      this.$emit("ok", this.selectionRows);
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
</style>
