<!-- 新增项目 -->
<template>
  <a-card>
    <!-- <a-form-model
      ref="ruleForm"
      :model="formData"
      :rules="formRules"
      class="ant-advanced-search-form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
    >
      <a-row :gutter="24">
        <a-col span="24">
          <div class="title">项目基本信息</div>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目类型" prop="project_type">
            <a-select
              :options="typeOptions"
              v-model="formData.project_type"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目名称" prop="project_name">
            <a-input
              placeholder="请输入"
              v-model="formData.project_name"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目编号" prop="code">
            <a-input placeholder="请输入" v-model="formData.code"></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="标签">
            <a-select
              placeholder="请选择"
              v-model="formData.tag"
              :options="tagList"
              mode="multiple"
            ></a-select>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="联系方式">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.phone"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="调查问卷">
            <a-select
              :options="[{ label: '调查问卷1', value: '调查问卷1' }]"
              v-model="formData.questionnaire"
              placeholder="请选择"
              style="width: 140px"
            />
            <a-button
              type="primary"
              style="padding: 0 8px; margin-left: 5px"
              @click="preview"
              >预览</a-button
            >
          </a-form-model-item>
        </a-col>

        <a-col span="16">
          <a-form-model-item
            label="简介"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 20 }"
          >
            <a-textarea
              type="ar"
              placeholder="请输入"
              v-model="formData.info"
            ></a-textarea>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            label="封面"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
          >
            <a-upload
              list-type="picture-card"
              :file-list="fileListMain"
              @preview="handlePreview"
              :remove="handleRemove1"
              :before-upload="beforeUpload1"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
        </a-col>

        <a-divider />

        <a-col span="24">
          <div class="title">项目样品信息</div>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="样品名称">
            <a-input
              placeholder="请输入"
              v-model="formData.sample_name"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="样品编号">
            <a-input
              placeholder="请输入"
              v-model="formData.sample_num"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="样品批号">
            <a-input
              placeholder="请输入"
              v-model="formData.sample_batchCode"
            ></a-input>
          </a-form-model-item>
        </a-col>

        <a-divider />

        <a-col span="24">
          <div class="title">打卡设置</div>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="打卡规则">
            <a-select :options="clock_opt" v-model="formData.clock_rule" />
          </a-form-model-item>
        </a-col>

        <a-col span="8" style="margin-bottom: -24px">
          <a-form-model-item label="第1次打卡时间">
            <a-form-model-item :style="{ display: 'inline-block' }">
              <a-time-picker
                placeholder="请选择"
                format="HH:mm"
                v-model="formData.clock_firstTime1"
                valueFormat="HH:mm"
                style="width: 90px"
              />
            </a-form-model-item>
            <span> - </span>
            <a-form-model-item
              :style="{ display: 'inline-block' }"
              prop="clock_firstTime2"
            >
              <a-time-picker
                placeholder="请选择"
                format="HH:mm"
                v-model="formData.clock_firstTime2"
                valueFormat="HH:mm"
                style="width: 90px"
              />
            </a-form-model-item>
          </a-form-model-item>
        </a-col>

        <a-col span="8" style="margin-bottom: -24px">
          <a-form-model-item label="第2次打卡时间">
            <a-form-model-item
              :style="{ display: 'inline-block' }"
              prop="clock_secondTime1"
            >
              <a-time-picker
                placeholder="请选择"
                format="HH:mm"
                v-model="formData.clock_secondTime1"
                valueFormat="HH:mm"
                style="width: 90px"
              />
            </a-form-model-item>
            <span> - </span>
            <a-form-model-item
              :style="{ display: 'inline-block' }"
              prop="clock_secondTime2"
            >
              <a-time-picker
                placeholder="请选择"
                format="HH:mm"
                v-model="formData.clock_secondTime2"
                valueFormat="HH:mm"
                style="width: 90px"
              />
            </a-form-model-item>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目开始时间">
            <a-date-picker
              placeholder="请选择"
              format="YYYY-MM-DD"
              :locale="locale"
              v-model="formData.clock_startTime"
            ></a-date-picker>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="项目结束时间">
            <a-date-picker
              placeholder="请选择"
              format="YYYY-MM-DD"
              :locale="locale"
              v-model="formData.clock_endTime"
            ></a-date-picker>
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            label="是否有做其他项目"
            :label-col="{ span: 10 }"
            :wrapper-col="{ span: 14 }"
          >
            <a-select
              :options="is_opt"
              v-model="formData.if_other"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="参与者性别">
            <a-select
              :options="sex_opt"
              v-model="formData.sex"
              placeholder="请选择"
            />
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item label="参与者年龄">
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.age1"
              style="width: 82px"
              min="0"
            ></a-input>
            -
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.age2"
              style="width: 82px"
              min="0"
            ></a-input>
            岁
          </a-form-model-item>
        </a-col>

        <a-divider />

        <a-col span="24">
          <div class="title">积分规则 (10积分=1元)</div>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            按时打卡1次得
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.ontime_reward"
              style="width: 85px"
              min="0"
            ></a-input
            >积分
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            超时打卡1次得
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.overtime_reward"
              style="width: 85px"
              min="0"
            ></a-input
            >积分
          </a-form-model-item>
        </a-col>

        <a-col span="8">
          <a-form-model-item
            :label-col="{ span: 0 }"
            :wrapper-col="{ span: 24 }"
          >
            1天内未打卡扣除
            <a-input
              type="number"
              placeholder="请输入"
              v-model="formData.no_clock"
              style="width: 85px"
              min="0"
            ></a-input
            >积分
          </a-form-model-item>
        </a-col>

        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="submitEvent"> 保存 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
            清空
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <vxe-modal  style="z-index: 10"  
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>

    <preview ref="preview" :template="template"></preview> -->

<edit-modal />
  </a-card>
</template>

<script>

import editModal from './modal/edit_modal'

export default {
  data() {
    return{}
  },

  components: { editModal},

  computed: {},

  created() {},

  mounted() {},

  methods: {
   
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
