<!-- 调查问卷模板 -->
<template>
  <div>
    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="1000"
      height="90%"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <div style="display: flex">
        <div class="leftV">
          <a-collapse v-model="collapse">
            <a-collapse-panel header="问卷属性">
              <a-form-model
                :label-col="firstSpan.labelCol"
                :wrapper-col="firstSpan.wrapperCol"
              >
                <a-form-model-item label="问卷标题:">
                  <a-textarea
                    v-model="template.name"
                    placeholder="请输入"
                    :auto-size="{ minRows: 3 }"
                  />
                </a-form-model-item>
                <a-form-model-item label="描述:">
                  <a-textarea
                    v-model="template.brief"
                    placeholder="请输入"
                    :auto-size="{ minRows: 3 }"
                  />
                </a-form-model-item>
                <a-form-model-item label="问卷编号:">
                  <a-input v-model="template.number" placeholder="请输入" />
                </a-form-model-item>
                <a-form-model-item label="类型:">
                  <a-select
                    :options="optType"
                    v-model="template.type"
                    placeholder="请选择"
                  />
                </a-form-model-item>
              </a-form-model>
            </a-collapse-panel>

            <a-collapse-panel header="表单组件">
              <draggable
                v-model="componentsList"
                draggable=".selectItem"
                animation="300"
                :options="{
                  sort: false,
                  group: { name: 'field', pull: 'clone', put: false },
                }"
                @clone="onClone"
              >
                <template v-for="(tc, tcIndex) in componentsList">
                  <a-col
                    span="12"
                    class="item selectItem"
                    :key="'tcIndex' + tcIndex"
                    ><p class="aLine">{{ tc.title }}</p></a-col
                  >
                </template>
              </draggable>
            </a-collapse-panel>

            <a-collapse-panel header="组件属性">
              <a-form-model
                :model="template.editForm"
                ref="editForm"
                :label-col="firstSpan.labelCol"
                :wrapper-col="firstSpan.wrapperCol"
              >
                <a-form-model-item label="小标题:">
                  <a-textarea
                    v-model="template.editForm.label"
                    placeholder="请输入"
                    :auto-size="{ minRows: 3 }"
                    @change="updateEdit"
                  />
                </a-form-model-item>

                <a-form-model-item label="是否必填:">
                  <a-radio-group
                    v-model="template.editForm.verify"
                    @change="updateEdit"
                  >
                    <a-radio :value="true"> 是 </a-radio>
                    <a-radio :value="false"> 否 </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <template v-if="template.editForm.hasOpt">
                  <a-form-model-item label="选项:">
                    <template
                      v-for="(item, index) in template.editForm.options"
                    >
                      <a-badge :key="'badge' + index">
                        <a-icon
                          slot="count"
                          type="close-circle"
                          class="closeBadeg"
                          @click="deleteOptions(index)"
                        />
                        <a-input
                          v-model="item.label"
                          @input="
                            (item.value = item.label),
                              (item.text = item.label),
                              (item.label = item.label)
                          "
                          @change="updateEdit"
                          :placeholder="'请输入选项' + (index + 1)"
                          style="width: 200px"
                        ></a-input>
                        分数
                        <a-input-number
                          v-model="item.score"
                          :min="0"
                          @input="
                            (item.value = item.score),
                              (item.text = item.score),
                              (item.label = item.score)
                          "
                          @change="updateEdit"
                          style="width: 50px"
                        ></a-input-number>
                      </a-badge>
                    </template>
                    <a-button type="primary" @click="addInput" size="small">
                      添加选项
                    </a-button>
                  </a-form-model-item>
                </template>
              </a-form-model>
            </a-collapse-panel>
          </a-collapse>

          <div class="btV">
            <a-button type="primary" @click="save"> 保存 </a-button>
            <a-button @click="clear" style="margin-left: 15px"> 清空 </a-button>
            <a-button type="primary" @click="preview" style="margin-left: 15px">
              预览
            </a-button>
          </div>
        </div>

        <div class="rightV">
          <div class="gutter-box">
            <div class="title">{{ template.name }}</div>
            <div class="info">{{ template.brief }}</div>

            <!-- <a-input v-model="template.name" style="color: black" class="title" /> -->
            <draggable
              group="field"
              :list="template.item"
              animation="300"
              class="gutter-box1"
              @change="toChange"
            >
              <div
                v-for="(item, index) in template.item"
                :key="index"
                class="formItem"
                :class="{ edit: template.currentIndex == index }"
              >
                <a-icon
                  type="close-circle"
                  class="close"
                  @click="closeItem(index)"
                ></a-icon>
                <a-icon
                  type="edit"
                  class="itemEdit"
                  @click="itemEdit(item, index)"
                ></a-icon>

                <!-- <a-input v-model="item.label" class="itemLabel" /> -->
                <a-form-model-item
                  :label="item.label"
                  :rules="item.verify ? item.rules : null"
                >
                  <a-input
                    placeholder="请输入"
                    v-model="item.value"
                    v-if="item.type == 'input'"
                  ></a-input>

                  <a-input
                    placeholder="请输入"
                    v-model="item.value"
                    type="number"
                    v-if="item.type == 'number'"
                  ></a-input>

                  <a-textarea
                    placeholder="请输入"
                    v-model="item.value"
                    v-if="item.type == 'textarea'"
                    :auto-size="{ minRows: 3 }"
                  />

                  <!-- <a-radio-group
                  v-model="item.value"
                  v-if="item.type == 'radio'"
                  :options="item.options"
                ></a-radio-group> -->

                  <!-- <a-checkbox-group
                  v-model="item.value"
                  v-if="item.type == 'checkbox'"
                  :options="item.options"
                ></a-checkbox-group> -->

                  <a-radio-group
                    v-model="item.value"
                    v-if="item.type == 'radio'"
                  >
                    <a-radio
                      :value="r.value"
                      v-for="(r, index) in item.options"
                      :key="index"
                    >
                      {{ r.label }}
                      <span v-if="r.score">{{ "（" + r.score + "分）" }}</span>
                    </a-radio>
                  </a-radio-group>

                  <a-checkbox-group
                    v-model="item.value"
                    v-if="item.type == 'checkbox'"
                  >
                    <a-checkbox
                      :value="r.value"
                      v-for="(r, index) in item.options"
                      :key="index"
                    >
                      {{ r.label }}
                      <span v-if="r.score">{{ "（" + r.score + "分）" }}</span>
                    </a-checkbox>
                  </a-checkbox-group>

                  <div @click="uploadC(index)" v-if="item.type == 'imgRequest'">
                    <a-upload
                      list-type="picture-card"
                      :file-list="fileListMain"
                      @preview="handlePreview"
                      :remove="handleRemove1"
                      :before-upload="beforeUpload1"
                      multiple
                    >
                      <div>
                        <a-icon type="plus" />
                        <div class="ant-upload-text">上传</div>
                      </div>
                    </a-upload>
                  </div>

                  <a-upload
                    v-if="item.type == 'uploadImg'"
                    list-type="picture-card"
                  >
                    <div>
                      <a-icon type="plus" />
                      <div class="ant-upload-text">上传</div>
                    </div>
                  </a-upload>
                </a-form-model-item>
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" class="pImg" />
    </vxe-modal>

    <preview ref="preview" :template="template"></preview>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import preview from "@/components/preview";
const opt0 = [
  {
    label: "选项一",
    text: "选项一",
    value: "选项一",
    score: 1,
  },
  {
    label: "选项二",
    text: "选项二",
    value: "选项二",
    score: 2,
  },
  {
    label: "选项三",
    text: "选项三",
    value: "选项三",
    score: 3,
  },
  {
    label: "选项四",
    text: "选项四",
    value: "选项四",
    score: 4,
  },
];
export default {
  data() {
    return {
      tooltips: ["1分", "2分"],
      collapse: [0, 1, 2],
      subModal: false,
      selectRow: null,
      firstSpan: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
      },
      template: {
        currentIndex: null,
        editForm: {},
        name: "问卷标题",
        number: "",
        brief: "",
        item: [],
        type: "",
      },
      optType: [
        { label: "初筛 ", value: "P" },
        { label: "初次 ", value: "F" },
        { label: "回访 ", value: "B" },
      ],
      componentsList: [
        {
          title: "输入框",
          label: "小标题",
          type: "input",
          value: "",
          code: "",
          verify: true,
          rules: [{ required: true, errorMessage: "请输入" }],
        },
        {
          title: "数字输入框",
          label: "小标题",
          type: "number",
          value: "",
          code: "",
          verify: true,
          rules: [{ required: true, errorMessage: "请输入" }],
        },
        {
          title: "文本框",
          label: "小标题",
          type: "textarea",
          value: "",
          code: "",
          verify: true,
          rules: [{ required: true, errorMessage: "请输入" }],
        },
        {
          title: "单选项",
          value: "",
          type: "radio",
          code: "",
          verify: true,
          label: "小标题",
          rules: [{ required: true, errorMessage: "请选择" }],
          options: opt0,
          hasOpt: true,
        },
        {
          title: "多选项",
          value: [],
          type: "checkbox",
          code: "",
          verify: true,
          label: "小标题",
          rules: [{ required: true, errorMessage: "请选择" }],
          options: opt0,
          hasOpt: true,
        },
        {
          title: "上传的图片示例",
          label: "上传的图片示例",
          type: "imgRequest",
          value: "",
          code: "",
          verify: false,
          rules: [{ required: true, errorMessage: "请选择" }],
        },
        {
          title: "上传图片",
          label: "上传图片",
          type: "uploadImg",
          value: "",
          code: "",
          verify: true,
          rules: [{ required: true, errorMessage: "请选择" }],
        },
      ],
      fileListMain: [],
      previewVisible: false,
      previewImage: "",
      itemIndex: null,
    };
  },

  components: { preview, draggable },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    show(data) {
      let that = this;
      this.subModal = true;
      this.selectRow = false;
      this.itemIndex = null
      this.template = {
        currentIndex: null,
        editForm: {},
        name: "问卷标题",
        number: "",
        brief: "",
        item: [],
        type: "",
      };
      that.fileListMain = [];
      if (data) {
        let hasIndex = this._.findIndex(data.item, { type: "imgRequest" });
        if (hasIndex > -1) {
          let d = data.item[hasIndex];
          if (d.value.length) {
            let arr = d.value;
            for (let i in arr) {
              let a = arr[i];
              let o = {
                uid: i,
                name: i,
                status: "done",
                url: a,
              };
              that.fileListMain.push(o);
            }
          }
        }
        this.template = data;
        this.selectRow = true;
      }
    },
    previewEvent(row) {
      let data = JSON.parse(JSON.stringify(row));
      this.template = {
        currentIndex: null,
        editForm: {},
        name: data.name,
        number: data.number,
        brief: data.brief,
        item: data.item ? data.item : [],
      };
      this.preview();
    },

    onClone(e) {
      let that = this;
      let item = that.componentsList[e.oldIndex];
      that.template.editForm = JSON.parse(JSON.stringify(item));
    },
    toChange(e) {
      if (e.added) {
        this.template.currentIndex = e.added.newIndex;
      }
      // console.log(this.template.item);
    },
    closeItem(index) {
      this.template.item.splice(index, 1);
    },
    itemEdit(item, index) {
      let that = this;
      that.template.currentIndex = index;
      that.template.editForm = JSON.parse(JSON.stringify(item));
    },
    updateEdit() {
      let that = this;
      that.template.item[that.template.currentIndex] = that.template.editForm;
    },

    deleteOptions(index) {
      this.template.editForm.options.splice(index, 1);
      this.updateEdit();
    },
    addInput() {
      let one = { label: "", value: "", text: "", score: "" };
      this.template.editForm.options.push(one);
    },

    save() {
      let that = this;
      if (!that.template.name) {
        that.$message.error("问卷标题不能为空");
        return;
      }
      if (!that.template.item) {
        that.$message.error("问卷内容不能为空");
        return;
      }

      if (this.itemIndex) {
        let urlArr = this.fileListMain.map((it) => {
          return it.url;
        });
        this.template.item[this.itemIndex].value = urlArr;
      }

      let param = {
        brief: that.template.brief,
        // customerID: "",
        name: that.template.name,
        number: that.template.number,
        item: that.template.item,
        status: 1,
        id: that.template.id,
        type: that.template.type,
      };
      that.$postAction(that.$Interface.question_edit, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("保存成功");
          that.$emit("ok");
          this.subModal = false;
        } else {
          that.$message.error("保存失败");
        }
      });
    },
    clear() {
      let that = this;
      that.template = {
        currentIndex: null,
        editForm: {},
        name: "",
        number: "",
        brief: "",
        item: [],
      };
    },
    preview() {
      this.$refs.preview.showModal();
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleRemove1(file) {
      const index = this.fileListMain.indexOf(file);
      const newfileListMain = this.fileListMain.slice();
      newfileListMain.splice(index, 1);
      this.fileListMain = newfileListMain;
    },
    uploadC(index) {
      this.itemIndex = index;
    },
    beforeUpload1(file) {
      let that = this;
      this.$uploadAction(file).then((res) => {
        if (res.code == 200) {
          let img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.data.url,
          };
          that.fileListMain = [...that.fileListMain, img];
          let urlArr = that.fileListMain.map((it) => {
            return it.url;
          });
          that.template.item[that.itemIndex].value = urlArr;
        }
      });
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.leftV {
  width: 400px;
}

.rightV {
  width: 375px;
  height: 667px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin-left: 100px;
  padding: 10px;
  overflow: hidden;
}

.title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  border: none;
  color: black;
}

.itemLabel {
  font-weight: bold;
  border: none;
}

.closeBadeg {
  color: red;
  cursor: pointer;
  z-index: 1;
}

.gutter-box {
  width: 100%;
  height: calc(100% - 30px);
  // padding-bottom: 50px;
  overflow: auto;
}

.gutter-box1 {
  width: 100%;
  min-height: calc(100% - 30px);
  // height: 100%;
  padding-bottom: 50px;
}

.edit {
  border: 1px dashed red;
}

.btV {
  margin: 15px;
}

/*滚动条样式*/
.gutter-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #fafafa;
  border-radius: 20px;
  background-color: #f5f5f5;
}
.gutter-box::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
.gutter-box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 5px #fafafa;
  background-color: #555;
}

.item {
  cursor: move;
}

.dragItem {
  position: relative;
}

.close {
  color: red;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

.itemEdit {
  color: #1890ff;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

.formItem {
  position: relative;
  padding: 0 5px;
  padding-top: 10px;
  margin-bottom: 10px;
}

.pImg {
  max-width: 700px;
  max-height: 700px;
}
</style>

<style lang="scss">
.rightV {
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: block;
    white-space: normal;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .ant-form-item-label {
    white-space: normal;
    text-align: left;
    line-height: normal;
    font-weight: bold;
    font-size: 15px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
