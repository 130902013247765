<!--  -->
<template>
  <vxe-modal
    style="z-index: 1000"
    v-model="ifShow"
    :width="600"
    :height="600"
    title="用户列表"
    resize
    destroy-on-close
    show-zoom
  >
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
    >
    </vxe-grid>

    <a-button type="primary" style="float: right" @click="comfirm"
      >确定</a-button
    >
  </vxe-modal>
</template>

<script>
export default {
  data() {
    let that = this
    return {
      ifShow: false,
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
      },
      roleOptions: [
        { label: "管理员", value: "管理员" },
        { label: "客户", value: "客户" },
      ],
      statusOptions: [
        { label: "启用", value: "启用" },
        { label: "停用", value: "停用" },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
       {
          title: "用户名称",
          field: "nick_name",
        },
       
        {
          title: "状态",
          field: "status_text",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
             if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&customerID=" +
              customer_id;
            return that.$getAction(that.$Interface.customer_zi_list + param);

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    name: "admin",
                    sex: "男",
                    age: "25",
                    address: "广东省广州市白云区启德路1号",
                    phone: 13392211555,
                    account: "admin",
                    password: "123465",
                    role: "管理员",
                    remark: "",
                    createTime: "2022-3-9",
                    status: "启用",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    show() {
      this.ifShow = true;
    },
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    comfirm() {
      let that = this;
       if(!that.selectionRows.length){
        that.$message.error('请选择人员')
        return
      }
      this.$emit("ok", that.selectionRows);
      that.ifShow = false;
    },
  },
};
</script>

<style scoped>
</style>
