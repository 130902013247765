<!-- 预览 -->
<template>
  <vxe-modal
    style="z-index: 10"
    v-model="show"
    title="预览"
    width="450"
    height="750"
    min-width="450"
    min-height="750"
    resize
    destroy-on-close
    show-zoom
  >
    <div class="rightV">
      <div class="gutter-box">
        <div class="title">{{ template.name }}</div>
        <div>{{ template.brief }}</div>
        <div
          v-for="(item, index) in template.item"
          :key="index"
          class="formItem"
        >
          <a-form-model-item
            :label="item.label"
            :rules="item.verify ? item.rules : null"
          >
            <a-input
              placeholder="请输入"
              v-model="item.value"
              v-if="item.type == 'input'"
            ></a-input>

            <a-input
              placeholder="请输入"
              v-model="item.value"
              type="number"
              v-if="item.type == 'number'"
            ></a-input>

            <a-textarea
              placeholder="请输入"
              v-model="item.value"
              v-if="item.type == 'textarea'"
              :auto-size="{ minRows: 3 }"
            />

            <!-- <a-radio-group
            v-model="item.value"
            v-if="item.type == 'radio'"
            :options="item.options"
          ></a-radio-group>

          <a-checkbox-group
            v-model="item.value"
            v-if="item.type == 'checkbox'"
            :options="item.options"
          ></a-checkbox-group> -->
            <a-radio-group v-model="item.value" v-if="item.type == 'radio'">
              <a-radio
                :value="r.value"
                v-for="(r, index) in item.options"
                :key="index"
              >
                {{ r.label }}
                <span v-if="r.score">{{ "（" + r.score + "分）" }}</span>
              </a-radio>
            </a-radio-group>

            <a-checkbox-group
              v-model="item.value"
              v-if="item.type == 'checkbox'"
            >
              <a-checkbox
                :value="r.value"
                v-for="(r, index) in item.options"
                :key="index"
              >
                {{ r.label }}
                <span v-if="r.score">{{ "（" + r.score + "分）" }}</span>
              </a-checkbox>
            </a-checkbox-group>

            <div v-if="item.type == 'imgRequest'">
              <template v-for="it in item.value">
                <img class="img1" :src="it" :key="it" />
              </template>
            </div>

            <a-upload v-if="item.type == 'uploadImg'" list-type="picture-card">
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </a-form-model-item>
        </div>
      </div>
    </div>
  </vxe-modal>
</template>

<script>
export default {
  props: ["template"],
  data() {
    return {
      show: false,
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    showModal() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 102px;
  height: 102px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.rightV {
  width: 375px;
  height: 667px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding: 10px;
  margin: auto;
  overflow: hidden;
}

.gutter-box {
  width: 100%;
  height: calc(100% - 30px);
  padding-bottom: 50px;
  overflow: auto;
}

/*滚动条样式*/
.gutter-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #fafafa;
  border-radius: 20px;
  background-color: #f5f5f5;
}
.gutter-box::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}
.gutter-box::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 5px #fafafa;
  background-color: #555;
}

.title {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  border: none;
  color: black;
}

.itemLabel {
  font-weight: bold;
  border: none;
}

.formItem {
  position: relative;
  padding: 0 5px;
  padding-top: 10px;
  margin-bottom: 10px;
}
</style>

<style lang="scss">
.rightV {
  .ant-radio-wrapper,
  .ant-checkbox-wrapper {
    display: block;
    white-space: normal;
  }
  .ant-form-item-label {
    white-space: normal;
    text-align: left;
    line-height: normal;
    font-weight: bold;
    font-size: 15px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>