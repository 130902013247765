<!-- 商户子列表管理 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="用户名称">
            <a-input placeholder="请输入" v-model="queryParam.name"></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row.id)">删除</a>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="用户名称"
          field="nick_name"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <!-- <vxe-form-item
          title="部门"
          field="depart"
          span="24"
          :item-render="{
            name: '$select',
            options: departOpts,
            attrs: { placeholder: '请选择' },
          }"
        ></vxe-form-item> -->

        <!-- <vxe-form-item
          title="性别"
          field="sex"
          span="24"
          :item-render="{
            name: '$select',
            options: sexOptions,
            attrs: { placeholder: '请选择' },
          }"
        ></vxe-form-item>

        <vxe-form-item
          title="年龄"
          field="age"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="电话"
          field="phone"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="地址"
          field="address"
          span="24"
          :item-render="{ name: 'textarea', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item> -->

        <vxe-form-item
          title="登录账号"
          field="account"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="登录密码"
          field="password"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="备注"
          field="remark"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="状态"
          field="status"
          span="24"
          :item-render="{
            name: '$select',
            options: statusOptions,
            attrs: { placeholder: '请选择' },
          }"
        ></vxe-form-item>

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </a-card>
</template>

<script>
export default {
  data() {
    let that = this;
    return {
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
      },
      departOpts: [
        { label: "销售部", value: "销售部" },
        { label: "研发部", value: "研发部" },
        { label: "人事部", value: "人事部" },
      ],
      sexOptions: [
        { label: "男", value: "男" },
        { label: "女", value: "女" },
      ],
      roleOptions: [
        { label: "管理员", value: "管理员" },
        { label: "客户", value: "客户" },
      ],
      statusOptions: [
        { label: "启用", value: 'Y' },
        { label: "停用", value: 'N' },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        //  {
        //   title: "公司名称",
        //   field: "customer_name",
        // },
        {
          title: "用户名称",
          field: "nick_name",
        },
        // {
        //   title: "性别",
        //   field: "sex",
        // },
        // {
        //   title: "年龄",
        //   field: "age",
        // },
        // {
        //   title: "电话",
        //   field: "phone",
        // },
        // {
        //   title: "地址",
        //   field: "address",
        // },
        {
          title: "登录账号",
          field: "account",
        },
        {
          title: "登录密码",
          field: "password",
        },
        // {
        //   title: "部门",
        //   field: "depart",
        // },
        //  {
        //   title: "角色",
        //   field: "role",
        // },
        {
          title: "创建时间",
          field: "createdAt",
        },
        {
          title: "备注",
          field: "remark",
        },
        {
          title: "状态",
          field: "status_text",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&customerID=" +
              customer_id;
            return that.$getAction(that.$Interface.customer_zi_list + param);

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    name: "admin",
                    sex: "男",
                    age: "25",
                    address: "广东省广州市白云区启德路1号",
                    phone: 13392211555,
                    account: "admin",
                    password: "123465",
                    role: "管理员",
                    remark: "",
                    createTime: "2022-3-9",
                    status: "启用",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        depart: "",
        status: 1,
      },
      formRules: {
        name: [{ required: true, message: "请输入" }],
        contacts: [{ required: true, message: "请输入" }],
        phone: [{ required: true, message: "请输入" }],
        address: [{ required: true, message: "请输入" }],
        account: [{ required: true, message: "请输入" }],
        password: [{ required: true, message: "请输入" }],
        role: [{ required: true, message: "请选择" }],
        status: [{ required: true, message: "请选择" }],
        sex: [{ required: true, message: "请选择" }],
        age: [{ required: true, message: "请输入" }],
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
      this.formData = {
        depart: "",
        status: 1,
      };
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {
      let that = this;
      that
        .$postAction(that.$Interface.customer_zi_del, { id: ids.toString() })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("删除成功");
            that.loadData();
          } else {
            that.$message.error("删除失败");
          }
        });
    },
    submitEvent() {
      let that = this;
      let param = JSON.parse(JSON.stringify(this.formData));
      let userInfo = JSON.parse(sessionStorage.userInfo);
      let customer_id = "";
      customer_id = JSON.parse(sessionStorage.userInfo).user_id;
      if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
        customer_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
      }
      param.customer_id = customer_id;
      that.$postAction(that.$Interface.customer_zi_edit, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("保存成功");
          that.loadData();
          that.subModal = false;
        } else {
          that.$message.error("保存失败");
        }
      });
    },
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
    },
  },
};
</script>

<style scoped>
</style>
