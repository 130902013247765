<!-- 功效评价 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="创建时间">
            <a-date-picker
              placeholder="开始时间"
              :locale="locale"
              v-model="queryParam.startTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束时间"
              :locale="locale"
              v-model="queryParam.endTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="问卷名称">
            <a-input placeholder="请输入" v-model="queryParam.name"></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="问卷编号">
            <a-input placeholder="请输入" v-model="queryParam.number"></a-input>
          </a-form-item>
        </a-col>

        <!-- <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="状态">
            <a-select
              placeholder="请输入"
              v-model="queryParam.status"
              :options="status_opt"
            ></a-select>
          </a-form-item>
        </a-col> -->

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="previewEvent(row)">预览</a>
        <a style="margin-left: 8px" @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row.id)">删除</a>
        <a style="margin-left: 8px" @click="copy(row)">复制</a>
      </template>
    </vxe-grid>

    <preview ref="preview" :template="template"></preview>

    <questionTeplate ref="questionTeplate" @ok="loadData" />
  </a-card>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import preview from "@/components/preview";
import questionTeplate from "./modal/question_teamplate.vue";
import { Row } from "ant-design-vue";
export default {
  data() {
    let _this = this;
    return {
      locale,
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: _this.$Interface.prod_list,
      },

      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          width: 150,
          slots: {
            default: "action",
          },
        },
        {
          title: "调查问卷名称",
          field: "name",
        },
        {
          title: "编号",
          field: "number",
        },
        {
          title: "类型",
          field: "type_text",
        },
        {
          title: "创建时间",
          field: "createdAt",
        },
        {
          title: "备注",
          field: "remark",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize
              //  + '&is_deleted=2';
            let rdata = _this.$getAction(this.$Interface.question_list + param);
            return rdata;

            let data = {
              data: {
                current: 1,
                list: [
                  {
                    name: "调查问卷1",
                    number: "pj1222",
                    createBy: "admin",
                    createTime: "2022-3-7",
                    status: "未发布",
                    item: [
                      {
                        title: "输入框",
                        label: "姓名",
                        type: "input",
                        value: "",
                        code: "",
                        verify: true,
                        rules: [
                          {
                            required: true,
                            errorMessage: "请输入",
                          },
                        ],
                      },
                      {
                        title: "输入框",
                        label: "身份证号",
                        type: "input",
                        value: "",
                        code: "",
                        verify: true,
                        rules: [
                          {
                            required: true,
                            errorMessage: "请输入",
                          },
                        ],
                      },
                      {
                        title: "单选项",
                        value: [],
                        type: "radio",
                        code: "",
                        verify: true,
                        label:
                          "(单选)洗完脸后的2-3小时，不在脸上涂任何保湿/防晒产品、化妆水、粉底或任何产品，这时如果在明亮的光线下照镜子，你的前额和脸颊部位：",
                        rules: [
                          {
                            required: true,
                            errorMessage: "请选择",
                          },
                        ],
                        options: [
                          {
                            label: "能看到反光",
                            text: "能看到反光",
                            value: "能看到反光",
                          },
                          {
                            label: "仍有紧绷感",
                            text: "仍有紧绷感",
                            value: "仍有紧绷感",
                          },
                          {
                            label: "能够回复正常的润泽感而且在镜中看不到反光",
                            text: "能够回复正常的润泽感而且在镜中看不到反光",
                            value: "能够回复正常的润泽感而且在镜中看不到反光",
                          },
                          {
                            label: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                            text: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                            value: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                          },
                        ],
                        hasOpt: true,
                      },
                      {
                        title: "多选项",
                        value: [],
                        type: "checkbox",
                        code: "",
                        verify: true,
                        label:
                          "(多选)上妆或使用粉底，但是不涂干的粉（如质地干燥的粉饼或散粉），2-3小时后，你的妆容看起来： ",
                        rules: [
                          {
                            required: true,
                            errorMessage: "请选择",
                          },
                        ],
                        options: [
                          {
                            label: "出现皮屑，有的粉底在皱纹里结成小块",
                            text: "出现皮屑，有的粉底在皱纹里结成小块",
                            value: "出现皮屑，有的粉底在皱纹里结成小块",
                          },
                          {
                            label: "光滑",
                            text: "光滑",
                            value: "光滑",
                          },
                          {
                            label: "出现闪亮",
                            text: "出现闪亮",
                            value: "出现闪亮",
                          },
                          {
                            label: "出现条纹并且闪亮",
                            text: "出现条纹并且闪亮",
                            value: "出现条纹并且闪亮",
                          },
                        ],
                        hasOpt: true,
                      },
                    ],
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      template: {
        currentIndex: null,
        editForm: {},
        name: "问卷标题",
        number: "",
        brief: "",
        item: [],
      },
    };
  },

  components: {
    preview,
    questionTeplate,
  },

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      // this.subModal = true;
      // this.selectRow = false;
      // this.clear();
      this.template.name = "问卷标题";
      this.$refs.questionTeplate.show();
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {
      let that = this;
      that
        .$postAction(that.$Interface.question_del, { id: ids.toString() })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("删除成功");
            that.loadData();
          } else {
            that.$message.error("删除失败");
          }
        });
    },
    editEvent(row) {
      // this.subModal = true;
      // this.selectRow = true;
      let data = JSON.parse(JSON.stringify(row));
      this.template = {
        currentIndex: null,
        editForm: {},
        name: data.name,
        number: data.number,
        brief: data.brief,
        item: data.item ? data.item : [],
        id: data.id,
        type:data.type,
      };
      this.$refs.questionTeplate.show(this.template);
    },
    copy(row) {
      let that = this
      let data = JSON.parse(JSON.stringify(row));
      data.name = data.name + "(复制)";
      data.number = data.number + "(复制)";
      delete data.id;
      that.$postAction(that.$Interface.question_edit, data).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData()
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    previewEvent(row) {
      let data = JSON.parse(JSON.stringify(row));
      this.template = {
        currentIndex: null,
        editForm: {},
        name: data.name,
        number: data.number,
        brief: data.brief,
        item: data.item ? data.item : [],
      };
      this.preview();
    },

    preview() {
      this.$refs.preview.showModal();
    },
  },
};
</script>

<style scoped lang="scss">
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
</style>

