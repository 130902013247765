<!-- 后台 -->
<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
        <div class="logo">天纵睿知化妆品评价中心</div>

        <a-menu
          theme1="dark"
          mode="inline"
          :defaultSelectedKeys="menuVal"
          :openKeys.sync="openKeys"
          @openChange="onOpenChange"
        >
          <template v-for="(item, index) in menu">
            <a-menu-item
              :key="item.path"
              @click="goUrl(item)"
              v-if="checkSf(item) && !item.children"
            >
              {{ item.title }}</a-menu-item
            >
            <a-sub-menu
              :key="'sub' + index"
              v-if="checkSf(item) && item.children"
            >
              <span slot="title"
                ><a-icon :type="item.icon" /><span>{{ item.title }}</span></span
              >
              <template v-for="it in item.children">
                <a-menu-item
                  :key="it.path"
                  @click="goUrl(it)"
                  v-if="checkSf(it)"
                >
                  {{ it.title }}
                </a-menu-item>
              </template>
            </a-sub-menu>
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <div class="contHeader">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />

          {{ selectedMenu }}

          <a-dropdown>
            <a
              class="ant-dropdown-link user1"
              @click="(e) => e.preventDefault()"
            >
              <span> 当前身份:{{ user_sf }}</span>
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item
                v-for="(it, index) in switchId"
                :key="index"
                @click="switchC(it)"
                >切换身份:{{ it.auth_type_name }}</a-menu-item
              >
              <a-menu-item @click="logout"> 退出 </a-menu-item>
            </a-menu>
          </a-dropdown>

          <span class="user1"
            ><a-icon slot="icon" type="user" /> {{ username }}</span
          >
        </div>

        <a-layout-content
          :style="{
            padding: '10px',
            'margin-top': '1px',
            minHeight: '280px',
          }"
        >
          <!-- <keep-alive> -->
          <router-view
            class="page-content"
            @change_bread="change_bread($event)"
          ></router-view>
        </a-layout-content>
        <!-- <a class="bottom" href="https://beian.miit.gov.cn/"
          >备案号：粤ICP备20043488号</a
        > -->
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import mixin from "@/assets/js/public/mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      userInfo: {},
      username: "",
      user_sf: "",
      switchId: [],
      actived: "",
      collapsed: false,
      menuVal: [],
      rootSubmenuKeys: [],
      openKeys: [],
      selectedMenu: "",
    };
  },

  components: {},

  computed: {},

  created() {
    // if (!this.username) {
    //   this.$router.push("/login");
    //   return;
    // }
    this.init();
  },

  mounted() {},

  methods: {
    change_bread(d) {
      this.actived = d.path;
      this.selectedMenu = d.title;
      this.menuVal = [d.path];
      this.openKeys = [d.index];
      this.$forceUpdate()
    },
    initSf() {
      if (!sessionStorage.userInfo) {
        this.$router.push("/login");
        return;
      }
      let userInfo = JSON.parse(sessionStorage.userInfo);
      this.userInfo = userInfo;
      if (userInfo.main) {
        this.username = userInfo.main.auth_name;
        this.user_sf = userInfo.main.auth_type_name;
      }
      if (userInfo.identity.length) {
        this.switchId = userInfo.identity.filter((it) => {
          if (it.auth_type != userInfo.main.auth_type) {
            return it;
          }
        });
      }
    },
    init() {
      if (this.menu.length) {
        // this.actived = this.menu[1].children[1].path;
        // this.selectedMenu = this.menu[1].children[1].title;
        // this.menuVal = [this.menu[1].children[1].path];
        // this.openKeys = ['sub1'];

        this.actived = this.menu[0].path;
        this.selectedMenu = this.menu[0].title;
        this.menuVal = [this.menu[0].path];
        this.openKeys = [0];
        this.$router.push(this.actived);
      }
      this.initSf();
    },
    switchC(it) {
      let that = this;
      that
        .$postAction(that.$Interface.identity, {
          auth_type: it.auth_type,
          auth_id: it.auth_id,
        })
        .then((res) => {
          if (res.code == 200) {
            sessionStorage.userInfo = JSON.stringify(res.data);
            that.initSf();
            that.$message.success("操作成功");
          } else {
            that.$message.success("操作失败");
          }
        });
    },
    goUrl(it) {
      let that = this;
      this.menuVal = [it.path];
      this.selectedMenu = it.title;
      this.actived = it.path;
      this.$router.push(it.path);
    },
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    checkSf(it) {
      let can = true;
      let arr = ["客户列表", "系统公告",'日志统计'];
      if (arr.indexOf(it.title) > -1 && this.user_sf == "商户管理员") {
        can = false;
      }
      let arr2 = ["客户列表", "系统公告", "账号管理",'日志统计'];
      if (arr2.indexOf(it.title) > -1 && this.user_sf == "项目管理员") {
        can = false;
      }
      return can;
    },
  },
};
</script>

<style>
#components-layout-demo-top-side-2  .ant-layout-sider {
  background:#FFFFFF;
}

#components-layout-demo-top-side-2 .ant-layout {
z-index: 1;
}
</style>
<style scoped>
#components-layout-demo-top-side-2 {
  width: 100%;
  height: 100%;
}

.header {
  padding: 5px 10px;
  height: 50px;
}

.contHeader {
  background: #fff;
  padding: 0px 10px;
  height: 40px;
  line-height: 40px;
}

.logoImg {
  height: 40px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0 24px;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-top-side-2 .actived {
  color: #1890ff;
}

.user1 {
  float: right;
  /* color: black; */
  /* margin: 10px 0; */
  margin: 0 10px;
}

#components-layout-demo-top-side-2 .ant-breadcrumb {
  width: 90%;
  display: inline-block;
}

.logo {
  color:#1890ff;
  /* color: white; */
  font-size: 18px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  margin-left: 2px;
  /* overflow: hidden; */
  width: 200px;
}
.bottom {
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
}
</style>

<style>
@import "../assets/css/common.css";
</style>