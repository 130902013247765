<!-- 打印标签 -->
<template>
  <div>
    <vxe-modal
      style="z-index: 10"
      v-model="printVisible"
      title="打印标签"
      show-zoom
      resize
      destroy-on-close
      width="500"
      height="auto"
    >
      <div id="printContent">
        <div class="qrcode" v-for="item in pData" :key="item.id">
          <div :id="'id_' + item.id"></div>
          
          <div style="margin-left: 10px">
            <div>项目编号：{{ item.project_no }}</div>
            <div>项目名称：{{ item.project_name }}</div>
            <div>样品名称：{{ item.sample_name }}</div>
            <div>样品编号：{{ item.sample_num }}</div>
            <div>
              预约时间：{{ item.base_day }}，{{ item.begin_time }}-{{ item.end_time }}
            </div>
            <div>测试指标：{{changeS(item.inspect_tag) }}</div>
            <div>
              志愿者：{{ item.volunteer_name }}，{{
                item.volunteer_sex
              }}，{{ item.volunteer_age }}岁
            </div>
          </div>
        </div>
      </div>
      <a-button v-print="'#printContent'" type="primary">打印</a-button>
    </vxe-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      printVisible: false,
      pData: [],
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    show(data) {
      let that = this;
      that.printVisible = true;

      that.pData = data;
      console.log(data);
      this.$nextTick(() => {
        this.pData.forEach((item) => {
          var qrcode = new QRCode(document.getElementById("id_" + item.id), {
            text: item.id, // 需要转换为二维码的内容
            width: 100,
            height: 100,
          });

          //   $("#printContent").find("canvas").remove();
        });
      });
    },
    changeS(s) {
      let t = "";
      console.log(s)
      if (s.length) {
        t = s.join('、')
      }
      return t
    },
  },
};
</script>

<style scoped>
.qrcode {
  display: flex;
  /* flex-wrap: wrap; */
  margin-bottom: 20px;
}

#printContent {
  margin: auto;
}


</style>
