<!-- 打卡详情 -->
<template>
  <div style="width: 1200px" id="clock_detail">
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="打卡时间">
            <a-date-picker
              placeholder="开始时间"
              :locale="locale"
              v-model="queryParam.createBegin"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束时间"
              :locale="locale"
              v-model="queryParam.createEnd"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="状态">
            <a-select
              :options="optC"
              placeholder="请选择"
              v-model="status"
              @change="statusChange"
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="志愿者">
            <a-input
              placeholder="请输入"
              v-model="queryParam.volunteer_name"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button type="primary" @click="$refs.table.commitProxy('reload')"
              >查询</a-button
            >
            <a-button type="primary" @click="showSta" style="margin-left: 8px"
              >打卡统计</a-button
            >
            <!-- <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            > -->
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <vxe-grid
      v-if="status == 1"
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
    >
      <template #toolbar_buttons="{ record }"> </template>
      <template v-slot:img="{ row }">
        <template v-for="item in row.res_info">
          <img
            class="img1"
            :src="item.url"
            :key="item.url"
            v-if="item.mime_type != 'video/mp4' || item.mime_type == ''"
            @click="seeImg(item.url)"
          />
        </template>
      </template>

      <template v-slot:video="{ row }">
        <template v-for="item in row.res_info">
          <video
            class="img1"
            :src="item.url"
            :key="item.url"
            v-if="item.mime_type == 'video/mp4'"
            @click="seeVideo(item.url)"
          />
        </template>
      </template>

      <template v-slot:action="{ row }">
        <a @click="preview(row)">查看问卷</a>
      </template>
    </vxe-grid>

    <vxe-grid
      v-if="status == 0"
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy2"
      :columns="columns2"
      :toolbar-config2="tableToolbar"
    >
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="allM"
      title="打卡统计"
      width="1200"
      resize
      destroy-on-close
      show-zoom
    >
      <col_sta :project_id="project_id" />
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="videoVisible"
      title="查看视频"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <video width="600" height="600" controls autoplay>
        <source :src="videoUrl" />
      </video>
    </vxe-modal>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
import col_sta from "./col_sta.vue";

export default {
  props: ["project_id"],
  data() {
    let _this = this;
    return {
      locale,
      status: 0,
      optC: [
        { label: "未打卡", value: 0 },
        { label: "已打卡", value: 1 },
      ],
      queryParam: {
        createBegin: moment().format("YYYY-MM-DD"),
        createEnd: moment().format("YYYY-MM-DD"),
      },
      allM: false,
      show: false,
      videoUrl: "",
      videoVisible: false,
      previewVisible: false,
      previewImage: "",
      template: {},
      columns2: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "志愿者",
          field: "volunteer_name",
        },
        {
          title: "性别",
          field: "volunteer_sex_text",
        },
        {
          title: "年龄",
          field: "volunteer_age",
        },
        {
          title: "手机",
          field: "volunteer_phone",
        },
        {
          title: "需要打卡的时间段",
          slots: {
            default: ({ row }) => {
              let begin = moment(row.checkin_begin).format("HH:mm");
              let end = moment(row.checkin_end).format("HH:mm");
              let str = row.checkin_day + " " + begin + "-" + end;
              return str;
            },
          },
        },
      ],
      tableProxy2: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let p = _this.queryParam;

            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&project_id=" +
              _this.project_id +
              "&base_day_begin=" +
              p.createBegin +
              "&base_day_end=" +
              p.createEnd +
              " 23:59:59" +
              "&is_record=0";
            if (p.volunteer_name) {
              param = param + "&volunteer_name=" + p.volunteer_name;
            }

            let rdata = _this.$getAction(
              _this.$Interface.zyz_clock_status + param
            );
            return rdata;
          },
        },
      },
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        // {
        //   title: "操作",
        //   field: "action",
        //   width: 80,
        //   slots: {
        //     default: "action",
        //   },
        // },
        {
          title: "志愿者",
          field: "volunteer_name",
          width: 120,
        },
        {
          title: "内容",
          field: "content",
        },
        {
          title: "图片",
          slots: {
            default: "img",
          },
        },
        {
          title: "视频",
          slots: {
            default: "video",
          },
        },
        {
          title: "打卡地址",
          field: "geo_loc",
        },
        {
          title: "打卡时间",
          field: "createdAt",
          width: 150,
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let p = _this.queryParam;

            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&project_id=" +
              _this.project_id +
              "&record_time_begin=" +
              p.createBegin +
              "&record_time_end=" +
              p.createEnd +
              " 23:59:59";
            if (p.volunteer_name) {
              param = param + "&volunteer_name=" + p.volunteer_name;
            }

            console.log(_this.status);
            let rdata = _this.$getAction(
              _this.$Interface.pro_clock_record + param
            );
            return rdata;
          },
        },
      },
    };
  },

  components: { col_sta },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    showModal() {
      this.show = true;
    },
    showSta() {
      this.allM = true;
    },

    seeImg(img) {
      // this.previewVisible = true;
      // this.previewImage = img;
      this.$emit("seeImg", img);
    },

    seeVideo(url) {
      this.videoUrl = url;
      this.videoVisible = true;
    },

    statusChange() {
      this.$nextTick(() => {
        this.$refs.table.commitProxy("reload");
      });
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>

<style lang="scss">
// .vxe-table--border-line {
//   display: none;
// }
</style>


