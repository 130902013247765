<!-- 参与的项目 -->
<template>
  <div>
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
    >
    </vxe-grid>
  </div>
</template>

<script>
export default {
  props: ["vid"],
  data() {
    let that = this;
    return {
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param =
              "?pageNum=1&pageSize=200" +
              "&volunteer_id=" +
              this.vid +
              "&is_deleted=0";
            return that
              .$getAction(this.$Interface.my_project + param)
              .then(async (res) => {
                if (res.code == 200) {
                  let data = res.data.list;
                  for (let i in data) {
                    let a = data[i];
                    let param1 =
                      "?pageNum=1&pageSize=200&project_id=" +
                      a.id +
                      "&volunteer_id=" +
                      that.vid;
                    a.col_ci = 0;
                    await that
                      .$getAction(that.$Interface.pro_clock_record + param1)
                      .then((res) => {
                        if (res.code == 200) {
                          console.log("打卡", res.data.list);
                          if (res.data.list && res.data.list.length) {
                            a.col_ci = res.data.list.length;
                          }
                        }
                      });

                    a.que_ci = 0;
                    await that
                      .$getAction(that.$Interface.question_record_list + param1)
                      .then((res) => {
                        if (res.code == 200) {
                          console.log("问卷", res.data.list);
                          if (res.data.list && res.data.list.length) {
                            a.que_ci = res.data.list.length;
                          }
                        }
                      });

                    a.yue_ci = 0;
                    await that
                      .$getAction(
                        that.$Interface.yuye_record_list +
                          param1 +
                          "&is_checkin=1"
                      )
                      .then((res) => {
                        if (res.code == 200) {
                          console.log("预约", res.data.list);
                          if (res.data.list && res.data.list.length) {
                            a.yue_ci = res.data.list.length;
                          }
                        }
                      });
                  }
                //   console.log(data);
                }
                return res;
              });
          },
        },
      },
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "项目名称",
          field: "project_name",
          //   width: 120,
        },
        {
          title: "状态",
          field: "status_text",
          width: 100,
          slots: {
            default: ({ row }) => {
              let status = row.status_text;
              if (row.deleted_at) {
                status = "已删除";
              }
              return status;
            },
          },
        },
        {
          title: "项目编号",
          field: "code",
          width: 100,
        },
        {
          title: "项目类别",
          field: "project_type",
          width: 100,
        },
        {
          title: "测试部位",
          field: "tag_text",
          width: 100,
        },

        // {
        //   title: "样品名称",
        //   field: "sample_name",
        //   width: 120,
        // },
        // {
        //   title: "样品编号",
        //   field: "sample_num",
        //   width: 120,
        // },
        {
          title: "开始时间",
          field: "extInfo.clock_startTime",
          width: 100,
        },
        {
          title: "结束时间",
          field: "extInfo.clock_endTime",
          width: 100,
        },
        {
          title: "完成打卡次数",
          field: "col_ci",
          width: 120,
        },
        {
          title: "完成调查问卷次数",
          field: "que_ci",
          width: 125,
        },
        {
          title: "完成签到次数",
          field: "yue_ci",
          width: 120,
        },
        // {
        //   title: "创建时间",
        //   field: "createdAt",
        //   width: 120,
        // },
        // {
        //   title: "创建人",
        //   field: "creator_name",
        //   width: 120,
        // },
      ],
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style scoped>
</style>
