<!-- 商品列表 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <!-- <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="创建日期">
            <a-date-picker
              placeholder="开始日期"
              class="query-group-cust"
              :locale="locale"
              v-model="queryParam.time1"
              @change="timeChange1"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束日期"
              class="query-group-cust"
              :locale="locale"
              v-model="queryParam.time2"
              @change="timeChange2"
            ></a-date-picker>
          </a-form-item>
        </a-col> -->
        <a-col :xl="6" :lg="7" :md="8" :sm="24">
          <a-form-item label="商品名称">
            <a-input
              placeholder="请输入商品名称"
              v-model="queryParam.name"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :xl="6" :lg="7" :md="8" :sm="24">
          <a-form-item label="型号">
            <a-input
              placeholder="请输入型号"
              v-model="queryParam.model"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :xl="6" :lg="7" :md="8" :sm="24">
          <a-form-item label="类型">
            <a-select
              v-model="queryParam.prod_type"
              :options="typeOptions"
              mode="multiple"
              placeholder="请输入类型"
            />
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons>
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
        <!-- <vxe-button status="primary" size="mini" @click="exportExcel()"
          >导出</vxe-button
        > -->

        <!-- <a-upload
          name="file"
          :showUploadList="false"
          :multiple="false"
          :action="url.importUrl"
          @change="importEvent"
        >
          <vxe-button status="primary" size="mini" style="margin-left: 12px"
            >导入</vxe-button
          >
        </a-upload> -->
      </template>
      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row)">删除</a>
      </template>
    </vxe-grid>

    <vxe-modal  style="z-index: 10"  
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
      >
        <vxe-form-item
          title="商品名称"
          field="name"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="型号"
          field="model"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item title="类型" field="prod_type" span="24">
          <!-- <a-auto-complete
            v-model="formData.prod_type"
            :data-source="typeOptions"
          /> -->
          <a-select
            v-model="formData.prod_type"
            :options="typeOptions"
            mode="multiple"
            placeholder="请输入类型"
          />
        </vxe-form-item>

        <vxe-form-item
          title="商品介绍"
          field="desc"
          span="24"
          :item-render="{ name: 'textarea', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="兑换积分"
          field="integral"
          span="24"
          :item-render="{
            name: 'input',
            attrs: { type: 'number', placeholder: '请输入' },
          }"
        ></vxe-form-item>

        <vxe-form-item
          title="状态"
          field="status"
          span="24"
          :item-render="{
            name: '$radio',
            options: [
              { label: '有效', value: 1 },
              { label: '无效', value: 0 },
            ],
          }"
        ></vxe-form-item>

        <vxe-form-item
          title="备注"
          field="remark"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <!-- <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item> -->
      </vxe-form>

      <div style="display: flex">
        <div class="upload1">主图</div>
        <a-upload
          list-type="picture-card"
          :file-list="fileListMain"
          @preview="handlePreview"
          :remove="handleRemove1"
          :before-upload="beforeUpload1"
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </div>
      <div style="display: flex">
        <div class="upload1">详情图</div>
        <a-upload
          list-type="picture-card"
          :file-list="fileListDe"
          @preview="handlePreview"
          :remove="handleRemove2"
          :before-upload="beforeUpload2"
          multiple
        >
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </div>

      <div style="text-align: center">
        <vxe-button @click="submitEvent" status="primary">保存</vxe-button>
        <vxe-button @click="reset">重置</vxe-button>
      </div>

      <vxe-modal  style="z-index: 10"  
        v-model="previewVisible"
        title="查看图片"
        show-zoom
        resize
        destroy-on-close
      >
        <img alt="example" style="width: 100%" :src="previewImage" />
      </vxe-modal>
    </vxe-modal>
  </a-card>
</template>

<script>
import JImageUpload from "@/components/JImageUpload";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import axios from "axios";

export default {
  data() {
    let _this = this;
    return {
      previewVisible: false,
      previewImage: "",
      fileListMain: [],
      fileListDe: [],

      locale,
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: _this.$Interface.prod_list,
        save: _this.$Interface.prod_save,
        del: _this.$Interface.prod_del,
        importUrl: "",
      },
      typeOptions: [
        { label: "祛痘", value: "祛痘" },
        { label: "修护", value: "修护" },
        { label: "滋养", value: "滋养" },
        { label: "抗皱", value: "抗皱" },
        { label: "紧致", value: "紧致" },
        { label: "舒缓", value: "舒缓" },
        { label: "保湿", value: "保湿" },
        { label: "控油", value: "控油" },
        { label: "其他", value: "其他" },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "商品名称",
          field: "name",
        },
        {
          title: "型号",
          field: "model",
        },
        {
          title: "类型",
          field: "prod_type",
        },
        {
          title: "商品介绍",
          field: "desc",
        },
        {
          title: "兑换积分",
          field: "integral",
        },
        // {
        //   title: "创建时间",
        //   field: "createTime",
        // },
        {
          title: "备注",
          field: "remark",
        },
        {
          title: "状态",
          field: "status",
          slots: {
            default: ({ row }) => {
              if (row.status == 1) {
                return "有效";
              } else {
                return "无效";
              }
            },
          },
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "result.records",
          total: "result.total",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param = _this.queryParam;
            param.page_num = page.currentPage;
            param.page_size = page.pageSize;
            param.detail = 1;
            // let rdata = _this.$Request(this.url.list, "post", param);
            // return rdata;

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    id: 19,
                    name: "3M™ 9132 N95医用防护口罩",
                    integral: 200,
                    model: "9132",
                    prod_type: "其他",
                    desc: "可用于职业性医护人员的呼吸防护",
                    remark: "",
                    status: 1,
                    images: {
                      1: [
                        {
                          img_id: 119,
                          img_group: 1,
                          img_url:
                            "http://tz-lims.oss-cn-shenzhen.aliyuncs.com/tzlims/cms/uploads/af76871240ebee39c279f7759822b041.png",
                          img_thumb:
                            "http://tz-lims.oss-cn-shenzhen.aliyuncs.com/tzlims/cms/uploads/af76871240ebee39c279f7759822b041.png?x-oss-process=image/auto-orient,0/quality,Q_76/resize,h_192,w_192",
                        },
                      ],
                      2: [
                        {
                          img_id: 120,
                          img_group: 2,
                          img_url:
                            "http://tz-lims.oss-cn-shenzhen.aliyuncs.com/tzlims/cms/uploads/57ef957f500e87061cfccdd4dcff776a.jpg",
                          img_thumb:
                            "http://tz-lims.oss-cn-shenzhen.aliyuncs.com/tzlims/cms/uploads/57ef957f500e87061cfccdd4dcff776a.jpg?x-oss-process=image/auto-orient,0/quality,Q_76/resize,h_192,w_192",
                        },
                        {
                          img_id: 121,
                          img_group: 2,
                          img_url:
                            "http://tz-lims.oss-cn-shenzhen.aliyuncs.com/tzlims/cms/uploads/eb3bcafc19e227430c0ed939d5c396a8.jpg",
                          img_thumb:
                            "http://tz-lims.oss-cn-shenzhen.aliyuncs.com/tzlims/cms/uploads/eb3bcafc19e227430c0ed939d5c396a8.jpg?x-oss-process=image/auto-orient,0/quality,Q_76/resize,h_192,w_192",
                        },
                      ],
                    },
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },

      subModal: false,
      selectRow: null,
      formData: {
        name: "",
        remark: "",
        status: 1,
        url: "",
        type: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入" }],
        productModel: [{ required: true, message: "请输入" }],
        integral: [{ required: true, message: "请输入" }],
        status: [{ required: true, message: "请选择" }],
      },
    };
  },

  components: {
    JImageUpload,
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    handleRemove1(file) {
      const index = this.fileListMain.indexOf(file);
      const newfileListMain = this.fileListMain.slice();
      newfileListMain.splice(index, 1);
      this.fileListMain = newfileListMain;
    },
    handleRemove2(file) {
      const index = this.fileListDe.indexOf(file);
      const newfileListDe = this.fileListDe.slice();
      newfileListDe.splice(index, 1);
      this.fileListDe = newfileListDe;
    },
    beforeUpload1(file) {
      let that = this;
      this.$uploadAction(file).then((res) => {
        if (res.code == 200) {
          let img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.data.url,
          };
          that.fileListMain = [img];
        }
      });
      return false;
    },
    beforeUpload2(file) {
      let that = this;
      this.$uploadAction(file).then((res) => {
        if (res.code == 200) {
          let img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.data.url,
          };
          that.fileListDe.push(img);
        }
      });

      return false;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    timeChange1(date, dateString) {
      this.queryParam.createTime_begin = dateString;
    },
    timeChange2(date, dateString) {
      this.queryParam.createTime_end = dateString;
    },
    insertEvent() {
      this.subModal = true;
      this.formData = { status: 1 };
      this.fileListMain = [];
      this.fileListDe = [];
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }

      let ids = Array.from(new Set(this.selectionRows.map((it) => it.id)));
      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that
            .$RequestStr(that.$Interface.prod_del, "post", {
              prodId: ids.join(","),
            })
            .then((res) => {
              if (res.code == 200) {
                that.$message.success("删除成功");
                that.loadData();
              } else {
                that.$message.error("删除失败");
              }
            });
        },
        onCancel() {},
      });
    },
    reset() {
      this.formData = { status: 1 };
      this.fileListMain = [];
      this.fileListDe = [];
    },
    submitEvent() {
      let that = this;
      let param = this.formData;
      param.images = {
        1: this.fileListMain,
        2: this.fileListDe,
      };
      console.log(param);
      that.$Request(this.url.save, "post", param).then((res) => {
        if (res.code == 200) {
          that.subModal = false;
          that.$message.success("保存成功");
          that.loadData();
        } else {
          that.$message.error("保存失败");
        }
      });
    },
    editEvent(row) {
      let that = this;
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      that.fileListDe = [];
      that.fileListMain = [];
      let images = row.images;
      let uid1 = -1;
      if (images && images["1"]) {
        let img1 = images["1"];
        for (let i in img1) {
          let d = img1[i];
          let a = {
            uid: uid1,
            name: d.img_id + ".jpg",
            status: "done",
            url: d.img_url,

            img_id: d.img_id,
            img_group: d.img_group,
            img_url: d.img_url,
            img_thumb: d.img_thumb,
          };
          that.fileListMain.push(a);
          uid1--;
        }
      }
      if (images && images["2"]) {
        let img2 = images["2"];
        for (let i in img2) {
          let d = img2[i];
          let a = {
            uid: uid1,
            name: d.img_id + ".jpg",
            status: "done",
            url: d.img_url,

            img_id: d.img_id,
            img_group: d.img_group,
            img_url: d.img_url,
            img_thumb: d.img_thumb,
          };
          that.fileListDe.push(a);
          uid1--;
        }
      }
    },
    importEvent() {},

    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },

    removeEvent(row) {
      let that = this;
      that
        .$RequestStr(this.$Interface.prod_del, "post", { prodId: row.id })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("删除成功");
            that.loadData();
          } else {
            that.$message.error("删除失败");
          }
        });
    },

    exportExcel() {
      /* 从表生成工作簿对象 */
      var wb = XLSX.utils.table_to_book(
        this.$refs.table.$el.querySelector(".vxe-table--render-wrapper")
      );

      /* 获取二进制字符串作为输出 */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], {
            type: "application/octet-stream",
          }),
          //设置导出文件名称
          "商品列表.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
    },
  },
};
</script>


<style scoped>
.upload1 {
  width: 100px;
  text-align: right;
  line-height: 100px;
  margin-right: 0.8em;
}
</style>

