<!-- 查询列表 -->
<template>
  <div>
    <vxe-grid
      ref="table"
      size="small"
      border
      resizable
      export-config
      import-config
      row-id="id"
      align="left"
      show-overflow
      highlight-hover-row
      :seq-config="{ startIndex: (tablePage.currentPage - 1) * tablePage.pageSize }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config2="tableProxy"
      :columns="columns"
      :data="tableData"
    >
      <template v-slot:action="{ row }">
        <span>
          <a @click="see(row.type)">查看</a>
        </span>
      </template>
    </vxe-grid>

    <vxe-modal v-model="seeModel" title="查看报表" width="80%" height="90%" resize destroy-on-close>
      <clock_list  v-if="tableType == 1" />
      <questionnaire_list v-if="tableType == 2" />
    </vxe-modal>
  </div>
</template>

<script>
import moment from 'moment'
import clock_list from '@/views/project/clock_list'
import questionnaire_list from '@/views/project/questionnaire_list'


export default {
  data() {
    let _this = this
    return {
      tableType: '',
      seeModel: false,
      tableData: [
        {
          name: '打卡查询',
          type: 1,
        },
        {
          name: '调查问卷查询',
          type: 2,
        },
      ],
      // 表头
      columns: [
        {
          title: '操作',
          field: 'action',
          align: 'center',
          slots: {
            default: 'action',
          },
          width: 100,
        },
        {
          title: '报表名称',
          align: 'center',
          field: 'name',
          showHeaderOverflow: true,
        },
      ],
      url: {
        list: '/sample/lmInspectSampleSubLog/list/',
      },
      tablePage: {
        pageSize: 10,
        align: 'right',
        total:2,
      },
      tableToolbar: {
        id: 'tools',
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: 'result.records',
          total: 'result.total',
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            _this.isorter.column = sort.property
            _this.isorter.order = sort.order
            _this.queryParam.inspectNum = _this.inspectNum
            return getAction(
              '/log/lmInspectLog/list',
              Object.assign({ pageNo: page.currentPage, pageSize: page.pageSize }, _this.getQueryParams())
            )
          }, // 模拟请求
        },
      },
    }
  },

  components: {
    clock_list,questionnaire_list
    },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    see(type) {
      let that = this
      that.tableType = type;
      that.seeModel = true
    },
  },
}
</script>

<style scoped>
</style>
