<!--志愿者列表 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="所属客户">
            <a-input
              placeholder="请输入所属客户"
              v-model="queryParam.customer"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="姓名">
            <a-input
              placeholder="请输入姓名"
              v-model="queryParam.name"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="手机">
            <a-input
              placeholder="请输入手机"
              v-model="queryParam.phone"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="状态">
            <a-select
              :options="status_opt"
              placeholder="请选择"
              v-model="status"
              allowClear
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      show-header-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piSet('J')"
          >批通过</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piSet('R')"
          >批拒绝</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piSet('B')"
          >批禁用</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piDel()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="showD(row)">详情</a>
        <a @click="editEvent(row)" style="margin-left: 8px">编辑</a>
        <!-- <a style="margin-left: 8px" @click="removeEvent(row)">删除</a> -->
        <a-popconfirm
          title="确认删除?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="removeEvent(row)"
        >
          <a style="margin-left: 8px" href="#">删除</a>
        </a-popconfirm>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      height="600"
      resize
      destroy-on-close
      show-zoom
    >
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="formRules"
        class="ant-advanced-search-form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-row :gutter="24">
          <a-col span="24">
            <a-form-model-item label="姓名" prop="name">
              <a-input placeholder="请输入" v-model="formData.name"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="来源" prop="source">
              <a-input placeholder="请输入" v-model="formData.source"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="性别" prop="sex">
              <a-select
                :options="sexOptions"
                placeholder="请输入"
                v-model="formData.sex"
              ></a-select>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="身份证" prop="id_card">
              <a-input
                placeholder="请输入"
                v-model="formData.id_card"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="年龄" prop="age">
              <a-input
                type="number"
                min="1"
                placeholder="请输入"
                v-model="formData.age"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24" v-if="!selectRow">
            <a-form-model-item label="手机" prop="phone">
              <a-input
                type="number"
                placeholder="请输入"
                v-model="formData.phone"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="地址" prop="address">
              <a-input
                placeholder="请输入"
                v-model="formData.address"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="标签" prop="type">
              <a-select
                :options="tagOpts"
                placeholder="请选择"
                v-model="formData.extInfo.tag"
                allowClear
              ></a-select>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="礼金发放方式" prop="award_method">
              <a-select
                :options="awardOptions"
                placeholder="请选择"
                v-model="formData.extInfo.award_method"
                allowClear
              ></a-select>
            </a-form-model-item>
          </a-col>
          <a-col span="24" v-if="formData.extInfo.award_method == '银行卡'">
            <a-form-model-item label="银行" prop="award_brank">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.award_brank"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="收款账号" prop="award_account">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.award_account"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="推荐人" prop="boss">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.boss"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="24">
            <a-form-model-item label="备注" prop="remark">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.remark"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :style="{ textAlign: 'center' }">
            <a-button type="primary" @click="submitEvent"> 保存 </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              清空
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="detailModal"
      title="项目详情"
      width="1200"
      height="80%"
      resize
      destroy-on-close
      show-zoom
    >
      <!-- <a-tabs type="card" destroyInactiveTabPane>
        <a-tab-pane key="0" tab="参与项目">
          <div class="tabContent"> -->
      <project :vid="selRow.id" />
      <!-- </div>
        </a-tab-pane>
      </a-tabs> -->
    </vxe-modal>
  </a-card>
</template>

<script>
import project from "./modal/project.vue";
export default {
  data() {
    let that = this;
    return {
      status: "J",
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
      },
      fileListMain: [],
      tagOptions: [],
      sexOptions: [
        { label: "男", value: "M" },
        { label: "女", value: "F" },
      ],
      tagOpts: [],
      awardOptions: [
        { label: "微信", value: "微信" },
        { label: "支付宝", value: "支付宝" },
        { label: "银行卡", value: "银行卡" },
      ],
      status_opt: [
        { label: "待审核", value: "P" },
        { label: "已通过", value: "J" },
        { label: "已拒绝", value: "R" },
        { label: "已禁用", value: "B" },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          width: "120",
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "姓名",
          field: "name",
        },
        {
          title: "来源",
          field: "source",
        },
        {
          title: "性别",
          field: "sex_text",
          // slots: {
          //   default: ({ row }) => {
          //     if (row.sex == 1) {
          //       return "男";
          //     }
          //     if (row.sex == 2) {
          //       return "女";
          //     }
          //   },
          // },
        },
        {
          title: "身份证",
          field: "id_card",
        },
        {
          title: "年龄",
          field: "age",
        },
        {
          title: "手机",
          field: "phone",
        },
        {
          title: "地址",
          field: "address",
        },
        {
          title: "创建时间",
          field: "createdAt",
        },
        {
          title: "状态",
          slots: {
            default: ({ row }) => {
              if (row.status == "P") {
                return "待审核";
              }
              if (row.status == "J") {
                return "已通过 ";
              }
              if (row.status == "R") {
                return "已拒绝";
              }
              if (row.status == "B") {
                return "已禁用 ";
              }
              if (row.status == "E") {
                return "已退出 ";
              }
            },
          },
        },
        // {
        //   title: "打卡次数",
        //   field: "extInfo.clock_times",
        // },
        {
          title: "标签",
          field: "extInfo.tag",
        },
        {
          title: "积分",
          field: "extInfo.integral",
        },
        {
          title: "礼金发放方式",
          field: "extInfo.award_method",
        },
        {
          title: "银行",
          field: "extInfo.award_brank",
        },
        {
          title: "收款账号",
          field: "extInfo.award_account",
        },

        {
          title: "推荐人",
          field: "extInfo.boss",
        },
        {
          title: "备注",
          field: "extInfo.remark",
        },
        {
          title: "皮肤问题",
          slots: {
            default: ({ row }) => {
              let str = "";
              if (row.extInfo && row.extInfo.skin && row.extInfo.skin.length) {
                str = row.extInfo.skin.join(",");
              }
              return str;
            },
          },
        },
        {
          title: "自然肤色",
          slots: {
            default: ({ row }) => {
              let str = "";
              if (
                row.extInfo &&
                row.extInfo.color &&
                row.extInfo.color.length
              ) {
                str = row.extInfo.color.join(",");
              }
              return str;
            },
          },
        },
        {
          title: "日晒后皮肤",
          slots: {
            default: ({ row }) => {
              let str = "";
              if (row.extInfo && row.extInfo.ri && row.extInfo.ri.length) {
                str = row.extInfo.ri.join(",");
              }
              return str;
            },
          },
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            // console.log(this.queryParam);
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            let url = that.$Interface.customer_zyz_list;
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
              url = that.$Interface.zyz_list;
            }
            let q = that.queryParam;
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&status=" +
              that.status +
              "&name=" +
              q.name +
              "&phone=" +
              q.phone +
              "&customer_id=" +
              customer_id;
            // return that.$getAction(that.$Interface.zyz_list + param);
            return that.$getAction(url + param);

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    name: "Carson",
                    source: "微信",
                    type: "消费者使用评价",
                    sex: "男",
                    id_card: "4406811",
                    age: "25",
                    phone: 13392211555,
                    address: "广东省广州市白云区启德路1号",
                    contacts: "陈先生",
                    award_method: "银行卡",
                    award_brank: "中国农业银行",
                    award_account: "44061879",
                    integral: 20,
                    clock_times: 1,
                    remark: "",
                    createTime: "2022-3-9",
                    project: "测试项目一",
                    status: "已通过",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        extInfo: {},
        type: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入" }],
        source: [{ required: true, message: "请输入" }],
        // type: [{ required: true, message: "请选择" }],
        sex: [{ required: true, message: "请选择" }],
        id_card: [{ required: true, message: "请输入" }],
        age: [{ required: true, message: "请输入" }],
        phone: [{ required: true, message: "请输入" }],
        address: [{ required: true, message: "请输入" }],
        // award_method: [{ required: true, message: "请选择" }],
        // award_brank: [{ required: true, message: "请输入" }],
        // award_account: [{ required: true, message: "请输入" }],
        // status: [{ required: true, message: "请选择" }],
      },
      selRow: {},
      detailModal: false,
      sf: "",
    };
  },

  components: { project },

  computed: {},

  created() {
    this.init();
  },

  mounted() {},

  methods: {
    init() {
      let that = this;
      that.tagOpts = [];
      let customer_id = "";
      customer_id = JSON.parse(sessionStorage.userInfo).user_id;
      if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
        customer_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
      }
      that.sf = sessionStorage.sf;
      if (sessionStorage.sf == "管理员") {
        customer_id = "";
      }
      that
        .$getAction(
          that.$Interface.zyz_tag_list + "?customer_id=" + customer_id
        )
        .then((res) => {
          if (res.code == 200) {
            if (res.data.list) {
              that.tagOpts = res.data.list.map((it) => {
                let a = {
                  value: it.name,
                  label: it.name,
                };
                return a;
              });
            }
          }
        });
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    showD(row) {
      this.selRow = row;
      this.detailModal = true;
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
      this.formData = {
        extInfo: {},
      };
    },
    piDel() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      let id = that.selectedRowKeys.join(",");
      let param = {
        force: 1,
        id: id,
      };
      that.$postAction(that.$Interface.zyz_delete, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    removeEvent(row) {
      let that = this;
      let param = {
        force: 1,
        id: row.id,
      };
      that.$postAction(that.$Interface.zyz_delete, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error(res.message);
        }
      });
    },
    allrowEvent(id) {},
    submitEvent() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.formData));
          let url = that.$Interface.zyz_apply;
          if (that.selectRow) {
            url = that.$Interface.zyz_info_edit;
          }
          that.$postAction(url, param).then((res) => {
            if (res.code == 200) {
              that.$message.success("保存成功");
              that.loadData();
              that.subModal = false;
            } else {
              that.$message.error("保存失败");
            }
          });
        } else {
          return false;
        }
      });
    },
    handleReset() {
      this.formData = {
        extInfo: {},
      };
    },
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      if (!row.extInfo) {
        this.formData.extInfo = {};
      }
    },

    piSet(status) {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      let id = that.selectedRowKeys.join(",");
      that.setStatus(id, status);
    },
    setStatus(id, status) {
      // P 待审核 J 已加入R 被驳回B 已拉黑E 已退出
      let that = this;
      let param = {
        id: id,
        status: status,
      };
      that.$postAction(that.$Interface.admin_audit_zyz, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.tabContent {
  border: 1px solid #e8e8e8;
  border-top: 0;
  padding: 10px;
  margin-top: -16px;
}
</style>
