<!--  -->
<template>
  <div style="width: 100%; height: 100%" class="divBg">
    <!-- <div style="padding: 10px">
      <div style="margin: auto; width: 70%">
        <img src="../assets/img/logo.jpg" style="width: 100px; height: 100px" />
        <span
          style="
            vertical-align: middle;
            background: #d8d8d8;
            height: 48px;
            width: 1px;
            display: inline-block;
            margin: 0 30px;
          "
        ></span>
        <span style="font-size: 40px; color: #2c3e50; vertical-align: middle"
          >欢迎登录</span
        >
      </div>
    </div> -->

    <!-- <div class="bg">
      <div class="bgImg"></div>
    </div> -->

    <!-- <div id="form">
      <div class="title">账号登录</div>

    </div> -->

    <a-modal
      id="login"
      :visible="true"
      :closable="false"
      :mask="false"
      :footer="null"
      centered
      width="400px"
      title=""
      class="myModal"
      @keyup.enter.native="login"
    >
      <a-spin :spinning="spinning" style="padding: 10px">
        <div class="ant-modal-title">天纵睿知化妆品评价中心</div>

        <a-form-model
          ref="loginForm"
          :model="loginForm"
          :rules="rules"
          style="margin-top: 30px"
        >
          <a-form-model-item prop="username">
            <a-input v-model="loginForm.username" placeholder="账号">
              <a-icon
                slot="prefix"
                type="user"
                style="color: #1890ff; font-weight: bold;"
              />
            </a-input>
          </a-form-model-item>

          <a-form-model-item prop="password">
            <a-input
              v-model="loginForm.password"
              type="password"
              placeholder="密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: #1890ff; font-weight: bold;"
              />
            </a-input>
          </a-form-model-item>

          <a-form-model-item>
            <a-button
              type="primary"
              @click="login"
              shape="round"
              style="width: 200px; margin-left: 80px; margin-top: 30px;font-weight: bold;"
            >
              立即登录
            </a-button>
            <!-- <a-button
              style="width: 100px; margin-left: 20px"
              @click="resetForm"
              shape="round"
            >
              清空
            </a-button> -->
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>

    <div class="foot">
      Copyright © 2022 天纵睿知(广东)科技有限公司
      <a class="bottom" href="https://beian.miit.gov.cn/"
        >备案号：粤ICP备20043488号</a
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinning: false,
      loginForm: {
        username: "",
        password: "",
      },
      rules: {
        username: {
          required: true,
          message: "请输入账号",
          trigger: "blur",
        },
        password: {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
      },
    };
  },

  components: {},

  computed: {},

  created() {
    let that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      if (e.code == "Enter" || e.code == "enter") {
        //验证在登录界面和按得键是回车键enter
        that.login(); //登录函数
      }
    };
  },

  mounted() {},

  methods: {
    resetForm() {
      this.loginForm = {};
    },
    login(e) {
      let that = this;
      sessionStorage.userInfo = "";
      sessionStorage.sf = "";
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // console.log(this.loginForm);
          that.spinning = true;

          setTimeout(() => {
            that.spinning = false;
            // if (
            //   this.loginForm.username == "admin" &&
            //   this.loginForm.password == "123456"
            // ) {
            that
              .$postAction(that.$Interface.login, {
                user: that.loginForm.username,
              })
              .then((res) => {
                if (res.code == 200) {
                  let userInfo = res.data;
                  let main = res.data.main;
                  sessionStorage.sf = main.auth_type_name;
                  sessionStorage.userInfo = JSON.stringify(res.data);

                  // console.log(main);

                  let identity = res.data.identity;
                  if (main.auth_type_name == "志愿者") {
                    let index = that._.findIndex(identity, {
                      auth_type_name: "项目管理员",
                    });
                    let index2 = that._.findIndex(identity, {
                      auth_type_name: "管理员",
                    });
                    if (index > -1) {
                      main = identity[index];
                      sessionStorage.sf = main.auth_type_name;
                      userInfo.main = main;
                      sessionStorage.userInfo = JSON.stringify(userInfo);
                    } else if (index2 > -1) {
                      main = identity[index2];
                      sessionStorage.sf = main.auth_type_name;
                      userInfo.main = main;
                      sessionStorage.userInfo = JSON.stringify(userInfo);
                    } else {
                      this.$message.error("不是管理员账号，无法登陆后台系统");
                      return;
                    }
                  }
                  // console.log(sessionStorage.sf);
                  // this.$router.push("/home");
                  // return;

                  that
                    .$postAction(that.$Interface.identity, {
                      auth_type: main.auth_type,
                      auth_id: main.auth_id,
                    })
                    .then((res) => {
                      if (res.code == 200) {
                        this.$router.push("/home");
                      } else {
                        this.$message.error("账号或密码错误，请重新输入");
                      }
                    });
                } else {
                  this.$message.error("账号或密码错误，请重新输入");
                }
              });

            // } else {
            //   this.$message.error("账号或密码错误，请重新输入");
            // }
          }, 1000);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
#login .ant-modal {
  position: absolute;
  right: 50px;
  bottom: 0;
  top: 0;
  margin: auto;
  height: 360px;
}

#login .ant-modal-content {
  background: transparent;
  border-radius: 15px;
  border: 1px solid #a3a3a3;
  box-shadow: 0px 0px 4px 4px #676767;
}

#login .ant-modal-header {
  border: none;
  background: transparent;
}

.myModal .ant-modal-title {
  font-size: 24px;
  font-weight: 600;
  color: #1890ff;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#login .ant-input {
  border: none;
  border-bottom: #1890ff solid 1px;
  color: #1890ff;
  background: transparent;
  font-weight: bold;
}

#login .ant-input::-webkit-input-placeholder {
  color: #1890ff;
}
</style>

<style scoped>
.divBg {
  background: url("../assets/img/bg.jpg") no-repeat;
  background-size: 100% 100%;
  /* background-size:contain; */
  width: 100%;
  height: 100%;
}

.bg {
  background-color: #eef6ff;
  height: 70%;
  width: 100%;
}

.bgImg {
  width: 1300px;
  height: 100%;
  /* background: url("../assets/img/bg-login.png") no-repeat; */
  background: url("../assets/img/background.svg") no-repeat;
  background-size: 100% 100%;
  margin: auto;
}

.foot {
  position: absolute;
  bottom: 20px;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 10000;
  color: white;
}

.bottom {
  /* color: rgba(0, 0, 0, 0.65); */
  color: white;
}
</style>
