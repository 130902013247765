<!--样品列表 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="编号">
            <a-input placeholder="请输入" v-model="queryParam.code"></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="标签名称">
            <a-input placeholder="请输入" v-model="queryParam.type1"></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      show-header-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row.id)">删除</a>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="1000"
      height="90%"
      resize
      destroy-on-close
      show-zoom
    >
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="formRules"
        class="ant-advanced-search-form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-row :gutter="24">
          <a-col span="24">
            <div class="title" style="width: 100%">样品信息</div>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品编号" prop="product_no">
              <a-input
                placeholder="请输入"
                v-model="formData.product_no"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品名称" prop="name">
              <a-input placeholder="请输入" v-model="formData.name"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品英文名称">
              <a-input
                placeholder="请输入"
                v-model="formData.name_en"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品类别">
              <a-input
                placeholder="请输入"
                v-model="formData.type_name"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="规格型号">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleModal"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品性状">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleCharacter"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="颜色物态">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleColorState"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="数量单位">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleUnit"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="生产日期或批号">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleBatchNumber"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="保质期或限用日期">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
                v-model="formData.extInfo.sampleLimitDate"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="保存条件">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleSaveCondition"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="样品备注">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.sampleRemark"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title" style="width: 100%">送检信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检单位名称">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionName"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检单位联系人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionContact"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检单位电话">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionPhone"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.inspectionPerson"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="送检日期">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
                v-model="formData.extInfo.inspectionDate"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="受理人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.acceptPerson"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="受理日期">
              <a-date-picker
                placeholder="请选择"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                :locale="locale"
                v-model="formData.extInfo.acceptDate"
              ></a-date-picker>
            </a-form-model-item>
          </a-col>

          <a-col span="16">
            <a-form-model-item
              label="送检单位地址"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                placeholder="请输入"
                v-model="formData.extInfo.inspectionAddress"
              ></a-textarea>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title" style="width: 100%">生产单位信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产国">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productContry"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产企业名称">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productName"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产企业联系人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productContact"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="生产企业电话">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.productPhone"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="16">
            <a-form-model-item
              label="生产企业地址"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                placeholder="请输入"
                v-model="formData.extInfo.productAddress"
              ></a-textarea>
            </a-form-model-item>
          </a-col>

          <a-divider />

          <a-col span="24">
            <div class="title" style="width: 100%">境内单位信息</div>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="境内单位名称">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.domesticName"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="境内单位联系人">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.domesticContact"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="8">
            <a-form-model-item label="境内单位电话">
              <a-input
                placeholder="请输入"
                v-model="formData.extInfo.domesticPhone"
              ></a-input>
            </a-form-model-item>
          </a-col>

          <a-col span="16">
            <a-form-model-item
              label="境内单位地址"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 20 }"
            >
              <a-textarea
                placeholder="请输入"
                v-model="formData.extInfo.domesticAddress"
              ></a-textarea>
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="submitEvent"> 保存 </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              清空
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </vxe-modal>
  </a-card>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  data() {
    let that = this;
    return {
      locale,
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      saveCondition_opt: [
        { label: "常温避光", value: "常温避光" },
        { label: "冷藏避光", value: "冷藏避光" },
        { label: "冷冻避光", value: "冷冻避光" },
      ],
      url: {
        list: "",
      },
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "样品编号",
          field: "product_no",
        },
        {
          title: "样品名称",
          field: "name",
        },
        // {
        //   title: "样品英文名称",
        //   field: "name_en",
        // },
        {
          title: "样品类别",
          field: "type_name",
        },
        {
          title: "样品备注",
          field: "extInfo.sampleRemark",
        },
        {
          title: "送检单位",
          field: "extInfo.inspectionName",
        },
        {
          title: "送检单位联系人",
          field: "extInfo.inspectionContact",
        },
        {
          title: "送检单位电话",
          field: "extInfo.inspectionPhone",
        },
        {
          title: "送检人",
          field: "extInfo.inspectionPerson",
        },
        {
          title: "送检日期",
          field: "extInfo.inspectionDate",
        },
        {
          title: "受理人",
          field: "extInfo.acceptPerson",
        },
        {
          title: "受理日期",
          field: "extInfo.acceptDate",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let customer_id = "";
            let c_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
            if (c_id) {
              customer_id = c_id;
            }
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&customer_id=" +
              customer_id;
            return that.$getAction(that.$Interface.sample_list + param);

            let data = {
              data: {
                list: [
                  {
                    sampleNum: "sm001",
                    sampleName: "测试样品",
                    sampleEnname: "",
                    sampleType: "国抽",
                    sampleModal: "1*1瓶",
                    sampleCharacter: "固态",
                    sampleColorState: "白色",
                    sampleUnit: "瓶",
                    sampleBatchNumber: "20220328",
                    sampleLimitDate: "2022-12-12",
                    sampleSaveCondition: "常温避光",
                    sampleRemark: "",
                    inspectionName: "广东生物研究院",
                    inspectionAddress: "广东生物研究院",
                    inspectionContact: "李先生",
                    inspectionPhone: "27782201",
                    inspectionPerson: "陈先生",
                    inspectionDate: "2022-03-12",
                    acceptPerson: "杨小姐",
                    acceptDate: "2022-03-12",
                    productContry: "中国",
                    productName: "广东美妆公司",
                    productAddress: "广东省佛山市顺德区",
                    productContact: "陈先生",
                    productPhone: "27784567",
                    domesticName: "广东美妆公司",
                    domesticAddress: "广东省佛山市顺德区",
                    domesticContact: "陈先生",
                    domesticPhone: "27784567",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        extInfo: {},
      },
      formRules: {
        product_no: [{ required: true, message: "请输入" }],
        name: [{ required: true, message: "请输入" }],
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
      this.formData = {
        extInfo: {},
      };
    },
    handleReset() {
      this.formData = {
        extInfo: {},
      };
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {
      let that = this;
      that
        .$postAction(that.$Interface.sample_del, { id: ids.toString() })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("删除成功");
            that.loadData();
          } else {
            that.$message.error("删除失败");
          }
        });
    },
    submitEvent() {
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.formData));
          if (!param.customer_id) {
            let customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            let c_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
            if (c_id) {
              customer_id = c_id;
            }
            param.customer_id = customer_id;
          }

          that.$postAction(that.$Interface.sample_edit, param).then((res) => {
            if (res.code == 200) {
              that.$message.success("保存成功");
              that.loadData();
              that.subModal = false;
            } else {
              that.$message.error("保存失败");
            }
          });
        } else {
          return false;
        }
      });
    },
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      if (!this.formData.extInfo) {
        this.formData.extInfo = {};
      }
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
}
</style>
