<!--系统公告 -->
<template>
  <div>
    <!-- <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="创建时间">
            <a-date-picker
              placeholder="开始日期"
              :locale="locale"
              v-model="queryParam.startTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束日期"
              :locale="locale"
              v-model="queryParam.endTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div> -->
    <!-- <a-date-picker
              placeholder="请选择"
              format="YYYY-MM-DD HH:mm"
              valueFormat="YYYY-MM-DD HH:mm"
              :locale="locale"
              v-model="formData.time"
              :show-time="{ format: 'HH:mm' }"
            ></a-date-picker> -->
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:imgArr="{ row }">
        <div>
          <img
            class="img1"
            :src="item"
            v-for="item in row.img_urls"
            :key="item"
            @click="seeImg(item)"
          />
        </div>
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row)">删除</a>
      </template>
    </vxe-grid>

    <a-modal
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      :width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
      :footer="null"
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="标题"
          field="title"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="内容"
          field="notice"
          span="24"
          :item-render="{
            name: '$textarea',
            props: { autosize: { minRows: 3 } },
            attrs: { placeholder: '请输入' },
          }"
        ></vxe-form-item>

        <vxe-form-item title="图片" field="imgArr" span="24">
          <template v-slot>
            <a-upload
              list-type="picture-card"
              :file-list="fileListDe"
              @preview="handlePreview"
              :remove="handleRemove2"
              :before-upload="beforeUpload2"
              multiple
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
          </template>
        </vxe-form-item>

        <!-- <vxe-form-item title="发布时间" span="24" field="time">
          <template #default>
            <a-date-picker
              placeholder="请选择"
              format="YYYY-MM-DD HH:mm"
              valueFormat="YYYY-MM-DD HH:mm"
              :locale="locale"
              v-model="formData.time"
              :show-time="{ format: 'HH:mm' }"
            ></a-date-picker>
          </template>
        </vxe-form-item> -->

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </a-modal>

    <vxe-modal  style="z-index: 10"  
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  props:['project_id'],
  data() {
    let that = this
    return {
      locale,
      queryParam: {},
      previewVisible: false,
      previewImage: "",
      fileListDe: [],
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
      },
      typeOptions: [{ label: "消费者使用测试", value: "消费者使用测试" }],

      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "标题",
          field: "title",
        },
        {
          title: "内容",
          field: "notice",
        },
        {
          title: "图片",
          slots: {
            default: "imgArr",
          },
        },
        {
          title: "创建人",
          field: "created_by",
        },
        {
          title: "创建时间",
          field: "created_at",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            // console.log(this.queryParam);
            // return _this.$Request(this.$Interface.warn_info);
             let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize + '&project_id='+that.project_id;
            return that.$getAction(that.$Interface.pro_notice_list + param);
            let data = {
              result: {
                records: [
                  // {
                  //   title: "标题一",
                  //   content: "aaaaaaaaaaaaaaaaa",
                  //   imgArr: ["https://cdn.uviewui.com/uview/album/7.jpg"],
                  //   create_time: "202-03-11 13:07",
                  //   create_by: "admin",
                  // },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        time: null,
      },
      formRules: {
        title: [{ required: true, message: "请输入" }],
        notice: [{ required: true, message: "请输入" }],
        imeArr: [{ required: true, message: "请上传" }],
        time: [{ required: true, message: "请选择" }],
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {},
    submitEvent() {
      let that = this;
      let param = this.formData;
      param.project_id = that.project_id;
      console.log(that.fileListDe);
      let img = that.fileListDe.map(it => it.id)
      param.img_ids = img
      that
        .$postAction(that.$Interface.pro_notice_add, that.formData)
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("操作成功");
            that.loadData();
            that.subModal = false
          } else {
            that.$message.error("操作失败");
          }
        });
    },
    editEvent(row) {
      let that = this;
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      that.fileListDe = [];
      // let img
      let img = row.img_urls;
      let img_ids = row.img_ids;
      for (let i in img) {
        let d = img[i];
        let a = {
          uid: i,
          name: i + ".jpg",
          status: "done",
          url: d,
        };
        if(img_ids[i]){
          a.id = img_ids[i]
        }
        that.fileListDe.push(a);
      }
    },

    seeImg(img) {
      // this.previewVisible = true;
      // this.previewImage = img;
      this.$emit("seeImg", img);
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleRemove2(file) {
      const index = this.fileListDe.indexOf(file);
      const newfileListDe = this.fileListDe.slice();
      newfileListDe.splice(index, 1);
      this.fileListDe = newfileListDe;
    },
    beforeUpload2(file) {
      let that = this;
      this.$uploadAction(file).then((res) => {
        if (res.code == 200) {
          let img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.data.url,
            id:res.data.id
          };
          that.fileListDe.push(img);
        }
      });
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>
