<!-- 日历 -->
<template>
  <div class="box">
    <div class="title" style="text-align: center">
      {{ title }}
      <div class="leftC" @click="leftC">＜</div>
      <div class="rightC" @click="rightC">＞</div>
    </div>
    <div class="aline">
      <div class="zi d1">一</div>
      <div class="zi d1">二</div>
      <div class="zi d1">三</div>
      <div class="zi d1">四</div>
      <div class="zi d1">五</div>
      <div class="zi d1">六</div>
      <div class="zi d1">日</div>
    </div>
    <div class="aline">
      <!-- active: checkSelect(dayobject.date), -->
      <div
        v-for="(dayobject, i) in days"
        :key="i"
        class="zi"
        @click="getClickDay(dayobject, i)"
        :class="{
          'other-month1': dayobject.cMonth != currentMonth,
          active: dayobject.rest,
           noAllow: new Date(dayobject.date + ' 23:59:59').getTime() <= new Date().getTime(),
        }"
      >
      <!-- noAllow: new Date(dayobject.date).getTime() <= new Date().getTime(), -->
       
        <div>
          <div style="font-weight: bold">{{ dayobject.cDay }}</div>
           <!-- v-if="new Date(dayobject.date).getTime() > new Date().getTime()" -->
          <template>
            <div
              v-for="(it, it_index) in dayobject.items"
              :key="it_index + 'it'"
              class="proName"
            >
              <a-popover :title="it.project_name" placement="right">
                <template #content>
                  <p v-for="(t, t_index) in it.items" :key="t_index + 't'">
                    {{ t.begin_time }}-{{ t.end_time }}，人数上限：{{
                      t.max_num
                    }}
                  </p>
                </template>
                <a-badge :color="dayobject.rest ? 'white' : '#108ee9'" />
                {{ it.project_name }}
              </a-popover>
            </div>
          </template>
        </div>
        <!-- 优先展示节日，其次，如果农历初一，展示当前农历月份，否则展示农历日期 -->
        <!-- <div
          v-if="showlunar"
          class="idaycn"
          :class="{
            festival:
              (dayobject.festival || dayobject.lunarFestival) &&
              dayobject.cMonth == currentMonth,
          }"
        >
          {{
            dayobject.festival
              ? dayobject.festival
              : dayobject.lunarFestival
              ? dayobject.lunarFestival
              : dayobject.IDayCn
          }}
        </div> -->
        <!-- <div class="xiu" v-if="dayobject.rest && dayobject.cMonth == currentMonth">休</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import calendar from "../calendar/date.js";
import moment from "moment";
export default {
  props: ["month", "selectFestival", "restDate", "festivalObj"],
  data() {
    let that = this;
    return {
      days: [],
      showlunar: true,
      title: new Date().getFullYear() + "年" + that.cMonth + "月",
      selectDate: [],
      dayC: 0,
      dateArr: [],
      proData: [],
    };
  },

  watch: {
    selectFestival(selectFestival) {
      for (let i in this.days) {
        let a = this.days[i];
        let festival = a.festival;
        let lunarFestival = a.lunarFestival;
        a.rest = false;
        if (festival && selectFestival.indexOf(festival) > -1) {
          a.rest = true;
          if (
            this.restDate.indexOf(a.date) == -1 &&
            a.cMonth == this.currentMonth
          ) {
            this.restDate.push(a.date);
          }
        }
        if (lunarFestival && selectFestival.indexOf(lunarFestival) > -1) {
          a.rest = true;
          if (
            this.restDate.indexOf(a.date) == -1 &&
            a.cMonth == this.currentMonth
          ) {
            this.restDate.push(a.date);
          }
        }
        //  if (this.restDate.indexOf(a.date ) > -1 && a.cMonth == this.currentMonth) {
        //   a.rest = true
        // }
      }
    },
    restDate(restDate) {
      // if (restDate.length) {
      //   let month = new Date(restDate[0]).getMonth() + 1;
      //   this.cMonth = month;
      //   this.initData();
      // }
      for (let i in this.days) {
        let a = this.days[i];
        // console.log(a)
        a.rest = false;
        if (restDate.indexOf(a.date) > -1) {
          a.rest = true;
        }
      }
    },

    dateArr(dateArr) {
      for (let i in this.days) {
        let a = this.days[i];
        a.rest = false;
        if (dateArr.indexOf(a.date) > -1) {
          a.rest = true;
        }
      }
    },
  },

  components: {},

  computed: {},

  created() {
    this.cMonth = this.month;
    this.getProData();
  },

  mounted() {},

  methods: {
    // 初始化
    initData() {
      this.title = new Date().getFullYear() + "年" + this.cMonth + "月";
      let cur = new Date().getFullYear() + "-" + this.cMonth + "-1";
      let now, curMonthStartDay, curMonthStartWeek, curPageStartDay;
      now = new Date(cur);
      // this.currentYear = now.getFullYear();
      this.currentMonth = now.getMonth() + 1;
      // this.currentDay = now.getDay();
      // 获取当前月第一天
      curMonthStartDay = new Date(
        this.formatDate(now.getFullYear(), now.getMonth() + 1, 1)
      );
      // 当前月第一天是周几
      curMonthStartWeek = curMonthStartDay.getDay(); // 1,2,3,4,5,6,0
      if (curMonthStartWeek == 0) {
        curMonthStartWeek = 7;
      }
      // 日历当前页开始日期
      curPageStartDay =
        curMonthStartDay -
        (this.fromsun ? curMonthStartWeek : curMonthStartWeek - 1) *
          24 *
          60 *
          60 *
          1000;
      // 循环获取日历当前页所有日期（7*this.lines \5/6\）
      this.days = [];
      for (let i = 0; i < 35; i++) {
        let year = new Date(
          curPageStartDay + i * 24 * 60 * 60 * 1000
        ).getFullYear();
        let month =
          new Date(curPageStartDay + i * 24 * 60 * 60 * 1000).getMonth() + 1;
        let day = new Date(curPageStartDay + i * 24 * 60 * 60 * 1000).getDate();
        let a = calendar.solar2lunar(year, month, day);
        a.rest = false;
        let festival = a.festival;
        let lunarFestival = a.lunarFestival;
        if (festival) {
          this.festivalObj[festival] = a.date;
        }
        if (lunarFestival) {
          this.festivalObj[lunarFestival] = a.date;
        }
        a.date = moment(a.date).format("YYYY-MM-DD");
        if (this.restDate.indexOf(a.date) > -1) {
          a.rest = true;
        }
        if (this.proData[a.date]) {
          let t = this.proData[a.date];
          a = { ...a, ...t };
        }
        this.days.push(a);
      }
      // console.log(this.days);
    },

    getProData() {
      let that = this;
      let customer_id = "";
      let c_id = JSON.parse(sessionStorage.userInfo).main.customer_id;
      if (c_id) {
        customer_id = c_id;
      }
      let param =
        "?pageNum=1" +
        "&pageSize=200" +
        "&customer_id=" +
        customer_id +
        "&is_deleted=0";
      this.$getAction(this.$Interface.project_list + param).then((res) => {
        if (res.code == 200) {
          let list = res.data.list;
          // console.log(list);

          let date = [];
          for (let i in list) {
            let d = list[i];
            let param = "?project_id=" + d.id;
            this.$getAction(this.$Interface.manage_yuyue_list + param)
              .then((res) => {
                if ((res.code = 200)) {
                  let list = res.data.list;
                  // console.log(list);
                  // let yuyue_arr1 = d.extInfo.yuyue_time;
                  // console.log(d.extInfo.yuyue_time)
                  let yuyue_arr = list;
                  let project_name = d.project_name;
                  for (let j in yuyue_arr) {
                    let a = yuyue_arr[j];
                    let base_day = a.base_day;
                    let items = a.items;
                    let t = {
                      project_name: project_name,
                      items: items,
                    };
                    if (!date[base_day]) {
                      date[base_day] = {
                        items: [],
                      };
                    }
                    date[base_day].items.push(t);
                  }
                }
              })
              .then(() => {
                that.proData = date;
                that.initData();
              });
          }
        }
      });
    },

    // 格式化 -> 2020-11-20
    formatDate(year, month, day) {
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      return year + "-" + month + "-" + day;
    },

    // 点击日期
    getClickDay(el, i) {
      // if (new Date(el.date).getTime() <= new Date().getTime()) {
      //   return;
      // }

      // if (el.cMonth != this.currentMonth) {
      //   return;
      // }
      let date = el.date;
      let lunarFestival = el.lunarFestival;
      let festival = el.festival;

      this.days[i].rest = !this.days[i].rest;
      let rest = this.days[i].rest;
      let index1 = this.selectFestival.indexOf(festival);
      let index2 = this.selectFestival.indexOf(lunarFestival);
      let restIndex = this.restDate.indexOf(date);
      if (!rest) {
        if (index1 > -1) {
          this.selectFestival.splice(index1, 1);
        }
        if (index2 > -1) {
          this.selectFestival.splice(index2, 1);
        }
        this.restDate.splice(restIndex, 1);
      }
      if (rest) {
        if (lunarFestival) {
          this.selectFestival.push(lunarFestival);
        }
        if (festival) {
          this.selectFestival.push(festival);
        }
        if (this.restDate.indexOf(date) == -1) {
          this.restDate.push(date);
        }
      }
      this.restDate.sort(function (a, b) {
        let date1 = new Date(a).getTime();
        let date2 = new Date(b).getTime();
        return date1 - date2;
      });
      this.$emit("dateChange", this.restDate);

      // if (new Date(el.date).getTime() <= new Date().getTime()) {
      //   return;
      // }
      // let date = el.date;

      // if (this.dayC == 2) {
      //   this.dayC = 0;
      //   this.dateArr = [];
      // }

      // if (this.dayC == 0) {
      //   if (this.dateArr.indexOf(date) == -1) {
      //     this.dateArr.push(date);
      //   }
      // }

      // if (this.dayC == 1) {
      //   if (this.dateArr.indexOf(date) == -1) {
      //     this.dateArr.push(date);
      //   }
      //   let date1 = new Date(this.dateArr[0]).getTime()
      //   let date2 = new Date(this.dateArr[1]).getTime()
      //   let t1  = this.dateArr[0];
      //   let t2 = this.dateArr[1]
      //   if(date1 > date2){
      //      t1  = this.dateArr[1];
      //      t2 = this.dateArr[0]
      //   }

      //   let arr = this.getDiffDate(t1, t2);
      //   this.dateArr = arr
      // }
      // this.dayC++;

      // this.$emit("dateChange", this.dateArr);
    },

    getDiffDate(start, end) {
      const startTime = this.getDate(start);
      const endTime = this.getDate(end);
      const dateArr = [];
      while (endTime.getTime() - startTime.getTime() >= 0) {
        const year = startTime.getFullYear();
        const month =
          startTime.getMonth().toString().length === 1
            ? "0" + (parseInt(startTime.getMonth().toString(), 10) + 1)
            : startTime.getMonth() + 1;
        const day =
          startTime.getDate().toString().length === 1
            ? "0" + startTime.getDate()
            : startTime.getDate();
        dateArr.push(year + "-" + month + "-" + day);
        startTime.setDate(startTime.getDate() + 1);
      }
      return dateArr;
    },

    getDate(datestr) {
      const temp = datestr.split("-");
      if (temp[1] === "01") {
        temp[0] = parseInt(temp[0], 10) - 1;
        temp[1] = "12";
      } else {
        temp[1] = parseInt(temp[1], 10) - 1;
      }
      const date = new Date(temp[0], temp[1], temp[2]);
      return date;
    },

    checkSelect(date) {
      let index = this.selectDate.indexOf(date);
      if (index == -1) {
        return false;
      } else {
        return true;
      }
    },

    leftC() {
      this.cMonth--;
      this.initData();
    },
    rightC() {
      this.cMonth++;
      this.initData();
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  /* height: 550px; */
  /* border: 1px solid #d9d9d9; */
  border-left: 1px solid #d9d9d9;
  /* border-radius: 4px; */
  margin: 5px;
  display: inline-block;
}

.title {
  text-align: center;
  border-bottom: 1px solid #d9d9d9;
  font-weight: bolder;
  padding: 2px;
  color: white;
  background: #1890ff;
  font-size: 14px;
}

.aline {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.zi {
  padding: 5px;
  text-align: center;
  width: 14.2857%;
  /* height: 42px;
  line-height: 42px; */

  height: 100px;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}

.other-month {
  color: gainsboro;
}

.active {
  background: #1890ff;
  color: #fff;
  /* border-radius: 4px; */
}

.festival {
  color: red;
  /* color: #1890ff; */
  /* color: #37d937; */
}

.xiu {
  position: absolute;
  top: 0;
  color: red;
  font-size: 8px;
}

.noAllow {
  cursor: not-allowed;
  color: gainsboro;
}

.leftC {
  cursor: pointer;
  float: left;
  margin-left: 15px;
  font-weight: bolder;
}

.rightC {
  cursor: pointer;
  float: right;
  margin-right: 15px;
  font-weight: bolder;
}

.proName {
  white-space: nowrap;

  overflow: hidden;

  text-overflow: ellipsis;
  text-align: left;
}

.d1 {
  height: 50px;
  line-height: 50px;
}
</style>
