<!-- 日记列表 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="操作时间">
            <a-date-picker
              placeholder="开始日期"
              :locale="locale"
              v-model="queryParam.startTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束日期"
              :locale="locale"
              v-model="queryParam.endTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="名称">
            <a-input placeholder="请输入" v-model="queryParam.name"></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div> -->

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <!-- <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        > -->
      </template>

      <template v-slot:imgArr="{ row }">
        <div>
          <img
            class="img1"
            :src="item"
            v-for="item in row.imgArr"
            :key="item"
            @click="seeImg(item)"
          />
        </div>
      </template>

      <template v-slot:action="{ row }">
        <a @click="preview(row)">查看问卷</a>
      </template>
    </vxe-grid>

    <preview ref="preview" :template="template"></preview>

    <vxe-modal  style="z-index: 10"  
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>
  </a-card>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import preview from "@/components/preview";

export default {
  data() {
    let _this = this;
    return {
      locale,
      queryParam: {},
      show: false,
      previewVisible: false,
      previewImage: "",
      template: {},
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          width: 80,
          slots: {
            default: "action",
          },
        },
        {
          title: "名称",
          field: "name",
        },
        {
          title: "性别",
          field: "sex",
          width: 50,
        },
        {
          title: "年龄",
          field: "age",
          width: 50,
        },
        {
          title: "电话",
          field: "phone",
          width: "100",
        },
        {
          title: "内容",
          field: "content",
        },
        {
          title: "图片",
          width: "185",
          slots: {
            default: "imgArr",
          },
        },
        {
          title: "打卡地址",
          field: "address",
        },
        {
          title: "打卡时间",
          field: "clock_time",
        },
        {
          title: "答卷时间",
          field: "answer_time",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param = _this.queryParam;
            param.page_num = page.currentPage;
            param.page_size = page.pageSize;
            let rdata = _this.$Request(this.url.list, "post", param);
            return rdata;

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    img: "https://cdn.uviewui.com/uview/album/1.jpg",
                    name: "Carson",
                    phone: "13392211015",
                    age: "20",
                    sex: "男",
                    clock_days: 1,
                    content: "赞赞赞赞赞赞赞赞赞赞",
                    imgArr: [
                      "https://cdn.uviewui.com/uview/album/7.jpg",
                      "https://cdn.uviewui.com/uview/album/8.jpg",
                      "https://cdn.uviewui.com/uview/album/9.jpg",
                    ],
                    address: "广东省佛山市顺德区",
                    clock_time: "2022-03-07 20:00",
                    answer_time: "2022-03-07 20:30",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
    };
  },

  components: { preview },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    showModal() {
      this.show = true;
    },
    preview(row) {
      this.template = {
        title: "调查问卷",
        content: [
          {
            title: "输入框",
            label: "姓名",
            type: "input",
            value: "Carson",
            code: "",
            verify: true,
            rules: [
              {
                required: true,
                errorMessage: "请输入",
              },
            ],
          },
          {
            title: "输入框",
            label: "身份证号",
            type: "input",
            value: "4406188",
            code: "",
            verify: true,
            rules: [
              {
                required: true,
                errorMessage: "请输入",
              },
            ],
          },
          {
            title: "单选项",
            value: "能看到反光",
            type: "radio",
            code: "",
            verify: true,
            label:
              "(单选)洗完脸后的2-3小时，不在脸上涂任何保湿/防晒产品、化妆水、粉底或任何产品，这时如果在明亮的光线下照镜子，你的前额和脸颊部位：",
            rules: [
              {
                required: true,
                errorMessage: "请选择",
              },
            ],
            options: [
              {
                label: "能看到反光",
                text: "能看到反光",
                value: "能看到反光",
              },
              {
                label: "仍有紧绷感",
                text: "仍有紧绷感",
                value: "仍有紧绷感",
              },
              {
                label: "能够回复正常的润泽感而且在镜中看不到反光",
                text: "能够回复正常的润泽感而且在镜中看不到反光",
                value: "能够回复正常的润泽感而且在镜中看不到反光",
              },
              {
                label: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                text: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                value: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
              },
            ],
            hasOpt: true,
          },
          {
            title: "多选项",
            value: ["光滑", "出现闪亮"],
            type: "checkbox",
            code: "",
            verify: true,
            label:
              "(多选)上妆或使用粉底，但是不涂干的粉（如质地干燥的粉饼或散粉），2-3小时后，你的妆容看起来： ",
            rules: [
              {
                required: true,
                errorMessage: "请选择",
              },
            ],
            options: [
              {
                label: "出现皮屑，有的粉底在皱纹里结成小块",
                text: "出现皮屑，有的粉底在皱纹里结成小块",
                value: "出现皮屑，有的粉底在皱纹里结成小块",
              },
              {
                label: "光滑",
                text: "光滑",
                value: "光滑",
              },
              {
                label: "出现闪亮",
                text: "出现闪亮",
                value: "出现闪亮",
              },
              {
                label: "出现条纹并且闪亮",
                text: "出现条纹并且闪亮",
                value: "出现条纹并且闪亮",
              },
            ],
            hasOpt: true,
          },
        ],
      };
      this.$refs.preview.showModal();
    },

    seeImg(img) {
      this.previewVisible = true;
      this.previewImage = img;
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>
