<!-- 志愿者列表 -->
<template>
  <div style="width: 1200px">
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
    >
    </vxe-grid>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  props: ["project_id"],
  data() {
    let that = this;
    return {
      tableData: [],
      zyzImg: [],
      previewVisible: false,
      previewImage: "",
      remarkModal: false,
      subModal: false,
      locale,
      queryParam: {},
      show: false,
      template: {},
      status: "P",
      optStatus: [
        { label: "待审核", value: "P" },
        { label: "已加入", value: "J" },
        { label: "被驳回", value: "R" },
        { label: "已拉黑", value: "B" },
        { label: "已退出", value: "E" },
      ],
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "志愿者",
          field: "volunteer_name",
        },
        {
          title: "性别",
          field: "volunteer_sex_text",
        },
        {
          title: "年龄",
          field: "volunteer_age",
        },
        {
          title: "手机",
          field: "volunteer_phone",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: async ({ page, sort, filters, form }) => {
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&project_id=" +
              that.project_id
              +'&is_record=0'

            return that.$getAction(that.$Interface.question_status + param);
          },
        },
      },

      formData: {
        remark: "",
      },
      formRules: {
        remark: [{ required: true, message: "请输入" }],
      },
      selectionRows: [],
      selectedRowKeys: [],
      selRow: {},
      selStatus: "",
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    setStatus(row, status) {
      // P 待审核 J 已加入R 被驳回B 已拉黑E 已退出
      let that = this;
      let param = {
        id: row.id,
        status: status,
        // volunteer_id: row.volunteer_id,
        // project_id: that.project_id,
      };
      that.$postAction(that.$Interface.project_zyz_apply, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    setStatus2(row, status) {
      this.selRow = row;
      this.selStatus = status;
      this.remarkModal = true;
    },
    piSet(status) {
      let that = this;
      if (!that.selectedRowKeys.length) {
        that.$message.warn("请选择志愿者");
        return;
      }
      let id = that.selectedRowKeys.join(",");
      let param = {
        id: id,
        status: status,
      };
      that.$postAction(that.$Interface.project_zyz_apply, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    remark() {},
    bulu() {
      this.subModal = true;
    },
    submitEvent() {
      let that = this;
      let param = {
        id: that.selRow.id,
        status: that.selStatus,
        remark: that.formData.remark,
      };
      that.$postAction(that.$Interface.project_zyz_apply, param).then((res) => {
        if (res.code == 200) {
          that.remarkModal = false;
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    seeImg(img) {
      // this.previewVisible = true;
      // this.previewImage = img;
      this.$emit("seeImg", img);
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>



