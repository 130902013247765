<!-- 含有的问卷模板 -->
<template>
  <div style="width: 1200px">
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config2="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      :data="tableData"
    >
      <template v-slot:action="{ row }">
        <a-badge :count="row.num">
          <a @click="showQue(row)">查看详情</a>
        </a-badge>
      </template>
    </vxe-grid>

    <!-- <a-modal
      v-model="recordMod"
      title="调查问卷记录"
      show-zoom
      resize
      destroy-on-close
      width="90%"
      :footer="false"
      :maskClosable="false"
    >
      <record ref="record" />
    </a-modal> -->

    <vxe-modal
      style="z-index: 10"
      v-model="recordMod"
      title="调查问卷记录"
      show-zoom
      resize
      destroy-on-close
      width="90%"
      height="90%"
    >
      <a-tabs type="card">
        <!-- <a-tab-pane key="0" tab="未填写">
          <div class="tabContent">
            <noFill ref="noFill" style="width: 100%"  />
          </div>
        </a-tab-pane> -->
        <a-tab-pane key="0" tab="问卷记录">
          <div class="tabContent">
            <record
              ref="record"
              style="width: 100%"
              :answer="selRow.answer"
              :project_id="project_id"
              :question_id="selRow.question_id"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="1" tab="数据统计">
          <div class="tabContent">
            <recordJi
              ref="recordJi"
              style="width: 100%"
              :answer="selRow.answer"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </vxe-modal>

    <preview ref="preview" :template="template"></preview>
  </div>
</template>

<script>
import preview from "@/components/preview";
import record from "./question_record.vue";
import recordJi from "./recordJi";
import noFill from "./no_fill";

export default {
  props: ["project_id"],
  data() {
    let that = this;
    return {
      selRow: {},
      recordMod: false,
      tableData: [],
      template: {},
      queryParam: {},
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          width: 100,
          slots: {
            default: "action",
          },
        },
        {
          title: "调查问卷名称",
          field: "question_name",
        },
        {
          title: "类型",
          field: "question_type",
        },
        {
          title: "开始时间",
          field: "begin_time",
        },
        {
          title: "结束时间",
          field: "end_time",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param = "?project_id=" + that.project_id;
            that.$getAction(that.$Interface.question_record_list + param);
          },
        },
      },
    };
  },

  components: { preview, record, recordJi, noFill },

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    async getData() {
      let that = this;
      that.tableData = [];
      let param =
        "?project_id=" + that.project_id + "&pageNum=" + 1 + "&pageSize=" + 200;

      let arr = [];
      await that
        .$getAction(that.$Interface.project_edit + "?id=" + that.project_id)
        .then(async (res) => {
          if (res.code == 200) {
            let data = res.data;
            let question = data.question;
            for (let i in question) {
              let a = question[i];
              if (!arr[a.question_id]) {
                arr[a.question_id] = {
                  answer: [],
                };
              }
              arr[a.question_id].question_id = a.question_id;
              arr[a.question_id].question_name = a.question_name;
              arr[a.question_id].question_type = a.type_text;
              arr[a.question_id].begin_time = a.begin_time;
              arr[a.question_id].end_time = a.end_time;
              arr[a.question_id].num = 0;
              let param1 =
                "?pageNum=" +
                "&pageSize=" +
                "&project_id=" +
                that.project_id +
                "&question_id=" +
                a.question_id +
                "&is_record=0";

              await that
                .$getAction(that.$Interface.question_status + param1)
                .then((res) => {
                  if (res.code == 200 && res.data.list) {
                    arr[a.question_id].num = res.data.list.length;
                  }
                });
            }
          }
        });

      that
        .$getAction(that.$Interface.question_record_list + param)
        .then((res) => {
          if (res.code == 200) {
            let data = res.data.list;
            // console.log(data);
            for (let i in data) {
              let a = data[i];
              let e = a.ext_info;
              let s = a.stat_info;
              if (!arr[a.question_id]) {
                arr[a.question_id] = {
                  answer: [],
                };
              }
              arr[a.question_id].question_id = a.question_id;
              arr[a.question_id].question_name = e.name;
              arr[a.question_id].question_type = e.type_text;
              arr[a.question_id].begin_time = e.begin_time;
              arr[a.question_id].end_time = e.end_time;
              arr[a.question_id].ext_info = e;
              let user = {
                project_name: s.project_name,
                project_number: s.project_number,
                created_at: a.created_at,
                volunteer: s.volunteer_name,
                question_name: e.name,
                question_type: e.type_text,
                content: JSON.parse(a.answer),
                score: a.score,
              };

              arr[a.question_id].answer.push(user);
            }
            arr = Object.values(arr);
            that.tableData = arr;
          }
        });
    },
    showQue(row) {
      let that = this;
      console.log(row);
      that.selRow = row;
      that.recordMod = true;
      // that.$nextTick(() => {
      //   that.$refs.record.getData(row.answer);
      // });
    },
    preview(row) {
      let template = {
        name: "调查问卷",
        item: [
          {
            title: "输入框",
            label: "姓名",
            type: "input",
            value: "Carson",
            code: "",
            verify: true,
            rules: [
              {
                required: true,
                errorMessage: "请输入",
              },
            ],
          },
          {
            title: "输入框",
            label: "身份证号",
            type: "input",
            value: "4406188",
            code: "",
            verify: true,
            rules: [
              {
                required: true,
                errorMessage: "请输入",
              },
            ],
          },
          {
            title: "单选项",
            value: "能看到反光",
            type: "radio",
            code: "",
            verify: true,
            label:
              "(单选)洗完脸后的2-3小时，不在脸上涂任何保湿/防晒产品、化妆水、粉底或任何产品，这时如果在明亮的光线下照镜子，你的前额和脸颊部位：",
            rules: [
              {
                required: true,
                errorMessage: "请选择",
              },
            ],
            options: [
              {
                label: "能看到反光",
                text: "能看到反光",
                value: "能看到反光",
              },
              {
                label: "仍有紧绷感",
                text: "仍有紧绷感",
                value: "仍有紧绷感",
              },
              {
                label: "能够回复正常的润泽感而且在镜中看不到反光",
                text: "能够回复正常的润泽感而且在镜中看不到反光",
                value: "能够回复正常的润泽感而且在镜中看不到反光",
              },
              {
                label: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                text: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
                value: "非常粗糙、出现皮屑，或者如布满灰尘般的晦暗",
              },
            ],
            hasOpt: true,
          },
          {
            title: "多选项",
            value: ["光滑", "出现闪亮"],
            type: "checkbox",
            code: "",
            verify: true,
            label:
              "(多选)上妆或使用粉底，但是不涂干的粉（如质地干燥的粉饼或散粉），2-3小时后，你的妆容看起来： ",
            rules: [
              {
                required: true,
                errorMessage: "请选择",
              },
            ],
            options: [
              {
                label: "出现皮屑，有的粉底在皱纹里结成小块",
                text: "出现皮屑，有的粉底在皱纹里结成小块",
                value: "出现皮屑，有的粉底在皱纹里结成小块",
              },
              {
                label: "光滑",
                text: "光滑",
                value: "光滑",
              },
              {
                label: "出现闪亮",
                text: "出现闪亮",
                value: "出现闪亮",
              },
              {
                label: "出现条纹并且闪亮",
                text: "出现条纹并且闪亮",
                value: "出现条纹并且闪亮",
              },
            ],
            hasOpt: true,
          },
        ],
      };
      this.$emit("previewQuestion", template);
    },
  },
};
</script>

<style scoped>
.tabContent {
  border: 1px solid #e8e8e8;
  border-top: 0;
  padding: 10px;
  margin-top: -16px;
}
</style>
