import Axios from 'axios'; // 引入axios库
import Qs from 'qs'; // 引入qs库
Axios.defaults.withCredentials = true
let that;
export function Request(requestUrl, method = 'get', requestData = {}) {
	that = this;
	return new Promise(function (resolve, reject) {
		Axios({
			method: method,
			url: requestUrl,
			data: Qs.stringify(requestData),
			// data: requestData,
			timeout: 15000,
		}).then(function (res) {
			// checkStatus(res.data);
			resolve(res.data);
		}).catch(function (error) {
			reject(error);
			alert('请求失败:' + error)
		});
	})
}

export function getAction(requestUrl,requestData = {}) {
	that = this;
	return new Promise(function (resolve, reject) {
		Axios({
			method: 'get',
			url: requestUrl,
			data: requestData,
			timeout: 15000,
		}).then(function (res) {
			// checkStatus(res.data);
			if (res.data.code == 401)
			{
				that.$router.push("/login");
			}
			resolve(res.data);
		}).catch(function (error) {
			reject(error);
			alert('请求失败:' + error)
		});
	})
}

export function postAction(requestUrl, requestData = {}) {
	that = this;
	return new Promise(function (resolve, reject) {
		Axios({
			method: 'post',
			url: requestUrl,
			// data: Qs.stringify(requestData),
			data: requestData,
			timeout: 15000,
		}).then(function (res) {
			// checkStatus(res.data);
			if (res.data.code == 401)
			{
				that.$router.push("/login");
			}
			resolve(res.data);
		}).catch(function (error) {
			reject(error);
			alert('请求失败:' + error)
		});
	})
}

export function RequestStr(requestUrl, method = 'get', requestData = {}) {
	that = this;
	return new Promise(function (resolve, reject) {
		Axios({
			method: method,
			url: requestUrl,
			data: Qs.stringify(requestData),
			// data:requestData,
			timeout: 15000,
		}).then(function (res) {
			// checkStatus(res.data);
			resolve(res.data);
		}).catch(function (error) {
			reject(error);
			alert('请求失败:' + error)
		});
	})
}

export function uploadAction(file) {
	that = this;
	let param = new FormData();
	param.append("images", file);
	param.append("name", "images");
	param.append("filename", file.name);
	let config = {
		headers: {
			"Content-Type": "multipart/form-data"
		},
	};
	return new Promise(function (resolve, reject) {
		Axios.post(that.$Interface.upload_img, param, config)
			.then(function (res) {
				checkStatus(res.data);
				resolve(res.data);
			}).catch(function (error) {
				reject(error);
				alert('请求失败:' + error)
			});
	})
}



export function getFileAccessHttpUrl(avatar, subStr) {
	if (!subStr) subStr = 'http'
	try {
		if (avatar && avatar.startsWith(subStr)) {
			return avatar;
		} else {
			if (avatar && avatar.length > 0 && avatar.indexOf('[') == -1) {
				return window._CONFIG['staticDomainURL'] + "/" + avatar;
			}
		}
	} catch (err) {
		return;
	}
}


function checkStatus(obj, success, fail, next) {
	if (obj.code == 200) {
		if (typeof (success) == 'function') {
			success();
		}
	} else {
		if (typeof (fail) == 'function') {
			fail();
		}
		// alert('请求错误:' + obj.msg)
		that.$message.error({
			title: '请求错误',
			content: obj.msg,
		});
	}
}