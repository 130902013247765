<!-- 志愿者列表 -->
<template>
  <div style="width: 1200px" id="clock_detail">
    <!-- <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="打卡时间">
            <a-date-picker
              placeholder="开始时间"
              :locale="locale"
              v-model="queryParam.startTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束时间"
              :locale="locale"
              v-model="queryParam.endTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form> -->

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="piSet('J')"
          >批通过</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piSet('R')"
          >批不通过</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piSet('B')"
          >批黑名单</vxe-button
        >
        状态：<a-select
          v-model="status"
          :options="optStatus"
          size="small"
          style="width: 100px"
          allowClear
        />
        <vxe-button
          status="primary"
          size="mini"
          @click="loadData"
          style="margin-left: 10px"
          >查询</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="setStatus(row, 'J')" :disabled2="row.status != 'P'">通过</a>
        <a
          @click="setStatus2(row, 'R')"
          :disabled2="row.status != 'P'"
          style="margin-left: 8px"
          >不通过</a
        >
        <a @click="setStatus2(row, 'B')" style="margin-left: 8px">黑名单</a>

        <!-- <a @click="remark" style="margin-left: 8px">补录</a>
        <a @click="remark" style="margin-left: 8px">中途退出</a>
        <a @click="remark" style="margin-left: 8px">结束</a> -->
      </template>

      <template v-slot:img="{ row }">
        <template v-for="item in zyzImg[row.volunteer_name]">
          <img
            class="img1"
            :src="item"
            :key="item"
            v-if="item.mime_type != 'video/mp4' || item.mime_type == ''"
            @click="seeImg(item)"
          />
        </template>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="remarkModal"
      title="备注"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="备注"
          field="remark"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  props: ["project_id"],
  data() {
    let that = this;
    return {
      zyzImg: [],
      previewVisible: false,
      previewImage: "",
      remarkModal: false,
      subModal: false,
      locale,
      queryParam: {},
      show: false,
      template: {},
      status: "P",
      optStatus: [
        { label: "待审核", value: "P" },
        { label: "已加入", value: "J" },
        { label: "被驳回", value: "R" },
        { label: "已拉黑", value: "B" },
        { label: "已退出", value: "E" },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          width: 230,
          slots: {
            default: "action",
          },
        },
        {
          title: "姓名",
          field: "volunteer_name",
          width: 120,
        },
        {
          title: "图片",
          slots: {
            default: "img",
          },
        },
        {
          title: "性别",
          field: "sex",
        },
        {
          title: "年龄",
          field: "age",
        },
        {
          title: "报名时间",
          field: "apply_at",
          width: 150,
        },
        // {
        //   title: "样品标签",
        //   field: "tag",
        // },
        {
          title: "备注",
          field: "status_remark",
        },
        {
          title: "状态",
          field: "status_text",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: async ({ page, sort, filters, form }) => {
            let arr = [];
            let param2 = "?project_id=" + that.project_id + "&pageNum=" + 1 + "&pageSize=" + 200 
            await that
              .$getAction(that.$Interface.question_record_list + param2)
              .then((res) => {
                if (res.code == 200) {
                  let data = res.data.list;
                  for (let i in data) {
                    let a = data[i];
                    let s = data[i].stat_info;
                    if (s.question_type == "P") {
                      let answer = JSON.parse(a.answer);
                      let imgIndex = that._.findIndex(answer, {
                        type: "uploadImg",
                      });
                      let img = [];
                      if (imgIndex > -1) {
                        img = answer[imgIndex].value;
                      }
                      arr[s.volunteer_name] = img;
                    }
                  }
                }
              });
            that.zyzImg = arr;
            // console.log(that.zyzImg)

            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&id=" +
              that.project_id +
              "&status=" +
              that.status;
            return that.$getAction(that.$Interface.project_zyz_list + param);

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    name: "Carson",
                    join_time: "2022-03-07 20:00",
                    tag: "保湿",
                    volunteer_tag: "",
                    status: 0,
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },

      formData: {
        remark: "",
      },
      formRules: {
        remark: [{ required: true, message: "请输入" }],
      },
      selectionRows: [],
      selectedRowKeys: [],
      selRow: {},
      selStatus: "",
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    setStatus(row, status) {
      // P 待审核 J 已加入R 被驳回B 已拉黑E 已退出
      let that = this;
      let param = {
        id: row.id,
        status: status,
        // volunteer_id: row.volunteer_id,
        // project_id: that.project_id,
      };
      that.$postAction(that.$Interface.project_zyz_apply, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    setStatus2(row, status) {
      this.selRow = row;
      this.selStatus = status;
      this.remarkModal = true;
    },
    piSet(status) {
      let that = this;
      if (!that.selectedRowKeys.length) {
        that.$message.warn("请选择志愿者");
        return;
      }
      let id = that.selectedRowKeys.join(",");
      let param = {
        id: id,
        status: status,
      };
      that.$postAction(that.$Interface.project_zyz_apply, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    remark() {},
    bulu() {
      this.subModal = true;
    },
    submitEvent() {
      let that = this;
      let param = {
        id: that.selRow.id,
        status: that.selStatus,
        remark: that.formData.remark,
      };
      that.$postAction(that.$Interface.project_zyz_apply, param).then((res) => {
        if (res.code == 200) {
          that.remarkModal = false;
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error("操作失败");
        }
      });
    },
    seeImg(img) {
      // this.previewVisible = true;
      // this.previewImage = img;
      this.$emit("seeImg", img);
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>



