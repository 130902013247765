<!-- 项目详情 -->
<template>
  <div style="margin: 20px">
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :columns="columns"
      :data="listData"
    >
    </vxe-grid>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listData: [
        {
          project_name: "测试项目一",
          person: "Carson",
          createTime: "2022-3-7",
          startTime: "2022-3-7",
          endTime: "2022-4-7",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "项目名称",
          field: "project_name",
        },
        {
          title: "参与者",
          field: "person",
        },
        {
          title: "参与人数",
          slots: {
            default: ({row}) => {
              let arr = row.person.split(",");
              let num = arr.length;
              return num;
            },
          },
        },
        {
          title: "创建日期",
          field: "createTime",
        },
        {
          title: "开始日期",
          field: "startTime",
        },
        {
          title: "结束日期",
          field: "endTime",
        },
      ],
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>

<style scoped>
</style>
