<!-- 统计 -->
<template>
  <div id="recordJi">
    <a-button
      type="primary"
      size="small"
      @click="exportData"
      style="margin-bottom: 10px"
      >导出</a-button
    >
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-header-overflow
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :columns="columns"
      :data="tableData"
    >
    </vxe-grid>
  </div>
</template>

<script>
export default {
  props: ["answer"],
  data() {
    return {
      tableData: [],
      columns: [
        {
          title: "题目",
          field: "question",
        },
        {
          title: "很好",
          field: "s5",
        },
        {
          title: "好",
          field: "s4",
        },
        {
          title: "一般",
          field: "s3",
        },
        {
          title: "不好",
          field: "s2",
        },
        {
          title: "很不好",
          field: "s1",
        },
        {
          title: "人数(人)",
          field: "person",
          slots: {
            default: ({ row }) => {
              let num = row.s5 + row.s4;
              return num;
            },
          },
        },
        {
          title: "(很好+好)占比(%)",
          field: "percent",
          slots: {
            default: ({ row }) => {
              let all = row.s5 + row.s4 + row.s3 + row.s2 + row.s1;
              let p = row.s5 + row.s4;
              let num = (p / all).toFixed(2) * 100;
              return num;
            },
          },
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
    };
  },

  components: {},

  computed: {},

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let that = this;
      let arr = that.answer;
      // console.log(tableData);
      that.tableData = [];
      for (let i in arr) {
        let a = arr[i];
        for (let b in a.content) {
          let item = a.content[b];
          if (item.type == "radio" || item.type == "checkbox") {
            if (JSON.stringify(item.value).indexOf("分") > -1) {
              if (!that.tableData[item.label]) {
                that.tableData[item.label] = {
                  question: item.label,
                  s5: 0,
                  s4: 0,
                  s3: 0,
                  s2: 0,
                  s1: 0,
                };
              }
              if (item.type == "radio") {
                let s = item.value;
                s = s.split("(")[1];
                s = s.split("分")[0];
                if (that.tableData[item.label].hasOwnProperty("s" + s)) {
                  that.tableData[item.label]["s" + s]++;
                }
              }
              if (item.type == "checkbox") {
                for (let k in item.value) {
                  let s = item.value[k];
                  s = s.split("(")[1];
                  s = s.split("分")[0];
                  if (that.tableData[item.label].hasOwnProperty("s" + s)) {
                    that.tableData[item.label]["s" + s]++;
                  }
                }
              }
            }
          }
        }
      }
      that.tableData = Object.values(that.tableData);
    },
    exportData() {
      let that = this;
      const workBook = this.$XLSX.utils.table_to_book(
        that.$jq("#recordJi .vxe-table--render-wrapper")[0],
        { raw: true }
      );
      this.$XLSX.writeFile(workBook, "导出.xlsx");
    },
  },
};
</script>

<style scoped>
</style>
