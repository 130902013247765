<!-- 历史单 -->
<template>
  <vxe-modal
    style="z-index: 10"
    v-model="showModal"
    title="项目列表"
    width="800"
    height="600"
    resize
    destroy-on-close
    show-zoom
  >
    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      @radio-change="radioChangeEvent"
    >
    </vxe-grid>
    <a-button @click="handleOk" type="primary" style="float: right"
      >确定</a-button
    >
  </vxe-modal>
</template>

<script>
export default {
  data() {
    let _this = this;
    return {
      showModal: false,
      selectRow: {},
      template: {},
      queryParam: {},
      columns: [
        { type: "radio", width: 45 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "项目名称",
          field: "project_name",
          //   width: 120,
        },
        {
          title: "项目编号",
          field: "code",
          //   width: 120,
        },
        {
          title: "项目类别",
          field: "project_type",
          //   width: 120,
        },
        {
          title: "标签",
          field: "tag",
          //   width: 120,
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize   +"&customer_id=" +
              customer_id +
              "&is_deleted=0" 
            let rdata = _this.$getAction(this.$Interface.project_list + param);
            return rdata;
          },
        },
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    show() {
      this.showModal = true;
    },
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
    handleOk() {
      this.$emit("ok", this.selectRow);
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
</style>
