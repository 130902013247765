<!-- 参与的项目 -->
<template>
  <div id="sta">
    <a-button
      type="primary"
      size="small"
      @click="exportData"
      style="margin-bottom: 10px"
      >导出</a-button
    >
    <vxe-grid
      ref="table"
      height="500"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config2="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      :data="tableData"
    >
    </vxe-grid>
  </div>
</template>

<script>
export default {
  props: ["project_id"],
  data() {
    let that = this;
    return {
      tableData: [],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {},
        },
      },
      columns: [
        // {
        //   type: "seq",
        //   width: "50",
        //   title: "序号",
        //   slots: {
        //     default: ({ seq }) => {
        //       return seq;
        //     },
        //   },
        // },
        {
          title: "打卡时间",
          field: "time",
          width: 250,
          fixed: "left",
        },
      ],
    };
  },

  components: {},

  computed: {},

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    exportData() {
      let that = this;
      const workBook = this.$XLSX.utils.table_to_book(
        that.$jq("#sta .vxe-table--render-wrapper")[0],
        { raw: true }
      );
      this.$XLSX.writeFile(workBook, "导出.xlsx");
    },
    async getData() {
      let that = this;
      let time = [];
      let tableData = [];

      let param1 = "?pageNum=1&pageSize=200&&status=J&id=" + that.project_id;
      let name_arr = [];
      await that
        .$getAction(that.$Interface.project_zyz_list + param1)
        .then((res) => {
          if (res.code == 200) {
            let data = res.data.list;
            for (let i in data) {
              let a = data[i];
              let name = a.volunteer_name;
              let col = {
                title: name,
                field: name,
                width: 60,
                slots: {
                  default: (data) => {
                    let columnIndex = data.columnIndex;
                    let col = that.columns[columnIndex].field;
                    let row = data.row;
                    let str = row[col];
                    let html = [<span style="color:red">{str}</span>];
                    if (str == "已打卡") {
                      html = [<span style="color:#05ff05">{str}</span>];
                    }
                    return html;
                  },
                },
              };
              that.columns.push(col);
              name_arr.push(name);
            }
          }
        });

      await this.$getAction(
        this.$Interface.pro_clock_list + "?project_id=" + that.project_id
      ).then((res) => {
        if ((res.code = 200)) {
          let list = res.data.list;
          for (let i in list) {
            let a = list[i];
            let base_day = a.base_day;
            for (let j in a.items) {
              let t = a.items[j];
              let str = base_day + " " + t.begin_time + " ~ " + t.end_time;
              let day = {
                time: str,
                day: base_day,
                begin_time: t.begin_time,
                end_time: t.end_time,
              };
              time.push(day);

              let h = {
                time: str,
                day: base_day,
                begin_time: t.begin_time,
                end_time: t.end_time,
              };
              for (let k in name_arr) {
                let name = name_arr[k];
                h[name] = "未打卡";
              }
              if (new Date(base_day).getTime() <= new Date().getTime()) {
                tableData.push(h);
              }
            }
          }
        }
      });
      // console.log(time);

      let param =
        "?pageNum=1" + "&pageSize=200" + "&project_id=" + that.project_id;
      // "&record_time_begin=" +
      // p.createBegin +
      // "&record_time_end=" +
      // p.createEnd + " 23:59:59"

      that.$getAction(that.$Interface.pro_clock_record + param).then((res) => {
        if (res.code == 200) {
          let data = res.data.list;
          for (let i in data) {
            let a = data[i];
            let name = a.volunteer_name;
            for (let j in tableData) {
              let t = tableData[j];
              let time = new Date(a.createdAt).getTime();
              let begin_time = new Date(t.day + " " + t.begin_time).getTime();
              let end_time = new Date(t.day + " " + t.end_time).getTime();
              if (begin_time <= time && time <= end_time) {
                t[name] = "已打卡";
              }
            }
          }
        }
      });

      that.tableData = tableData;
    },
  },
};
</script>

<style scoped>
</style>
