<!-- 功效评价 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="创建时间">
            <a-date-picker
              placeholder="开始时间"
              :locale="locale"
              v-model="queryParam.createBegin"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束时间"
              :locale="locale"
              v-model="queryParam.createEnd"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="项目名称">
            <a-input
              placeholder="请输入"
              v-model="queryParam.projectName"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="项目编号">
            <a-input
              placeholder="请输入"
              v-model="queryParam.projectNO"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="状态">
            <a-select
              placeholder="请选择"
              v-model="queryParam.status"
              :options="status_opt"
              allowClear
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="项目类型">
            <a-select
              placeholder="请选择"
              v-model="queryParam.projectType"
              :options="typeOptions"
              allowClear
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
        <!-- <vxe-button status="primary" size="mini" @click="piSetStauts(3)"
          >批通过</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="piSetStauts(1)"
          >批不通过</vxe-button
        > -->
      </template>

      <template v-slot:action="{ row }">
        <a @click="see(row)">查看</a>
        <a style="margin-left: 8px" @click="detail(row)">详情</a>
        <a style="margin-left: 8px" @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row.id)">删除</a>
        <template v-if="sf == '管理员'">
          <a
            @click="setStatus(row, 3)"
            :class="{ disabled: row.status != 2 }"
            style="margin-left: 8px"
            >通过</a
          >
          <a
            @click="setStatus(row, 1)"
            :class="{ disabled: row.status != 2 }"
            style="margin-left: 8px"
            >不通过</a
          >
        </template>
      </template>

      <template v-slot:intable="{ row }">
        <a-tabs type="card" style="margin: 20px" destroyInactiveTabPane>
          <a-tab-pane key="0" tab="志愿者列表">
            <div class="tabContent">
              <volunteer-detail :project_id="row.id" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="1" tab="打卡详情">
            <div class="tabContent">
              <clock-detail @seeImg="seeImg" :project_id="row.id" />
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" tab="问卷详情">
            <div class="tabContent">
              <questionDetail
                @previewQuestion="previewQuestion"
                :project_id="row.id"
              />
            </div>
          </a-tab-pane>
          <a-tab-pane key="3" tab="公告">
            <div class="tabContent">
              <noticeList @seeImg="seeImg" :project_id="row.id" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="1200"
      height="90%"
      resize
      destroy-on-close
      show-zoom
    >
      <edit-modal ref="editModal" style="z-index: 100" @loadData="loadData" />
    </vxe-modal>

    <!-- <a-modal
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="1050"
      height="90%"
      resize
      destroy-on-close
      show-zoom
      :footer="null"
    >
      <edit-modal ref="editModal" style="z-index: 100" @loadData="loadData" />
    </a-modal> -->

    <vxe-modal
      style="z-index: 10"
      v-model="detailModal"
      title="项目详情"
      width="1200"
      height="80%"
      resize
      destroy-on-close
      show-zoom
    >
      <a-tabs type="card" destroyInactiveTabPane>
        <a-tab-pane key="0" tab="志愿者列表">
          <div class="tabContent">
            <volunteer-detail style="width: 100%" :project_id="project_id" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="1" tab="打卡详情">
          <div class="tabContent">
            <clock-detail
              style="width: 100%"
              @seeImg="seeImg"
              :project_id="project_id"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="问卷详情">
          <div class="tabContent">
            <questionDetail
              style="width: 100%"
              @previewQuestion="previewQuestion"
              :project_id="project_id"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" tab="预约查询">
          <div class="tabContent">
            <yuyueDetail :project_id="project_id" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="5" tab="日志">
          <div class="tabContent">
            <logList :project_id="project_id" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="公告">
          <div class="tabContent">
            <noticeList
              style="width: 100%"
              @seeImg="seeImg"
              :project_id="project_id"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </vxe-modal>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>

    <preview ref="preview" :template="template"></preview>

    <detailModal ref="detailModal" />
  </a-card>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import clockDetail from "./modal/clock_detail";
import questionDetail from "./modal/question_detail";
import noticeList from "./modal/notice_list";
import editModal from "./modal/edit_modal";
import preview from "@/components/preview";
import detailModal from "./modal/detail_modal.vue";
import volunteerDetail from "./modal/volunteer_detail.vue";
import yuyueDetail from "./modal/yuyue_detail.vue";
import logList from "./modal/log_list.vue";

export default {
  data() {
    let _this = this;
    return {
      locale,
      project_id: "",
      detailModal: false,
      previewVisible: false,
      previewImage: "",
      queryParam: {
        createBegin: "",
        createEnd: "",
        projectName: "",
        projectNO: "",
        status: "",
        projectType: "",
      },
      template: {},
      typeOptions: [],
      status_opt: [
        { label: "草稿", value: 0 },
        { label: "被驳回", value: 1 },
        { label: "审核中", value: 2 },
        { label: "未开始", value: 4 },
        { label: "进行中", value: 5 },
        { label: "已结束", value: 6 },
        { label: "已中止", value: 7 },
        { label: "已暂停", value: 8 },
        { label: "已删除", value: "delete" },
      ],
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: _this.$Interface.prod_list,
      },
      columns: [
        { type: "checkbox", width: 50 },
        // {
        //   width: 50,
        //   title: "",
        //   type: "expand",
        //   slots: {
        //     content: "intable",
        //   },
        // },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          width: 250,
          slots: {
            default: "action",
          },
        },
        {
          title: "项目名称",
          field: "project_name",
          width: 120,
        },
        {
          title: "状态",
          field: "status_text",
          width: 120,
        },
        {
          title: "项目编号",
          field: "code",
          width: 120,
        },
        {
          title: "项目类别",
          field: "project_type",
          width: 120,
        },
        {
          title: "标签",
          field: "tag_text",
          width: 120,
        },
        // {
        //   title: "简介",
        //   field: "info",
        //   width: 120,
        // },
        {
          title: "样品名称",
          field: "sample_name",
          width: 120,
        },
        {
          title: "样品编号",
          field: "sample_num",
          width: 120,
        },

        // {
        //   title: "第1次打卡时间",
        //   slots: {
        //     default: ({ row }) => {
        //       return row.clock_firstTime1 + "-" + row.clock_firstTime2;
        //     },
        //   },
        //   width: 120,
        // },
        // {
        //   title: "第2次打卡时间",
        //   slots: {
        //     default: ({ row }) => {
        //       return row.clock_secondTime1 + "-" + row.clock_secondTime2;
        //     },
        //   },
        //   width: 120,
        // },
        {
          title: "开始时间",
          field: "extInfo.clock_startTime",
          width: 120,
        },
        {
          title: "结束时间",
          field: "extInfo.clock_endTime",
          width: 120,
        },
        {
          title: "创建人",
          field: "creator_name",
          width: 120,
        },
        {
          title: "创建时间",
          field: "createdAt",
          width: 120,
        },
        // {
        //   title: "参与人数",
        //   field: "participate_num",
        //   width: 120,
        // },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let p = _this.queryParam;
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let status = p.status;
            p.is_deleted = 0;
            if (p.status == "delete") {
              p.is_deleted = 1;
            }
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&createBegin=" +
              p.createBegin +
              "&createEnd=" +
              p.createEnd +
              "&projectName=" +
              p.projectName +
              "&projectNO=" +
              p.projectNO +
              "&status=0" +
              "&projectType=" +
              p.projectType +
              "&customer_id=" +
              customer_id +
              "&is_deleted=" +
              p.is_deleted;

            // let param =
            //   "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize;
            return _this.$getAction(this.$Interface.project_list + param);

            let data = {
              data: {
                current: 1,
                list: [
                  {
                    project_name: "测试项目一",
                    code: "pj1222",
                    project_team: "测试组一",
                    project_type: "人体功效评价",
                    tag: ["美白"],
                    info: "aaaaaaaaaaaaaaaaaaaa",
                    phone: "13392211015",
                    img: "https://cdn.uviewui.com/uview/album/1.jpg",
                    questionnaire: "调查问卷1",
                    sample_name: "润手霜",
                    sample_num: "s123",
                    sample_batchCode: "20220310",
                    clock_firstTime1: "8:00",
                    clock_firstTime2: "9:00",
                    clock_secondTime1: "20:00",
                    clock_secondTime2: "21:00",
                    clock_startTime: "2022-03-07",
                    clock_endTime: "2022-04-07",
                    publish_time1: "2022-03-07",
                    publish_time2: "2022-03-08",
                    visit_time1: "2022-03-07",
                    visit_time2: "2022-03-08",
                    sex: 1,
                    age1: 20,
                    age2: 30,
                    if_other: 0,
                    clock_rule: "按日打卡",
                    ontime_reward: 2,
                    overtime_reward: 1,
                    no_clock: 1,
                    exchange_points: 10,
                    exchange_money: 1,
                    participate_num: "1",

                    status: "进行中",
                    createTime: "2022-3-7",
                    createBy: "admin",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      fileListMain: [],

      formData: {
        clock_rule: "按日打卡",

        ontime_reward: 2,
        overtime_reward: 1,
        no_clock: 1,
        exchange_points: 10,
        exchange_money: 1,
      },
      formRules: {
        project_type: [{ required: true, message: "请选择" }],
        project_name: [{ required: true, message: "请输入" }],
        code: [{ required: true, message: "请输入" }],
        clock_firstTime2: [
          { validator: _this.checkFirstTime2, trigger: "change" },
        ],
        clock_secondTime1: [
          { validator: _this.checkSecondTime1, trigger: "change" },
        ],
        clock_secondTime2: [
          { validator: _this.checkSecondTime2, trigger: "change" },
        ],
      },
      userInfo: {},
      sf: "",
    };
  },

  components: {
    clockDetail,
    questionDetail,
    noticeList,
    editModal,
    preview,
    detailModal,
    volunteerDetail,
    yuyueDetail,
    logList,
  },

  computed: {},

  created() {
    this.init();
  },

  mounted() {},

  methods: {
    init() {
      let that = this;
      this.userInfo = JSON.parse(sessionStorage.userInfo);
      if (this.userInfo.main) {
        this.sf = this.userInfo.main.auth_type_name;
      }
      console.log(this.sf);
      that.typeOptions = [];
      that.$getAction(that.$Interface.project_type_list).then((res) => {
        if (res.code == 200) {
          that.typeOptions = res.data.list.map((it) => {
            let id = it.id;
            let a = {
              value: id,
              label: it.name,
            };
            return a;
          });
        }
      });
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {
        createBegin: "",
        createEnd: "",
        projectName: "",
        projectNO: "",
        status: "",
        projectType: "",
      };
      this.loadData();
    },
    loadData() {
      this.subModal = false;
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
      this.$nextTick(() => {
        this.$refs.editModal.addEvent();
      });
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {
      let that = this;
      that
        .$postAction(that.$Interface.project_del, { id: ids.toString() })
        .then((res) => {
          if (res.code == 200) {
            that.$message.success("删除成功");
            that.loadData();
          } else {
            that.$message.error("删除失败");
          }
        });
    },
    detail(row) {
      this.detailModal = true;
      this.project_id = row.id;
    },
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs.editModal.editEvent(this.formData);
      });
    },
    seeImg(img) {
      this.previewImage = img;
      this.previewVisible = true;
    },
    previewQuestion(template) {
      this.template = template;
      this.$refs.preview.showModal();
    },
    see(row) {
      this.$refs.detailModal.show(row);
    },
    piSetStauts(status) {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      for (let i in that.selectedRowKeys) {
        let id = that.selectedRowKeys[i];
        let param = {
          id: id,
          status: status,
        };
        that.$postAction(that.$Interface.project_status, param).then((res) => {
          if (res.code == 200) {
            if (i == that.selectedRowKeys.length - 1) {
              that.$message.success("操作成功");
              that.loadData();
            }
          } else {
            that.$message.error(res.message);
          }
        });
      }
    },
    setStatus(row, status) {
      // 0草稿 1被驳回 2审核中 3待发布 4未开始 5进行中 6已结束 7已取消 8已暂停
      let that = this;
      let param = {
        id: row.id,
        status: status,
      };
      that.$postAction(that.$Interface.project_status, param).then((res) => {
        if (res.code == 200) {
          that.$message.success("操作成功");
          that.loadData();
        } else {
          that.$message.error(res.message);
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.ant-tabs-bar {
  margin: 0 !important;
}

.ant-tabs-top-content {
  border: 1px solid #e8e8e8;
}

.tabContent {
  border: 1px solid #e8e8e8;
  border-top: 0;
  padding: 10px;
  margin-top: -16px;
}
.disabled {
  cursor: not-allowed;
  color: gainsboro;
}
</style>
