<!-- 柱状图 -->
<template>
  <div :id="id" class="echarts"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    id: String,
    data: Object,
  },
  data() {
    return {}
  },

  components: {},

  computed: {},

  created() {},

  mounted() {
    this.initCharts()
  },
  watch: {
    data: {
      handler(newV) {
        this.initCharts()
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let that = this
      let x_data = that.data.x_data.length ? that.data.x_data : []
      let y_data = that.data.y_data.length ? that.data.y_data : []
      let option = {
        title: {
          text: '销售额统计',
          left: 'center',
        },
        grid: {
          left: 40,
          right: 40,
          top: 40,
          bottom: 40,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: '{b}<br/>{a0}  : {c}元',
        },
        xAxis: {
          type: 'category',
          data: x_data,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: 'value',
          name: '销售额(元)',
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            name: '销售额',
            data: y_data,
            type: 'bar',
            barWidth: '30px',
            color: '#3aa1ff',
          },
          {
            type: 'line',
            smooth: true,
            data: y_data,
            symbol: 'circle',
          },
        ],
      }

      let dom = document.getElementById(this.id)
      let chart = echarts.getInstanceByDom(dom)
      chart = echarts.init(dom)
      chart.setOption(option, true)
      chart.resize()
      window.addEventListener('resize', function () {
        chart.resize()
      })
    },
  },
}
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 300px;
}
</style>
