var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vxe-grid',{ref:"table",attrs:{"border":"","size":"small","resizable":"","row-id":"id","show-overflow":"","highlight-hover-row":"","seq-config":{
      startIndex: (_vm.tablePage.currentPage - 1) * _vm.tablePage.pageSize,
    },"pager-config":_vm.tablePage,"sort-config":{ trigger: 'cell' },"proxy-config":_vm.tableProxy,"columns":_vm.columns,"toolbar-config2":_vm.tableToolbar},on:{"checkbox-change":_vm.selectChangeEvent,"checkbox-all":_vm.selectAllEvent},scopedSlots:_vm._u([{key:"toolbar_buttons",fn:function({ record }){return [_c('vxe-button',{attrs:{"status":"primary","size":"mini"},on:{"click":function($event){return _vm.insertEvent()}}},[_vm._v("新增")]),_c('vxe-button',{attrs:{"status":"primary","size":"mini"},on:{"click":function($event){return _vm.deleteEvent()}}},[_vm._v("批删除")])]}},{key:"imgArr",fn:function({ row }){return [_c('div',_vm._l((row.imgArr),function(item){return _c('img',{key:item,staticClass:"img1",attrs:{"src":item},on:{"click":function($event){return _vm.seeImg(item)}}})}),0)]}},{key:"action",fn:function({ row }){return [_c('a',{on:{"click":function($event){return _vm.editEvent(row)}}},[_vm._v("编辑")]),_c('a',{staticStyle:{"margin-left":"8px"},on:{"click":function($event){return _vm.removeEvent(row)}}},[_vm._v("删除")])]}}])}),_c('a-modal',{attrs:{"title":_vm.selectRow ? '编辑' : '新增',"width":800,"min-width":"600","min-height":"300","resize":"","destroy-on-close":"","show-zoom":"","footer":null},model:{value:(_vm.subModal),callback:function ($$v) {_vm.subModal=$$v},expression:"subModal"}},[_c('vxe-form',{attrs:{"data":_vm.formData,"rules":_vm.formRules,"title-align":"right","title-width":"100","title-colon":""},on:{"submit":_vm.submitEvent}},[_c('vxe-form-item',{attrs:{"title":"标题","field":"title","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"内容","field":"content","span":"24","item-render":{
          name: '$textarea',
          props: { autosize: { minRows: 3 } },
          attrs: { placeholder: '请输入' },
        }}}),_c('vxe-form-item',{attrs:{"align":"center","span":"24"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-button',{attrs:{"type":"submit","status":"primary"}},[_vm._v("保存")]),_c('vxe-button',{attrs:{"type":"reset"}},[_vm._v("重置")])]},proxy:true}])})],1)],1),_c('vxe-modal',{staticStyle:{"z-index":"10"},attrs:{"title":"查看图片","show-zoom":"","resize":"","destroy-on-close":"","width":"auto","height":"auto"},model:{value:(_vm.previewVisible),callback:function ($$v) {_vm.previewVisible=$$v},expression:"previewVisible"}},[_c('img',{attrs:{"alt":"example","src":_vm.previewImage}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }