<!-- 客户列表 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="客户名称">
            <a-input placeholder="请输入" v-model="queryParam.name"></a-input>
          </a-form-item>
        </a-col>

        <!-- <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="客户编号">
            <a-input
              placeholder="请输入"
              v-model="queryParam.customerNO"
            ></a-input>
          </a-form-item>
        </a-col> -->

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row)">删除</a>
      </template>

      <template v-slot:attachUrl="{ row }">
        <template v-if="row.extInfo">
          <a
            :href="row.extInfo.attachUrl"
            target="blank"
            v-if="row.extInfo.attachUrl"
            >下载</a
          >
        </template>
      </template>

      <template v-slot:intable="{ row }">
          <zi_list :customer_id="row.id"></zi_list>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="公司名称"
          field="name"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <!-- <vxe-form-item
          title="客户编号"
          field="customerNO"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item> -->

        <vxe-form-item
          title="联系人"
          field="contactName"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="电话"
          field="contactTel"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="地址"
          field="contactAddress"
          span="24"
          :item-render="{ name: 'textarea', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="登录账号"
          field="account"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="登录密码"
          field="password"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item
          title="客户类别"
          field="type"
          span="24"
          :item-render="{
            name: '$select',
            options: typeOptions,
            attrs: { placeholder: '请选择' },
          }"
        ></vxe-form-item>

        <vxe-form-item
          title="业务负责人"
          field="charger"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item title="附件" span="24">
          <template v-slot>
            <a-upload
              name="file"
              :file-list="fileListMain"
              :remove="handleRemove1"
              :before-upload="beforeUpload1"
            >
              <a-button> <a-icon type="upload" /> 上传</a-button>
            </a-upload>
          </template>
        </vxe-form-item>

        <vxe-form-item
          title="备注"
          field="remark"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </a-card>
</template>

<script>

import zi_list from './modal/zi_list.vue'

export default {
  data() {
    let _this = this;
    return {
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
        importUrl: "",
      },
      fileListMain: [],
      typeOptions: [
        { label: "一级", value: "一级" },
        { label: "二级", value: "二级" },
        { label: "三级", value: "三级" },
      ],
      columns: [
        { type: "checkbox", width: 50 },
        {
          width: 50,
          title: "",
          type: "expand",
          slots: {
            content: "intable",
          },
        },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "公司名称",
          field: "name",
        },
        // {
        //   title: "客户编号",
        //   field: "customerNO",
        // },
        {
          title: "联系人",
          field: "contactName",
        },
        {
          title: "电话",
          field: "contactTel",
        },
        {
          title: "地址",
          field: "contactAddress",
        },
        {
          title: "登录账号",
          field: "account",
        },
        {
          title: "登录密码",
          field: "password",
        },
        {
          title: "创建时间",
          field: "createdAt",
        },
        {
          title: "客户类别",
          field: "type",
        },
        {
          title: "备注",
          field: "remark",
        },
        {
          title: "业务负责人",
          field: "charger",
        },
        {
          title: "附件",
          slots: {
            default: "attachUrl",
          },
        },
        // {
        //   title: "状态",
        //   field: "status",
        //   slots: {
        //     default: ({ row }) => {
        //       if (row.status == 1) {
        //         return "已送货";
        //       } else {
        //         return "未送货";
        //       }
        //     },
        //   },
        // },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            // let param = _this.queryParam;
            // param.pageNum = page.currentPage;
            // param.pageSize = page.pageSize;
            // console.log(param);
            let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize;
            return _this.$getAction(_this.$Interface.customer_list + param);
            //  let rdata = _this.$Request(this.url.list, "post", param)
            // let data = {
            //   result: {
            //     current: 1,
            //     records: [
            //       {
            //         name: "广州市疾病预防控制中心",
            //         contactAddress: "广东省广州市白云区启德路1号",
            //         contactName: "陈先生",
            //         contactTel: 13392211555,
            //         customerNO: "a123",
            //         account: "b123",
            //         password: "123465",
            //         type: "",
            //         remark: "",
            //         charger: "admin",
            //         createdAt: "2022-3-9",
            //       },
            //     ],
            //     size: 10,
            //     total: 1,
            //   },
            // };
            // return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        extInfo: {},
        type: [],
      },
      formRules: {
        name: [{ required: true, message: "请输入" }],
        contactName: [{ required: true, message: "请输入" }],
        customerNO: [{ required: true, message: "请输入" }],
        contactTel: [{ required: true, message: "请输入" }],
        contactAddress: [{ required: true, message: "请输入" }],
        account: [{ required: true, message: "请输入" }],
        password: [{ required: true, message: "请输入" }],
        // type: [{ required: true, message: "请输入" }],
      },
    };
  },

  components: {
    zi_list
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {
      let that = this;
      that
        .$postAction(that.$Interface.customer_del, { id: ids })
        .then((res) => {
          if (res.code == 200) {
            that.subModal = false;
            that.$message.success("删除成功");
            that.loadData();
          } else {
            that.$message.error("删除失败");
          }
        });
    },
    submitEvent() {
      let that = this;
      // return console.log(that.formData)
      let user_id = JSON.parse(sessionStorage.userInfo).user_id;
      that.formData.customerNO = user_id.toString();
      that
        .$postAction(that.$Interface.customer_edit, that.formData)
        .then((res) => {
          if (res.code == 200) {
            that.subModal = false;
            that.$message.success("保存成功");
            that.loadData();
          } else {
            that.$message.error("保存失败");
          }
        });
    },
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
      if (row.extInfo) {
        let img = {
          uid: 0,
          name: "附件",
          status: "done",
          url: row.extInfo.attachUrl,
        };
        this.fileListMain = [img];
      } else {
        this.formData.extInfo = {};
      }
    },
    handleRemove1(file) {
      const index = this.fileListMain.indexOf(file);
      const newfileListMain = this.fileListMain.slice();
      newfileListMain.splice(index, 1);
      this.fileListMain = newfileListMain;
    },
    beforeUpload1(file) {
      let that = this;
      this.$uploadAction(file).then((res) => {
        if (res.code == 200) {
          let img = {
            uid: file.uid,
            name: file.name,
            status: "done",
            url: res.data.url,
          };
          that.fileListMain = [img];
          that.formData.extInfo.attachUrl = res.data.url;
        }
      });
      return false;
    },
  },
};
</script>

<style scoped>
</style>
