<!--角色管理 -->
<template>
  <a-card>
    <!-- <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="角色名称">
            <a-input placeholder="请输入" v-model="queryParam.code"></a-input>
          </a-form-item>
        </a-col>
       
        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="ant-alert ant-alert-info" style="margin-bottom: 12px">
      <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
      <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
      >项
      <a style="margin-left: 24px" @click="onClearSelected">清空</a>
    </div> -->

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-overflow
      highlight-hover-row
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        >
      </template>

      <template v-slot:action="{ row }">
        <a @click="editEvent(row)">编辑</a>
        <a style="margin-left: 8px" @click="removeEvent(row)">删除</a>
      </template>
    </vxe-grid>

    <vxe-modal  style="z-index: 10"  
      v-model="subModal"
      :title="selectRow ? '编辑' : '新增'"
      width="800"
      min-width="600"
      min-height="300"
      resize
      destroy-on-close
      show-zoom
    >
      <vxe-form
        :data="formData"
        :rules="formRules"
        title-align="right"
        title-width="100"
        @submit="submitEvent"
        title-colon
      >
        <vxe-form-item
          title="角色名称"
          field="name"
          span="24"
          :item-render="{ name: 'input', attrs: { placeholder: '请输入' } }"
        ></vxe-form-item>

        <vxe-form-item title="菜单权限" field="menu" span="24">
          <template v-slot>
            <a-tree-select
              v-model="formData.menu"
              style="width: 100%"
              :tree-data="menu"
              tree-checkable
              :show-checked-strategy="SHOW_PARENT"
              :replaceFields="replaceFields"
              placeholder="请选择"
            />
          </template>
        </vxe-form-item>

        <vxe-form-item align="center" span="24">
          <template #default>
            <vxe-button type="submit" status="primary">保存</vxe-button>
            <vxe-button type="reset">重置</vxe-button>
          </template>
        </vxe-form-item>
      </vxe-form>
    </vxe-modal>
  </a-card>
</template>

<script>
import { TreeSelect } from "ant-design-vue";
const SHOW_PARENT = TreeSelect.SHOW_CHILD;

import mixin from "@/assets/js/public/mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      SHOW_PARENT,
      replaceFields: { key: "title", value: "title" },
      value: [],
      queryParam: {},
      selectedRowKeys: [],
      selectionRows: [],
      url: {
        list: "",
      },
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "操作",
          width: "100",
          field: "action",
          slots: {
            default: "action",
          },
        },
        {
          title: "角色名称",
          field: "name",
          width: "200",
        },
        {
          title: "菜单权限",
          field: "menu",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "result.records",
          total: "result.total",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            // console.log(this.queryParam);
            // return _this.$Request(this.$Interface.warn_info);
            let data = {
              result: {
                records: [
                  {
                    name: "管理员",
                    menu: [
                      "功效评价",
                      "调查问卷",
                      "客户列表",
                      "客户分析",
                      "志愿者列表",
                      "用户管理",
                      "角色管理",
                      "项目类别",
                      "编号规则",
                      "日志列表"
                    ],
                  },
                  {
                    name: "客户",
                    menu: [
                      "功效评价",
                      "调查问卷",
                      "客户列表",
                      "客户分析",
                    ],
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      subModal: false,
      selectRow: null,
      formData: {
        sex: "",
        type: "",
        award_method: "",
      },
      formRules: {
        menu: [{ required: true, message: "请输入" }],
        name: [{ required: true, message: "请输入" }],
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
      this.$refs.table.clearCheckboxRow();
    },

    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    insertEvent() {
      this.subModal = true;
      this.selectRow = false;
    },
    deleteEvent() {
      let that = this;
      if (!this.selectionRows.length) {
        this.$message.error("至少选择一项");
        return;
      }
      that.removeEvent(that.selectedRowKeys.join(","));
    },
    removeEvent(ids) {},
    submitEvent() {},
    editEvent(row) {
      this.subModal = true;
      this.selectRow = true;
      this.formData = JSON.parse(JSON.stringify(row));
    },
  },
};
</script>

<style scoped>
</style>
