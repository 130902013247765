<!-- 扇形图 -->
<template>
  <div :id="id" class="echarts"></div>
</template>

<script>
import * as echarts from "echarts";
export default {
  props: {
    id: String,
    data: Array,
    name: String,
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  created() {},

  mounted() {
    this.initCharts();
  },
  watch: {
    data: {
      handler(newV) {
        this.initCharts();
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let that = this;
      let option = {
        color: [
          "#80FFA5",
          "#37A2FF",
          "#6438C6",
          "#FF0087",
          "#FFBF00",
          "#00DDFF",
        ],
        title: {
          text: that.name,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: that.name,
            type: "pie",
            radius: "50%",
            center: ["50%", "50%"],
            data: that.data,
            // label: {
            //   normal: {
            //     show: true,
            //     position: "inner",
            //   },
            // },
            // labelLine:{
            //   normal:{
            //       length:5,
            //       length2:5,
            //   }
            // },
          },
        ],
      };

      let dom = document.getElementById(this.id);
      let chart = echarts.getInstanceByDom(dom);
      chart = echarts.init(dom);
      chart.setOption(option, true);
      chart.resize();
      window.addEventListener("resize", function () {
        chart.resize();
      });
    },
  },
};
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 300px;
}
</style>
