<!-- 预约详情 -->
<template>
  <div id="clock_detail">
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="操作时间">
            <a-date-picker
              placeholder="开始时间"
              :locale="locale"
              v-model="queryParam.startTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束时间"
              :locale="locale"
              v-model="queryParam.endTime"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="4" :md="4" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              >查询</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <vxe-grid
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <!-- <vxe-button status="primary" size="mini" @click="insertEvent()"
          >新增</vxe-button
        >
        <vxe-button status="primary" size="mini" @click="deleteEvent()"
          >批删除</vxe-button
        > -->
      </template>

      <template v-slot:imgArr="{ row }">
        <div v-if="row.ext_info">
          <img
            class="img1"
            :src="item"
            v-for="item in row.ext_info.img"
            :key="item"
            @click="seeImg(item)"
          />
        </div>
      </template>

      <template v-slot:action="{ row }">
        <a >通过</a>
        <a style="margin-left:8px">不通过</a>
      </template>
    </vxe-grid>

    <vxe-modal  style="z-index: 10"  
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" />
    </vxe-modal>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  props:['project_id'],
  data() {
    let _this = this;
    return {
      locale,
      queryParam: {},
      show: false,
      previewVisible: false,
      previewImage: "",
      template: {},
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        // {
        //   title: "操作",
        //   field: "action",
        //   width: 80,
        //   slots: {
        //     default: "action",
        //   },
        // },
        {
          title: "操作人",
          field: "auth_name",
          width: 120,
        },
        {
          title: "操作内容",
          field: "log_content",
        },
           {
          title: "操作时间",
          field: "created_at",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let p = _this.queryParam;

            let param =
              "?pageNum=" + page.currentPage + "&pageSize=" + page.pageSize +'&project_id='+_this.project_id
              +'&time_begin='+ p.startTime+'&time_end='+  p.endTime  +" 23:59:59";
            let rdata = _this.$getAction(_this.$Interface.project_log + param);
            return rdata;

            // let data = {
            //   data: {
            //     current: 1,
            //     list: [
            //       {
            //         img: "https://cdn.uviewui.com/uview/album/1.jpg",
            //         name: "admin",
            //         phone: "13392211015",
            //         age: "20",
            //         sex: "男",
            //         clock_days: 1,
            //         content: "创建了新的项目",
            //         imgArr: [
            //           "https://cdn.uviewui.com/uview/album/7.jpg",
            //           "https://cdn.uviewui.com/uview/album/8.jpg",
            //           "https://cdn.uviewui.com/uview/album/9.jpg",
            //         ],
            //         address: "广东省佛山市顺德区",
            //         clock_time: "2022-06-07 20:00",
            //       },
            //     ],
            //     totalCount: 10,
            //     total: 1,
            //   },
            // };

            // return data;
          },
        },
      },
    };
  },

  components: {},

  computed: {},

  created() {},

  mounted() {},

  methods: {
    showModal() {
      this.show = true;
    },

    seeImg(img) {
      // this.previewVisible = true;
      // this.previewImage = img;
      this.$emit('seeImg',img)
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>

<style lang="scss">
// .vxe-table--border-line {
//   display: none;
// }
</style>


