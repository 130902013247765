<!-- 预约详情 -->
<template>
  <a-card>
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item label="预约时间">
            <a-date-picker
              placeholder="开始时间"
              :locale="locale"
              v-model="queryParam.createBegin"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
            <span class="query-group-split-cust"></span>
            <a-date-picker
              placeholder="结束时间"
              :locale="locale"
              v-model="queryParam.createEnd"
              valueFormat="YYYY-MM-DD"
              class="query-group-cust"
            ></a-date-picker>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="状态">
            <a-select
              v-model="status"
              :options="staOpt"
              @change="statusChange"
            />
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="项目名称">
            <a-input
              placeholder="请输入"
              v-model="queryParam.project_name"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="项目编号">
            <a-input
              placeholder="请输入"
              v-model="queryParam.project_code"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="志愿者">
            <a-input
              placeholder="请输入"
              v-model="queryParam.volunteer_name"
            ></a-input>
          </a-form-item>
        </a-col>

        <a-col :xl="6" :lg="6" :md="6" :sm="24">
          <a-form-item>
            <a-button
              type="primary"
              @click="$refs.table.commitProxy('reload')"
              icon="search"
              >查询</a-button
            >
            <a-button
              type="primary"
              @click="searchReset"
              icon="reload"
              style="margin-left: 8px"
              >重置</a-button
            >
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <vxe-grid
      v-if="status == 0 || status == 1"
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      @checkbox-change="selectChangeEvent"
      @checkbox-all="selectAllEvent"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy"
      :columns="columns"
      :toolbar-config="tableToolbar"
    >
      <template #toolbar_buttons="{ record }">
        <!-- <vxe-button status="primary" size="mini">批通过</vxe-button>
        <vxe-button status="primary" size="mini">批不通过</vxe-button> -->
        <a-button type="primary" @click="exportData">导出</a-button>
        <a-button type="primary" @click="showPrint" style="margin-left: 5px"
          >打印标签</a-button
        >
      </template>

      <template v-slot:imgArr="{ row }">
        <div v-if="row.ext_info">
          <img
            class="img1"
            :src="item"
            v-for="item in row.ext_info.img"
            :key="item"
            @click="seeImg(item)"
          />
        </div>
      </template>

      <template v-slot:action="{ row }">
        <!-- <a>通过</a>
        <a style="margin-left: 8px">不通过</a> -->
      </template>
    </vxe-grid>

    <vxe-grid
      v-if="status == 2"
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy3"
      :columns="columns3"
      :toolbar-config2="tableToolbar"
    >
    </vxe-grid>

    <printModal ref="printModal" />
  </a-card>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import printModal from "./modal/print.vue";
import moment from "moment";

export default {
  props: ["project_id"],
  data() {
    let _this = this;
    return {
      locale,
      queryParam: {
        createBegin: moment().format("YYYY-MM-DD"),
        createEnd: moment().format("YYYY-MM-DD"),
      },
      show: false,
      previewVisible: false,
      previewImage: "",
      template: {},
      status: 2,
      staOpt: [
        { label: "未预约", value: 2 },
        { label: "未签到", value: 0 },
        { label: "已签到", value: 1 },
      ],
      columns3: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "项目名称",
          field: "project_name",
        },
        {
          title: "志愿者",
          field: "volunteer_name",
        },
        {
          title: "性别",
          field: "volunteer_sex_text",
        },
        {
          title: "年龄",
          field: "volunteer_age",
        },
        {
          title: "手机",
          field: "volunteer_phone",
        },
      ],
      tableProxy3: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let p = _this.queryParam;

            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&projectName=" +
              p.projectName +
              "&projectCode=" +
              p.project_code +
              // "&base_day_begin=" +
              // p.createBegin +
              // "&base_day_end=" +
              // p.createEnd +
              // " 23:59:59" +
              "&is_reserve=0";
            if (p.volunteer_name) {
              param = param + "&volunteer_name=" + p.volunteer_name;
            }

            let rdata = _this.$getAction(
              _this.$Interface.zyz_yuyue_status + param
            );
            return rdata;
          },
        },
      },
      columns: [
        { type: "checkbox", width: 50 },
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        // {
        //   title: "操作",
        //   field: "action",
        //   width: 80,
        //   slots: {
        //     default: "action",
        //   },
        // },

        {
          title: "项目名称",
          field: "project_name",
        },
        {
          title: "项目编号",
          field: "project_no",
        },
        {
          title: "样品编号",
          field: "sample_num",
        },
        {
          title: "样品名称",
          field: "sample_name",
        },
        {
          title: "测试指标",
          field: "inspect_tag",
        },
        {
          title: "志愿者姓名",
          field: "volunteer_name",
        },
        {
          title: "性别",
          field: "volunteer_sex",
        },
        {
          title: "年龄",
          field: "volunteer_age",
        },
        {
          title: "预约时间段",
          // field: "base_day",
          width: 200,
          slots: {
            default: ({ row }) => {
              return row.base_day + " " + row.begin_time + "-" + row.end_time;
            },
          },
        },
        {
          title: "签到状态",
          field: "checkin_status_text",
        },
        {
          title: "签到时间",
          field: "checkin_at",
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let p = _this.queryParam;
            let customer_id = "";
            customer_id = JSON.parse(sessionStorage.userInfo).user_id;
            if (JSON.parse(sessionStorage.userInfo).main.customer_id) {
              customer_id = JSON.parse(sessionStorage.userInfo).main
                .customer_id;
            }
            if (sessionStorage.sf == "管理员") {
              customer_id = "";
            }
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&base_day_begin=" +
              p.createBegin +
              "&base_day_end=" +
              p.createEnd +
              " 23:59:59" +
              "&customer_id=" +
              customer_id +
              "&is_checkin=" +
              _this.status;
            if (p.project_name) {
              param = param + "&project_name=" + p.project_name;
            }
            if (p.project_code) {
              param = param + "&project_code=" + p.project_code;
            }
            if (p.volunteer_name) {
              param = param + "&volunteer_name=" + p.volunteer_name;
            }

            let rdata = _this.$getAction(
              _this.$Interface.yuye_record_list + param
            );
            return rdata;

            let data = {
              result: {
                current: 1,
                records: [
                  {
                    img: "https://cdn.uviewui.com/uview/album/1.jpg",
                    name: "Carson",
                    phone: "13392211015",
                    age: "20",
                    sex: "男",
                    clock_days: 1,
                    content: "赞赞赞赞赞赞赞赞赞赞",
                    imgArr: [
                      "https://cdn.uviewui.com/uview/album/7.jpg",
                      "https://cdn.uviewui.com/uview/album/8.jpg",
                      "https://cdn.uviewui.com/uview/album/9.jpg",
                    ],
                    address: "广东省佛山市顺德区",
                    clock_time: "2022-03-07 20:00",
                  },
                ],
                size: 10,
                total: 1,
              },
            };

            return data;
          },
        },
      },
      selectionRows: [],
      selectedRowKeys: [],
    };
  },

  components: { printModal },

  computed: {},

  created() {
    let data = {
      path: "yuyue_list",
      title: "预约列表",
      index: "sub1",
    };
    this.$emit("change_bread", data);
  },

  mounted() {},

  methods: {
    showPrint() {
      if (!this.selectionRows.length) {
        if (!this.selectionRows.length) {
          this.$message.error("至少选择一项");
          return;
        }
      }
      this.$refs.printModal.show(this.selectionRows);
    },
    selectChangeEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    selectAllEvent({ checked, records }) {
      this.selectionRows = records;
      this.selectedRowKeys = records.map((it) => it.id);
    },
    searchReset() {
      this.queryParam = {};
      this.loadData();
    },
    loadData() {
      this.$refs.table.commitProxy("query");
    },
    exportData() {
      let that = this;
      const workBook = this.$XLSX.utils.table_to_book(
        that.$jq(" .vxe-table--render-wrapper")[0],
        { raw: true }
      );
      this.$XLSX.writeFile(workBook, "导出.xlsx");
    },
    statusChange() {
      this.$nextTick(() => {
        this.$refs.table.commitProxy("reload");
      });
    },
  },
};
</script>

<style scoped>
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>

<style lang="scss">
// .vxe-table--border-line {
//   display: none;
// }
</style>


