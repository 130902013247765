import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	Request,RequestStr,getAction,postAction,uploadAction
} from '@/assets/js/public/request.js';
import {
	Interface
} from '@/assets/js/public/api.js';

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.use(Antd)

import $ from 'jquery'
Vue.prototype.$jq = $

import XLSX from 'xlsx'
Vue.prototype.$XLSX = XLSX

import _ from 'lodash'
Vue.prototype._ = _

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

Vue.use(VXETable)

import Print from "vue-print-nb";
Vue.use(Print);

Vue.config.productionTip = false
Vue.prototype.$Interface = Interface;
Vue.prototype.$Request = Request;
Vue.prototype.$RequestStr = RequestStr;
Vue.prototype.$getAction = getAction;
Vue.prototype.$postAction = postAction;
Vue.prototype.$uploadAction = uploadAction;

import moment from 'moment'
Vue.prototype.$moment = moment


new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')

