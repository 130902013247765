var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',[_c('a-form',{staticClass:"myForm",nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$refs.table.commitProxy('reload')}}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"xl":4,"lg":5,"md":6,"sm":24}},[_c('a-form-item',{attrs:{"label":"用户名称"}},[_c('a-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.queryParam.name),callback:function ($$v) {_vm.$set(_vm.queryParam, "name", $$v)},expression:"queryParam.name"}})],1)],1),_c('a-col',{attrs:{"xl":4,"lg":4,"md":4,"sm":24}},[_c('a-form-item',[_c('a-button',{attrs:{"type":"primary","icon":"search"},on:{"click":function($event){return _vm.$refs.table.commitProxy('reload')}}},[_vm._v("查询")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","icon":"reload"},on:{"click":_vm.searchReset}},[_vm._v("重置")])],1)],1)],1)],1),_c('div',{staticClass:"ant-alert ant-alert-info",staticStyle:{"margin-bottom":"12px"}},[_c('i',{staticClass:"anticon anticon-info-circle ant-alert-icon"}),_vm._v(" 已选择 "),_c('a',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.selectedRowKeys.length))]),_vm._v("项 "),_c('a',{staticStyle:{"margin-left":"24px"},on:{"click":_vm.onClearSelected}},[_vm._v("清空")])]),_c('vxe-grid',{ref:"table",attrs:{"border":"","size":"small","resizable":"","row-id":"id","show-overflow":"","highlight-hover-row":"","seq-config":{
      startIndex: (_vm.tablePage.currentPage - 1) * _vm.tablePage.pageSize,
    },"pager-config":_vm.tablePage,"sort-config":{ trigger: 'cell' },"proxy-config":_vm.tableProxy,"columns":_vm.columns,"toolbar-config":_vm.tableToolbar},on:{"checkbox-change":_vm.selectChangeEvent,"checkbox-all":_vm.selectAllEvent},scopedSlots:_vm._u([{key:"toolbar_buttons",fn:function({ record }){return [_c('vxe-button',{attrs:{"status":"primary","size":"mini"},on:{"click":function($event){return _vm.insertEvent()}}},[_vm._v("新增")]),_c('vxe-button',{attrs:{"status":"primary","size":"mini"},on:{"click":function($event){return _vm.deleteEvent()}}},[_vm._v("批删除")])]}},{key:"action",fn:function({ row }){return [_c('a',{on:{"click":function($event){return _vm.editEvent(row)}}},[_vm._v("编辑")]),_c('a',{staticStyle:{"margin-left":"8px"},on:{"click":function($event){return _vm.removeEvent(row)}}},[_vm._v("删除")])]}}])}),_c('vxe-modal',{staticStyle:{"z-index":"10"},attrs:{"title":_vm.selectRow ? '编辑' : '新增',"width":"800","min-width":"600","min-height":"300","resize":"","destroy-on-close":"","show-zoom":""},model:{value:(_vm.subModal),callback:function ($$v) {_vm.subModal=$$v},expression:"subModal"}},[_c('vxe-form',{attrs:{"data":_vm.formData,"rules":_vm.formRules,"title-align":"right","title-width":"100","title-colon":""},on:{"submit":_vm.submitEvent}},[_c('vxe-form-item',{attrs:{"title":"用户名称","field":"name","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"性别","field":"sex","span":"24","item-render":{
          name: '$select',
          options: _vm.sexOptions,
          attrs: { placeholder: '请选择' },
        }}}),_c('vxe-form-item',{attrs:{"title":"年龄","field":"age","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"电话","field":"phone","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"地址","field":"address","span":"24","item-render":{ name: 'textarea', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"登录账号","field":"account","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"登录密码","field":"password","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"角色","field":"role","span":"24","item-render":{
          name: '$select',
          options: _vm.roleOptions,
          attrs: { placeholder: '请选择' },
        }}}),_c('vxe-form-item',{attrs:{"title":"备注","field":"remark","span":"24","item-render":{ name: 'input', attrs: { placeholder: '请输入' } }}}),_c('vxe-form-item',{attrs:{"title":"状态","field":"status","span":"24","item-render":{
          name: '$select',
          options: _vm.statusOptions,
          attrs: { placeholder: '请选择' },
        }}}),_c('vxe-form-item',{attrs:{"align":"center","span":"24"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-button',{attrs:{"type":"submit","status":"primary"}},[_vm._v("保存")]),_c('vxe-button',{attrs:{"type":"reset"}},[_vm._v("重置")])]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }