<!-- 折线图 -->
<template>
  <div :id="id" class="echarts"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  props: {
    id: String,
    data: Object,
  },
  data() {
    return {}
  },

  components: {},

  computed: {},

  created() {},

  mounted() {
    this.initCharts()
  },
  watch: {
    data: {
      handler(newV) {
        this.initCharts()
      },
      deep: true,
    },
  },

  methods: {
    initCharts() {
      let that = this
      let x_data = that.data.x_data.length ? that.data.x_data : []
      let y_data = that.data.y_data.length ? that.data.y_data : []
      // console.log(x_data)
      // console.log(y_data)
      let series_arr = []
      for (let i in x_data) {
        let a = x_data[i]
        let o = {
          type: 'line',
          
          smooth: true,
          symbol: 'circle',
        }
        series_arr.push(o)
      }
      console.log(series_arr)
      let option = {
        legend: { bottom: 'bottom' },
        title: {
          text: '实验室统计',
          left: 'center',
        },
        grid: {
          left: 40,
          right: 40,
          top: 40,
          bottom: 40,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          // formatter: '{b}<br/>{a0}  : {c}元',
        },
        xAxis: {
          type: 'category',
          // data: x_data,
          axisTick: {
            alignWithLabel: true,
          },
        },
        yAxis: {
          type: 'value',
          name: '数量',
          axisLine: {
            show: true,
          },
        },
        dataset: [
          {
            // source: [
              // { date: "2022-07-04", "微生物": 4, "理化": 2 },
              // { date: "2022-07-04", "微生物": 3, "理化":5 },
            // ],
            source: y_data,
          },
        ],
        series:series_arr,
        // series: [
        //   {
        //     type: 'line',
        //     smooth: true,
        //     symbol: 'circle',
        //   },
        //   {
        //     type: 'line',
        //     smooth: true,
        //     symbol: 'circle',
        //   },
          
        // ],
      }

      let dom = document.getElementById(this.id)
      let chart = echarts.getInstanceByDom(dom)
      chart = echarts.init(dom)
      chart.setOption(option, true)
      chart.resize()
      window.addEventListener('resize', function () {
        chart.resize()
      })
    },
  },
}
</script>

<style scoped>
.echarts {
  width: 100%;
  height: 300px;
}
</style>
