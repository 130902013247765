<!-- 答卷列表 -->
<template>
  <div id="answer">
    <a-form
      @keyup.enter.native="$refs.table.commitProxy('reload')"
      class="myForm"
    >
      <a-row :gutter="24">
        <a-col :xl="4" :lg="5" :md="6" :sm="24">
          <a-form-item label="状态">
            <a-select
              :options="optC"
              placeholder="请选择"
              v-model="status"
              @change="statusChange"
            ></a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="8" :lg="8" :md="8" :sm="24">
          <a-form-item>
            <a-button type="primary" @click="exportData">导出</a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <vxe-grid
      v-if="status == 0"
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config="tableProxy2"
      :columns="columns2"
      :toolbar-config2="tableToolbar"
    >
    </vxe-grid>

    <vxe-grid
      v-if="status == 1"
      ref="table"
      border
      size="small"
      resizable
      row-id="id"
      show-header-overflow
      highlight-hover-row
      :seq-config="{
        startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
      }"
      :pager-config="tablePage"
      :sort-config="{ trigger: 'cell' }"
      :proxy-config2="tableProxy"
      :columns="columns"
      :toolbar-config2="tableToolbar"
      :data="tableData"
    >
      <template v-slot:imgRequest="{ row }">
        <template v-for="item in row['imgRequest']">
          <img class="img1" :src="item" :key="item" @click="seeImg(item)" />
        </template>
      </template>

      <template v-slot:uploadImg="{ row }">
        <template v-for="item in row['uploadImg']">
          <img class="img1" :src="item" :key="item" @click="seeImg(item)" />
        </template>
      </template>
    </vxe-grid>

    <vxe-modal
      style="z-index: 10"
      v-model="previewVisible"
      title="查看图片"
      show-zoom
      resize
      destroy-on-close
      width="auto"
      height="auto"
    >
      <img alt="example" :src="previewImage" class="pImg" />
    </vxe-modal>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  computed: {},
  props: ["answer", "project_id", "question_id"],
  data() {
    let _this = this;
    return {
      locale,
      status: 0,
      optC: [
        { label: "未填写", value: 0 },
        { label: "已填写", value: 1 },
      ],
      queryParam: {},
      previewVisible: false,
      previewImage: "",
      columns2: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "志愿者",
          field: "volunteer_name",
        },
        {
          title: "性别",
          field: "volunteer_sex_text",
        },
        {
          title: "年龄",
          field: "volunteer_age",
        },
        {
          title: "手机",
          field: "volunteer_phone",
        },
      ],
      tableProxy2: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "data.list",
          total: "data.totalCount",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {
            let param =
              "?pageNum=" +
              page.currentPage +
              "&pageSize=" +
              page.pageSize +
              "&project_id=" +
              _this.project_id +
              "&question_id=" +
              _this.question_id +
              "&is_record=0";

            return _this.$getAction(_this.$Interface.question_status + param);
          },
        },
      },
      tableData: [],
      columns: [
        {
          type: "seq",
          width: "50",
          title: "序号",
          slots: {
            default: ({ seq }) => {
              return seq;
            },
          },
        },
        {
          title: "项目名称",
          field: "project_name",
          width: 120,
        },
        {
          title: "项目编号",
          field: "project_number",
          width: 120,
        },
        {
          title: "问卷名称",
          field: "question_name",
          width: 120,
        },
        {
          title: "类型",
          field: "question_type",
          width: 120,
        },
        {
          title: "志愿者姓名",
          field: "volunteer",
          width: 120,
        },
        {
          title: "答卷时间",
          field: "created_at",
          width: 150,
        },
      ],
      tablePage: {
        pageSize: 10,
        align: "right",
      },
      tableToolbar: {
        slots: {
          buttons: "toolbar_buttons",
        },
        id: "tools",
        refresh: true, // 刷新按钮
        zoom: true, // 最大化按钮
        // 列宽操作记录
        resizable: { storage: true },
        // 列操作记录
        custom: { storage: true },
      },
      tableProxy: {
        sort: true, // 启用排序代理
        // 配置响应的数据属性
        props: {
          result: "result.records",
          total: "result.total",
        },
        ajax: {
          query: ({ page, sort, filters, form }) => {},
        },
      },
    };
  },

  components: {},

  created() {
    this.getData();
  },

  mounted() {},

  methods: {
    getData() {
      let that = this;
      let arr = that.answer;
      // console.log(tableData);
      // console.log(arr)
      that.selArr = [];
      for (let i in arr) {
        let a = arr[i];
        for (let b in a.content) {
          let item = a.content[b];
          if (i == 0) {
            if (item.hasOpt && b == 0) {
              let hasScore = _.findIndex(item.options, "score");
              if (hasScore > -1) {
                let s = {
                  title: "得分",
                  field: "score",
                  width: 60,
                };
                that.columns.push(s);
              }
            }
            let t = {
              title: item.label,
              field: item.label,
              width: 150,
            };
            if (item.type == "imgRequest") {
              t.slots = {
                default: "imgRequest",
              };
            }
            if (item.type == "uploadImg") {
              t.slots = {
                default: "uploadImg",
              };
            }
            that.columns.push(t);
          }
          if (item.type == "imgRequest") {
            a["imgRequest"] = item.value;
          }
          if (item.type == "uploadImg") {
            a["uploadImg"] = item.value;
          }
          a[item.label] = item.value;
        }
      }
      that.tableData = arr;
    },
    exportData() {
      let that = this;
      const workBook = this.$XLSX.utils.table_to_book(
        that.$jq("#answer .vxe-table--render-wrapper")[0],
        { raw: true }
      );
      this.$XLSX.writeFile(workBook, "导出.xlsx");
    },
    seeImg(img) {
      this.previewImage = img;
      this.previewVisible = true;
    },
    statusChange() {
      this.$nextTick(() => {
        this.$refs.table.commitProxy("reload");
      });
    },
  },
};
</script>



<style lang="scss">
.img1 {
  width: 50px;
  height: 50px;
  margin: 2px;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.pImg {
  max-width: 700px;
  max-height: 700px;
}
</style>


